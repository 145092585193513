import React from "react";
import { Link, useNavigate } from "react-router-dom";
//import history from "../../store/history";

import * as style from "./Pagination.style";

import { ButtonLink } from "..";
import { Arrow } from "../../assets/icons";

export const Pagination = (props: any) => {
  const navigate = useNavigate();
  return (
    <style.Wrapper>
      <style.Link>
        <Link
          to={props.previous}
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
        >
          <Arrow /> Tillbaka
        </Link>
      </style.Link>

      {props.next !== "" && (
        <style.Button>
          <ButtonLink label="Fortsätt" path={props.next} />
        </style.Button>
      )}
    </style.Wrapper>
  );
};
