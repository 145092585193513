import styled from "styled-components";

export const Card = styled.div`
  margin-bottom: 32px;

  h4 {
    color: #000;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 20px;
  }

  ul {

  }

  li {
    margin-bottom: 20px;

    strong {
      color: #000;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
    }

    p {
      color: #000000;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
  }
`;
