export interface Product {
  attributes: ProductAttribute[],
  categories: number[],
  description: string,
  type: ProductType,
  id: number,
  images: string[]
  in_stock: boolean,
  stock_quantity?:number,
  is_subscription: boolean,
  price: string,
  regular_price: string,
  short_description: string,
  slug: string,
  title: string,
  variations: ProductVariation[],
  banner_subtitle?: string,
  banner_headline?: string,
  banner_text?: string,
  banner_image?: string,
  details?: ProductDetailItem[],
  product_type: ProductKind,
};
export enum ProductKind {
  Phone = 'Phone',
  Subscription = 'Telephone subscription',
  Accessory = 'Accessory',
};
export enum ProductType {
  Simple = 'simple',
  Variable = 'variable',
};
export interface ProductDetailItem {
  id: string,
  label: string,
  text: string,
};
export interface ProductAttribute {
  name: ProductAttributeTypes
  slug: ProductAttributeTypes
  values: string[]
}
export enum ProductAttributeTypes {
  Color = 'color',
  Memory = 'memory',
  Months = 'months',
};
export interface PhoneVariationAttributes {
  attribute_color: string,
  attribute_memory: string,
};
export interface SubscriptionVariationAttributes {
  attribute_months: string,
};
export interface UnkownVariationAttributes {
  [key: string]: string,
};
export type ProductVariationAttributes =
  UnkownVariationAttributes |
  PhoneVariationAttributes |
  SubscriptionVariationAttributes;
export interface VariationDimension {
  length: string,
  width: string,
  height: string
};
export interface VariationImage {
  title: string,
  caption: string,
  url: string,
  alt: string,
  src: string,
  srcset: string,
  sizes: string,
  full_src: string,
  full_src_w: number,
  full_src_h: number,
  gallery_thumbnail_src: string,
  gallery_thumbnail_src_w: number,
  gallery_thumbnail_src_h: number,
  thumb_src: string,
  thumb_src_w: number,
  thumb_src_h: number,
  src_w: number,
  src_h: number
};
export interface ProductVariation {
  attributes: ProductVariationAttributes,
  attribute_color: string,
  attribute_memory: string,
  availability_html: string,
  backorders_allowed: boolean,
  dimensions: VariationDimension,
  dimensions_html: string,
  display_price: number,
  display_regular_price: number,
  image: VariationImage,
  image_id: string,
  is_downloadable: boolean,
  is_in_stock: boolean,
  is_purchasable: boolean,
  is_sold_individually: string,
  is_virtual: boolean,
  max_qty: string,
  min_qty: number,
  price_html: string,
  sku: string,
  variation_description: string,
  variation_id: number,
  variation_is_active: boolean,
  variation_is_visible: boolean,
  weight: string,
  weight_html: string,
};
