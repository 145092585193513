import React, { Fragment, useContext } from "react";

import { ProductCategory } from "../../types/Category";
import { Product } from "../../types/Product";
import { sanitizePath } from "../../utils/sanitizers";
import { ProductItem, Heading } from "../../components";
import { SmallArrow } from "../../assets/icons";
import * as style from "./RelatedProducts.style";
import { AppContext } from "../../AppProvider";
interface Props {
  title: string;
  relatedProducts: Array<Product>;
  category?: ProductCategory;
}
export const RelatedProducts: React.FC<Props> = ({
  title,
  relatedProducts,
  category,
}) => {
  const context = useContext(AppContext);
  const categoryBaseSlug = context?.settings?.category_slug; //useSelector(selectCategorySlug);
  console.log("category ", category);
  return (
    <Fragment>
      <style.Header>
        <Heading title={title} size={3} />
        {category && (
          <style.LinkItem
            to={sanitizePath(`/${categoryBaseSlug}/${category.slug}`)}
          >
            <strong>Visa alla {category.name}</strong>
            <SmallArrow />
          </style.LinkItem>
        )}
      </style.Header>
      <style.ProductItems>
        {relatedProducts?.map((item) => (
          <ProductItem key={item.id} {...item} />
        ))}
      </style.ProductItems>
    </Fragment>
  );
};
export default RelatedProducts;
