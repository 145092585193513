import styled from "styled-components";
export type valign = 'top' | 'bottom' | 'center';
const getValign = (valign?: valign) => {
  switch (valign) {
    case 'top':
      return 'margin-top: 0; margin-bottom: auto;';
    case 'center':
      return 'margin-top: auto; margin-bottom: auto;';
    case 'bottom':
      return 'margin-top: auto; margin-bottom: 0;';
    default:
      return 'margin-top: 0; margin-bottom: auto;';
  }
};
interface ContainerProps {
  valign?: valign,
};
export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 0 32px;
  position: relative;
  ${(props: ContainerProps) => getValign(props.valign)}
`;
