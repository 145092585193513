import React, { useState } from "react";
import { Subscription } from "../../../types/Subscription";
import { Arrow } from "../../../assets/icons";
import { format } from "date-fns";
import * as style from "./Subscription.style";
import { Button, IconButton, Input } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
interface IProps {
  subscription: Subscription;
}
interface IInlineEditProps {
  editing: boolean;
  value: string;
  onChange: (val: any) => void;
}

export const SubscriptionContent: React.FC<IProps> = ({ subscription }) => {
  return (
    <React.Fragment>
      {/* <Heading size={3} title={`Abonnemang (${subscriptions.length}st)`} /> */}
      <style.GoBack to={"/foretag/abonnemang"}>
        <Arrow />
        Abonnemang
      </style.GoBack>
      <style.Subscription>
        <style.h3>
          {subscription.firstName} {subscription.lastName}
        </style.h3>
        <style.span>{subscription.phone_number}</style.span>
        <style.Columns>
          <style.Column>
            <style.Heading>Mobil</style.Heading>
            <style.Description>{subscription.operator}</style.Description>
            <style.Heading>Pris</style.Heading>
            <style.Description>
              {subscription.price} {subscription.currency} /{" "}
              {subscription.invoice_time}
            </style.Description>
            <style.Heading>Hårdvarupris:</style.Heading>
            <style.Description>
              {subscription.hardware_price &&
              subscription.hardware_price !== "0"
                ? `${subscription.hardware_price} ${subscription.currency} / ${subscription.invoice_time}`
                : "-"}
            </style.Description>
            <style.Heading>Bindningstid</style.Heading>
            <style.Description>
              {format(new Date(subscription.subscription_time), "yyyy-MM-dd")}
              {" - "}
              {format(new Date(subscription.extended_to), "yyyy-MM-dd")}
            </style.Description>
          </style.Column>
          <style.Column>
            <style.Heading>IMEI-nr:</style.Heading>
            <style.Description>{subscription.imei}</style.Description>
            <style.Heading>PUK-kod:</style.Heading>
            <style.Description>{subscription.puk_code}</style.Description>
            <style.Heading>SIM:</style.Heading>
            <style.Description>{subscription.sim}</style.Description>
            <style.Heading>Konto:</style.Heading>
            <style.Description>{subscription.account_name}</style.Description>
          </style.Column>
        </style.Columns>
      </style.Subscription>
    </React.Fragment>
  );
};
