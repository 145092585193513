import React, { useContext } from "react";

import { UserContext } from "../../../providers/UserProvider";
import { DocumentsContent } from "./Documents.content";

export const Documents = () => {
  const userContext = useContext(UserContext);
  const user = userContext.user;

  if (!user) {
    return null;
  }
  console.log(user);
  return (
    <DocumentsContent
      documents={
        user?.company?.documents?.filter((document) => document.document) ?? []
      }
    />
  );
};
