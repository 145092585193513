import React from "react";

import * as style from "./Heading.style";

type THeading = {
  size?: number;
  title: string;
  spacing?: number;
  children?: React.ReactNode;
};

const sizeToHeading = (
  size?: number
): "h1" | "h2" | "h3" | "h4" | "h5" | "h6" => {
  switch (size) {
    case 1:
      return "h1";
    case 2:
      return "h2";
    case 3:
      return "h3";
    case 4:
      return "h4";
    case 5:
      return "h5";
    case 6:
      return "h6";
    default:
      return "h1";
  }
};
export const Heading = ({ size = 1, title, spacing }: THeading) => (
  <style.Heading
    as={sizeToHeading(size)}
    role="heading"
    aria-level={size}
    spacing={spacing ? spacing : 0}
  >
    {title}
  </style.Heading>
);
