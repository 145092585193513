import React from "react";
import { Checkmark } from "../../assets/icons";
import { HeaderBullets } from "../../types/Settings";
import * as style from "./TopBar.style";
interface Props {
  headerBullets?: HeaderBullets;
}
export const TopBarContent: React.FC<Props> = ({ headerBullets }) => (
  <style.Wrapper>
    <style.Container>
      <ul>
        {headerBullets?.map((bullet) => (
          <li key={bullet.id}>
            <Checkmark stroke="#fff" />
            {bullet.text}
          </li>
        ))}
      </ul>
    </style.Container>
  </style.Wrapper>
);
