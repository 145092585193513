import styled from "styled-components";
import { colors } from "../../../assets/style/variables";
import { Link } from "react-router-dom";
export const SubscriptionCard = styled(Link)`
  display: block;
  border-radius: 3px 3px 0 0;
  padding: 30px;
  margin-bottom: 25px;
  background-color: #fff;
  transition: background-color 0.3s ease;
  cursor: pointer;
  align-items: center;
  @media screen and (min-width: 768px) {
    display: flex;
  }
  &:hover {
    background: #eeeeee;
  }
`
export const Subscriptions = styled.div`
  margin-top: 35px;
  input {
    margin-top: 9px;
    font-size: 16px;
  }
`
export const h3 = styled.h3`
  color: ${colors.accent};
  padding-bottom: 10px;
  font-size: 18px;
`
export const span = styled.span`
`
export const RotatedElement = styled.div`
  svg {
    transform: rotate(180deg);
  }
`
export const LeftWrap = styled.div`
  display: flex;
  align-items: center;
  min-width: 200px;
`
export const MiddleWrap = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  @media screen and (max-width: 767px) {
    margin-top: 1em;
  }
`
export const RightWrap = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 767px) {
    margin-top: 1em;
  }
`
export const Meta = styled.div`
  margin-left: 25px;
`
