import styled, {keyframes} from "styled-components";

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`
export const Loader = styled.div`
  text-align: center;
  display: block;
  width: 70px;
  height: 70px;
  border: 7px solid rgba(0,0,0,0.2);
  border-radius: 50%;
  border-top-color: #333;
  animation:  ${rotate}  1s linear infinite;
`;

