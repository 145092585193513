import React, { useEffect, useState } from "react";
import { Heading, Spinner } from "../../../components";
import { Subscription } from "../../../types/Subscription";
import { format } from "date-fns";
import * as style from "./Subscriptions.style";
import { Button, IconButton, Input, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
interface IProps {
  subscriptions: Subscription[] | null;
  loading: boolean;
}
export const SubcriptionsContent: React.FC<IProps> = ({
  subscriptions,
  loading,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const handleChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (subscriptions) {
      const results: any = subscriptions.filter((sub) => {
        let findSub =
          sub.firstName + " " + sub.lastName + " " + sub.phone_number;
        return findSub.toLowerCase().includes(searchTerm);
      });
      setSearchResults(searchTerm ? results : []);
    }
  }, [searchTerm]);
  if (loading) {
    return <Spinner />;
  }
  if (!subscriptions) {
    return <p>Failed to fetch subscriptions.</p>;
  }
  if (subscriptions.length <= 0) {
    return <p>Du har inga abbonemang registrerade ännu.</p>;
  }
  let searched: any;
  if (searchTerm === "") {
    searched = false;
  } else {
    searched = true;
  }
  return (
    <React.Fragment>
      <Heading size={3} title={`Abonnemang (${subscriptions.length} st)`} />
      <style.Subscriptions>
        <TextField
          variant="outlined"
          placeholder="Sök efter namn/nummer"
          value={searchTerm}
          onChange={handleChange}
        />

        {searched
          ? searchResults &&
            searchResults?.length &&
            searchResults?.map((sub: Subscription, key) => (
              <div>
                <style.SubscriptionCard
                  key={sub.id}
                  to={`/foretag/abonnemang/${sub.id}`}
                >
                  <style.LeftWrap>
                    <style.Meta>
                      <style.h3>Mobil</style.h3>
                      <style.span>{sub.operator}</style.span>
                    </style.Meta>
                  </style.LeftWrap>
                  <style.MiddleWrap>
                    <style.Meta>
                      <style.h3>
                        {sub.firstName} {sub.lastName}
                      </style.h3>
                      <style.span>{sub.phone_number}</style.span>
                    </style.Meta>
                  </style.MiddleWrap>
                  <style.RightWrap>
                    <style.Meta>
                      <style.h3>
                        {format(new Date(sub.subscription_time), "yyyy-MM-dd")}
                      </style.h3>
                      <style.span>
                        {sub.price} {sub.currency} / {sub.invoice_time}
                      </style.span>
                    </style.Meta>
                  </style.RightWrap>
                </style.SubscriptionCard>
              </div>
            ))
          : subscriptions?.map((subscription) => (
              <style.SubscriptionCard
                key={subscription.id}
                to={`/foretag/abonnemang/${subscription.id}`}
              >
                <style.LeftWrap>
                  <style.Meta>
                    <style.h3>Mobil</style.h3>
                    <style.span>{subscription.operator}</style.span>
                  </style.Meta>
                </style.LeftWrap>
                <style.MiddleWrap>
                  <style.Meta>
                    <style.h3>
                      {subscription.firstName} {subscription.lastName}
                    </style.h3>
                    <style.span>{subscription.phone_number}</style.span>
                  </style.Meta>
                </style.MiddleWrap>
                <style.RightWrap>
                  <style.Meta>
                    <style.h3>
                      {format(
                        new Date(subscription.subscription_time),
                        "yyyy-MM-dd"
                      )}
                    </style.h3>
                    <style.span>
                      {subscription.price} {subscription.currency} /{" "}
                      {subscription.invoice_time}
                    </style.span>
                  </style.Meta>
                </style.RightWrap>
              </style.SubscriptionCard>
            ))}
      </style.Subscriptions>
    </React.Fragment>
  );
};
