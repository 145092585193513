import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { sanitizePath } from "../../utils/sanitizers";
import { TaxonomyTerm } from "../../types/Category";
import * as style from "./CategoryBar.style";
import { AppContext } from "../../AppProvider";
interface Props {
  categories: Array<number>;
}
export const CategoryBar: React.FC<Props> = ({ categories }) => {
  const context = useContext(AppContext);

  const categoryBaseSlug = context?.settings?.category_slug; //useSelector(selectCategorySlug);
  const cats = context?.categories?.filter((cat: any) =>
    categories.includes(cat.id)
  );
  return (
    <style.Wrapper>
      <style.InnerWrapper>
        {cats &&
          cats.map((item: any) => {
            return (
              <div key={item.id}>
                <Link to={sanitizePath(`/${categoryBaseSlug}/${item.slug}`)}>
                  {item.icon && (
                    <div>
                      <img src={item.icon} alt="" />
                    </div>
                  )}
                  <p>{item.name}</p>
                </Link>
              </div>
            );
          })}
      </style.InnerWrapper>
    </style.Wrapper>
  );
};
