import React from "react";
import { CategoryItem, Heading } from "../../components";
import * as style from "./RelatedCategories.style";
import { ProductCategory } from "../../types/Category";
interface Props {
  title: string;
  categories: ProductCategory[];
}
// TODO: Make smart to work with Product.tsx and TS'ify
export const RelatedCategoriesContent: React.FC<Props> = ({
  title,
  categories,
}) => (
  <React.Fragment>
    <style.Header>
      <Heading size={3} title={title} />
    </style.Header>
    <style.Wrapper>
      {categories.slice(0, 2).map((category) => (
        <CategoryItem key={category.id} {...category} />
      ))}
    </style.Wrapper>
  </React.Fragment>
);
