import styled from "styled-components";
import { colors } from "../../../assets/style/variables";

export const Wrapper = styled.div`
  background-color: ${colors.accent};
  color: #fff;
  padding: 16px 0;
`

export const InnerWrapper = styled.div`
  margin: auto;
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  > div {
    display: flex;
    padding: 16px 25px;

    @media screen and (max-width: 991px) {
      border: 1px solid transparent;
    }

    @media screen and (min-width: 568px) and (max-width: 768px) {
      border-right-color: rgba(255,255,255, 0.2);

      :last-child {
        border-right-color: transparent;
      }
    }

    @media screen and (min-width: 568px) and (max-width: 767px) {
      flex: 0 0 50%;
      max-width: 50%;

      :nth-child(2n) {
        border-right-color: transparent;
      }

      :nth-child(n+3) {
        border-top-color: rgba(255,255,255, 0.2);
      }
    }

    @media screen and (max-width: 567px) {
      flex: 0 0 100%;
      max-width: 100%;

      :nth-child(n+2) {
        border-top-color: rgba(255,255,255, 0.2);
      }
    }

    > a {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;

      @media screen and (max-width: 991px) {
        flex-direction: row;
      }

      > div {
        @media screen and (max-width: 991px) {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 3em;
        }
      }

      > div > img {
        padding-bottom: 20px;

        @media screen and (max-width: 991px) {
          padding: 0 1em 0 0;
          max-width: 3em;
          max-height: 2em;
        }
      }
    }
  }
`
