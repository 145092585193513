import { useContext } from "react";
import { UserContext } from "../../../providers/UserProvider";
import { OrdersContent } from "./Orders.content";
import { OrdersTable } from "./OrdersTable";

import { Heading, Spinner, Container } from "../../../components";
import * as style from "./Orders.style";
export const Attestations = () => {
  const context = useContext(UserContext);

  return (
    <div id="orders">
      <style.Header>
        <Heading size={3} title="Attesteringar" />
      </style.Header>

      {context?.attestations?.length ? (
        <OrdersTable
          baseUrl="/foretag/attesteringar/"
          orders={context?.attestations ?? []}
        />
      ) : (
        <p>Det finns inga registrerade ordrar ännu.</p>
      )}
    </div>
  );
};
