import React, { useEffect } from "react";
import { setEventValue } from "../../utils";

import { Alert, Heading, Input } from "../../components";

import { Container, Page } from "../../modules";
import * as style from "./Login.style";
import { AlertTypes, ButtonSizes, ButtonTypes } from "../../types/Enums";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
interface Props {
  onLoginClick: (
    event: React.FormEvent | React.MouseEvent<HTMLElement>
  ) => void;
  errorMessage?: string;
  setUser: (user: string) => void;
  user: string;
  setPassword: (pw: string) => void;
  password: string;
  setRemember: (remember: boolean) => void;
  remember: boolean;
  loading: boolean;
}
export const LoginContent = ({
  onLoginClick,
  errorMessage,
  setUser,
  user,
  setPassword,
  password,
  setRemember,
  remember,
  loading,
}: Props) => {
  useEffect(() => {
    document.body.classList.add("is-bg");
    return () => document.body.classList.remove("is-bg");
  }, []);
  return (
    <Page paddedTop>
      <Container valign="center">
        <style.Wrapper>
          <style.Header>
            <Heading size={2} title="Logga in" />
            <style.Label>Företag</style.Label>
          </style.Header>
          <form onSubmit={onLoginClick}>
            <style.Fields>
              <Input
                size="small"
                type="text"
                name="user"
                placeholder="E-post"
                value={user}
                onChange={setEventValue(setUser)}
              />
              <Input
                size="small"
                type="password"
                name="password"
                placeholder="Lösenord"
                value={password}
                onChange={setEventValue(setPassword)}
              />
              {/* <style.Checkbox>
                <label>
                  <input
                    type="checkbox"
                    name="remember"
                    onClick={setEventValue(setRemember)}
                    onChange={setEventValue(setRemember)}
                    checked={remember}
                  />
                  Kom ihåg mig
                </label>
              </style.Checkbox> */}
            </style.Fields>
            <LoadingButton
              variant="contained"
              onClick={onLoginClick}
              loading={loading}
            >
              Logga in
            </LoadingButton>
            {errorMessage && (
              <style.Alert>
                <Alert
                  type={AlertTypes.DANGER}
                  title="Du loggades inte in"
                  text={errorMessage}
                />
              </style.Alert>
            )}
            <style.Links>
              {/*<li>
                <Link to="/registrera">Skapa konto</Link>
              </li>*}
              {/* <li>
                <Link to="/wp-login.php?action=lostpassword">Glömt lösenord</Link>
              </li> */}
              <li>
                <a href="/wp-login.php?action=lostpassword">glömt lösenord</a>
              </li>
            </style.Links>
          </form>
        </style.Wrapper>
      </Container>
    </Page>
  );
};
