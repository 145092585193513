import React from "react";

import { Radio } from "..";

import * as style from "./RadioGroup.style";
import { IRadioProps } from "../Radio/Radio";

interface IRadioGroupProps {
  options: IRadioProps[];
}
export const RadioGroup = ({ options }: IRadioGroupProps) => (
  <style.RadioGroup>
    {options.map((item, i) => (
      <Radio
        key={i}
        size={item.size}
        label={item.label}
        name={item.name}
        value={item.value}
        onChange={item.onChange}
        checked={item.checked}
      />
    ))}
  </style.RadioGroup>
);
