import React from "react";
import PropTypes from "prop-types";

import * as style from "./Input.style";

interface IInputProps {
  size?: string;
  type?: string;
  placeholder?: string;
  value: string;
  min?: number;
  name?: string;
  onChange: (ev: any) => void;
  autoComplete?: string;
  required?: boolean;
  disabled?: boolean;
}
export const Input = ({
  size,
  type,
  placeholder,
  value,
  min,
  name,
  onChange,
  autoComplete,
  required,
  disabled,
}: IInputProps) => {
  return (
    <style.Input
      inputSize={size}
      type={type ?? "text"}
      placeholder={placeholder}
      value={value}
      min={min}
      name={name ?? ""}
      onChange={onChange}
      autoComplete={autoComplete}
      required={required}
      disabled={disabled ?? false}
    />
  );
};
