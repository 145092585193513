import styled from "styled-components";
import { colors } from "../../assets/style/variables";
import { NotificationType } from "../../types/Notifications";
interface StyleProps {
  type: NotificationType,
};
const getBackgroundColor = (type: NotificationType): string => {
  switch (type) {
    case NotificationType.ERROR:
      return colors.red;
    case NotificationType.SUCCESS:
      return colors.green;
    default:
      return 'transparent';
  }
};
export const Notification = styled.div`
  position: relative;
  margin-top:10px;
  color: #fff;
  font-family: 'Montserrat',sans-serif;
  padding: 15px 32px;
  height: 79px;
  border-radius: 2px;
  background-color: ${({ type }: StyleProps) => getBackgroundColor(type)};
  h5 {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    margin-right: 50px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    display: inline;
    margin-right: 50px;
  }
  button {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);
    display: block;
    border: 0;
    cursor: pointer;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: rgb(0, 0, 0, .2);
    svg {
      transform: translate(-50%, -50%);
      display: block;
      width: 10px;
      height: 10px;
      margin: 9px 4px 0;
    }
  }
`;
export const Notifications = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: transparent;
  z-index: 402;
  padding: 20px;
  .item-enter {
    opacity: 0;
  }
  .item-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  .item-exit {
    opacity: 1;
    h5, p, button {
      opacity: 1;
    }
  }
  .item-exit-active {
    opacity: 0;
    height: 0;
    padding: 0 32px;
    margin-top: 0;
    transition: 
      opacity 400ms ease-in, 
      height 400ms ease-in, 
      padding 400ms ease-in,
      margin-top 400ms ease-in;
      h5, p, button {
        opacity: 0;
        transition: opacity 300ms ease-in;
      }
  }
`;
