import { format, parseISO } from "date-fns";
import { PaymentMethod, LineItemAttribute } from "../types/Order";
import { CartItemOptions } from "../types/Cart";
export const formatOrderDate = (date: string): string => {
  return format(parseISO(date), 'yyyy-MM-dd HH:mm');
};
export const mapOrderStatus = (status: string): string => {
  switch (status) {
    case 'pending':
      return 'Beställning mottagen';
    case 'processing':
    case 'on-hold':
      return 'Beställningen behandlas';
    case 'failed':
    case 'cancelled':
      return 'Makulerad / Avbeställd';
    case 'refunded':
      return 'Makulerad / Återbetald';
    case 'completed':
      return 'Skickad';
    case 'certification':
      return 'Väntar på attestering';
    default:
      return status;
  };
};
export const mapPaymentMethod = (method: PaymentMethod): string => {
  switch (method) {
    case 'Invoice to billing email':
      return 'Fakturan skickas till e-post';
    case 'Invoice to physical adress':
      return 'Fakturan skickas till postadress';
    case 'Pays by installment':
      return 'Delbetalning';
    default:
      return method;
  };
};
export const mapLineItemAttributes = (
  attributes: LineItemAttribute[]
): CartItemOptions => {
  const attributesMap = attributes.reduce((acc, current) => ({
    ...acc,
    [current.key]: current.value,
  }), {
    fullName: '',
  });
  return attributesMap;
}
