import React, { useState, useEffect, useRef, useContext } from "react";

import { NavContent } from "./Nav.content";
import { UserContext } from "../../providers/UserProvider";
import { AppContext } from "../../AppProvider";
export const Nav = () => {
  const wrapperRef = useRef<HTMLDivElement>();
  const userContext = useContext(UserContext);
  const appContext = useContext(AppContext);
  const user = userContext?.user; //useSelector((state) => (state as any).user.user);
  const isLoggedIn = !!user; //useSelector(selectIsLoggedIn);

  const primaryNavigation = appContext?.settings?.primary_navigation; //useSelector(selectPrimaryNavigation);
  const categorySlug = appContext?.settings?.category_slug; //useSelector(selectCategorySlug);
  console.log("Fix this");
  const pageHasPaddedTop = false; //appContext?.settings?useSelector(selectIsPaddedTop);
  const [modal, setModal] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const [navTop, setNavTop] = useState(0);
  const [navFixed, setNavFixed] = useState(false);
  const [navHasBg, setNavHasBg] = useState(false);
  const [navHidden, setNavHidden] = useState(false);
  const refValues = useRef({
    scrollY: window.scrollY, // current scroll position
    turnPosition: 0, // position for scroll direction turn
    scrollDirection: 1, // current scroll direction (1 down, -1 up)
    navInViewport: true,
  });

  useEffect(() => {
    const handleClick = (event: any) => {
      if (!wrapperRef.current?.contains(event.target)) {
        setNavOpen(false);
        setModal(false);
      } else {
        if (event.target.closest("a")) {
          setNavOpen(false);
          setModal(false);
        }
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);
  // Handle scroll
  useEffect(() => {
    const handleScroll = () => {
      // Get wrapper element height
      let elemHeight = 0;
      if (wrapperRef.current) {
        elemHeight = wrapperRef.current.getBoundingClientRect().height;
      }
      // Determine scroll direction
      const scrollDirection =
        window.scrollY >= refValues.current.scrollY ? 1 : -1;
      // When scroll has changed direction
      if (scrollDirection !== refValues.current.scrollDirection) {
        refValues.current.turnPosition = window.scrollY;
        // If scroll direction is downwards (and direction has just changed)
        if (scrollDirection === 1) {
          setNavFixed(false);
          setNavTop(window.scrollY);
        } else {
          setNavFixed(true);
          setNavTop(0);
          setNavHasBg(true);
        }
      }
      // If scroll direction is downwards
      if (scrollDirection === 1) {
        if (
          refValues.current.navInViewport &&
          window.scrollY >= refValues.current.turnPosition + elemHeight
        ) {
          refValues.current.navInViewport = false;
          setNavOpen(false);
          setNavHidden(true);
          setModal(false);
        }
        if (window.scrollY < 0) {
          refValues.current.turnPosition = 0;
          setNavFixed(false);
          setNavTop(0);
          setNavHasBg(false);
        }
      }
      // If scrolling is upwards
      if (scrollDirection === -1) {
        refValues.current.navInViewport = true;
        setNavHidden(false);
        if (window.scrollY <= 0) {
          refValues.current.turnPosition = 0;
          setNavFixed(false);
          setNavTop(0);
          setNavHasBg(false);
        }
      }
      // Update refvalues
      refValues.current.scrollY = window.scrollY;
      refValues.current.scrollDirection = scrollDirection;
    };
    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [wrapperRef]);
  const toggleModal = () => {
    setModal((prevState) => !prevState);
    setNavOpen(false);
  };
  const toggleNav = () => {
    setNavOpen((prevOpen) => !prevOpen);
    setModal(false);
  };
  return (
    <NavContent
      user={user}
      toggleModal={toggleModal}
      modal={modal}
      isLoggedIn={isLoggedIn ?? false}
      wrapperRef={wrapperRef}
      navigation={primaryNavigation}
      categorySlug={categorySlug}
      navOpen={navOpen}
      toggleNav={toggleNav}
      navTop={navTop}
      navFixed={navFixed}
      navHasBg={navHasBg}
      navHidden={navHidden}
      pageHasPaddedTop={pageHasPaddedTop}
    />
  );
};
