import styled from "styled-components";
import { colors } from "../../../assets/style/variables";

export const Header = styled.div`
  margin-bottom: 32px;
`

export const OrderHeader = styled.div`
  font-weight: 600;
  border-radius: 3px 3px 0 0;
  padding: 20px 32px;
  background-color: ${colors.darkgray};
`

export const Labels = styled.div`
  width: 70%;
  display: flex;

  @media screen and (max-width: 991px) {
    width: 90%;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  @media screen and (max-width: 567px) {
    flex-wrap: wrap;
  }

  div {
    width: 33.333%;
    word-break: break-word;
    hyphens: auto;

    @media screen and (max-width: 567px) {
      width: 50%;
    }
  }
`
