export const colors = {
  accent: "#255958",
  darkaccent: "#255958",
  darkaccentligther: "#255958",

  white: "#FFFFFF",
  black: "#000000",

  lightgreen: "#ECFDF4",
  green: "#13CE66",
  darkgreen: "#12C360",

  lightred: "#FFE0E0",
  red: "#FF4949",
  darkred: "#AD0000",

  lightgray: "#F1F1F1",
  gray: "#F7F7F7",
  darkgray: "#DBDBDB",
}

