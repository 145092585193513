import * as pages from "./pages";
import { RouteProps } from "react-router-dom";
import { RedirectToCategories } from "./pages/Category/RedirectToCategories";
import { ComponentType } from "react";

interface IRoute {
  path: string;
  component?: ComponentType;
  isProduct?: boolean;
  isCategory?: boolean;
}
export const businessRoutes: IRoute[] = [
  {
    path: "/foretag/dokument",
    component: pages.Documents,
  },
  {
    path: "/foretag/abonnemang",
    component: pages.Subscriptions,
  },
  {
    path: "/foretag/abonnemang/:id",
    component: pages.Subscription,
  },
  {
    path: "/foretag/bestallning",
    component: pages.Orders,
  },
  {
    path: "/foretag/bestallning/:id",
    component: pages.OrderSummary,
  },
  /*
  {
    path: "/foretag/installning",
    component: pages.Settings,
  },
  */
  {
    path: "/foretag/anvandare",
    component: pages.Users,
  },
];

export const mainRoutes: IRoute[] = [
  {
    path: `/PRODUCT_ROOT_SLUG/:product_slug`, // will be replaced on render
    isProduct: true,
    component: pages.Product,
  },
  {
    path: `/CATEGORY_ROOT_SLUG/:categorySlug?/:filterCategorySlug?`, // will be replaced on render
    isCategory: true,
    component: pages.Category,
  },
  {
    path: `/PRODUCT_ROOT_SLUG`, // will be replaced on render
    isProduct: true,
    component: RedirectToCategories,
  },
  {
    path: "/foretag/:tab?/:id?",
    component: pages.BusinessFolder,
  },
  {
    path: "/kundvagn/tack",
    component: pages.ThankYou,
  },
  {
    path: "/kundvagn/bestall",
    component: pages.Checkout,
  },
  {
    path: "/kundvagn",
    component: pages.CartSummary,
  },
  {
    path: "/login",
    component: pages.Login,
  },

  {
    path: "/wp-login.php?action=lostpassword",
    // component: pages.ForgotPassword,
  },
  {
    path: "/",
    component: pages.Start,
  },
  {
    path: "*",
    component: pages.DynamicRoute,
  },
];
