import React, { useEffect, ChangeEvent } from "react";
import { ProductItem, Heading, RichText, WrapText } from "../../components";
import { Container, Page } from "../../modules";
import { ProductCategory, CategoryHero } from "../../types/Category";
import { Product } from "../../types/Product";
import { UspBlock } from "../../blocks";

import { isNotEmptyString } from "../../utils";
import * as style from "./Category.style";
interface CategoryProps {
  category?: ProductCategory;
  productsHero?: CategoryHero;
  productsInCategory: Product[];
  childCategories?: Array<ProductCategory>;
  onFilterChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  selectedFilter: string;
}
export const CategoryContent: React.FC<CategoryProps> = ({
  category,
  productsHero,
  productsInCategory,
  childCategories,
  onFilterChange,
  selectedFilter,
}) => {
  useEffect(() => {
    document.body.classList.add("fixed-top");
    return () => document.body.classList.remove("fixed-top");
  }, []);
  const textColumns = [
    category?.bottomTextLeft,
    category?.bottomTextRight,
  ].filter(isNotEmptyString);
  const hero: CategoryHero | undefined = category
    ? {
        header: category.name,
        image: category.heroImage || "",
        text: category.description || "",
        text_color: category.heroTextColor,
      }
    : productsHero;
  return (
    <Page>
      {hero && (
        <style.Header image={hero.image} textColor={hero.text_color}>
          <style.Container>
            <Heading size={1} title={hero.header} />
            {hero.text && <WrapText text={hero.text} />}
          </style.Container>
        </style.Header>
      )}
      <Container>
        <style.Flex>
          <div>
            {childCategories && childCategories.length > 1 && (
              <style.FilterWrap>
                <p>Filtrera på:</p>
                <style.FilterSelect>
                  <select onChange={onFilterChange} value={selectedFilter}>
                    <option value="">Visa alla</option>
                    {childCategories.map((cat) => (
                      <option key={cat.id} value={cat.slug}>
                        {cat.name}
                      </option>
                    ))}
                  </select>
                </style.FilterSelect>
              </style.FilterWrap>
            )}
          </div>
          {productsInCategory.length > 0 && (
            <p>{`${productsInCategory.length} produkt${
              productsInCategory.length > 1 ? "er" : ""
            }`}</p>
          )}
        </style.Flex>
        {productsInCategory.length > 0 ? (
          <style.ProductItems>
            {productsInCategory.map((product) => (
              <ProductItem key={product.id} {...product} />
            ))}
          </style.ProductItems>
        ) : (
          <div>Inga produkter hittades</div>
        )}
        {textColumns.length > 0 && (
          <style.TextColums>
            <div>
              {textColumns.map((col, index) => (
                <RichText key={index} markup={col} />
              ))}
            </div>
          </style.TextColums>
        )}
      </Container>
      <UspBlock />
    </Page>
  );
};
