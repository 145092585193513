import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

import { sanitizePath } from "../../utils/sanitizers";
import { AppContext } from "../../AppProvider";
export const RedirectToCategories = () => {
  const context = useContext(AppContext);
  const categorySlug = context?.settings?.category_slug;
  if (!categorySlug) {
    return null;
  }
  return <Navigate to={sanitizePath(`/${categorySlug}`)} />;
};
