import React, { useEffect, useCallback, useContext } from "react";
import { Navigate, useParams } from "react-router-dom";

import { SubscriptionContent } from "./Subscription.content";
import { UserContext } from "../../../providers/UserProvider";
import { AppContext } from "../../../AppProvider";

export const Subscription = () => {
  const { id } = useParams();

  const userContext = useContext(UserContext);
  const subscription = userContext.subscriptions?.find((x) => x.id === id); //.getSubscription(id);
  /*
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSubscriptionsForUser());
  }, [dispatch]);

  const subscription = useSelector(useCallback(selectSubscription(id), []));
*/
  if (subscription) {
    return <SubscriptionContent subscription={subscription} />;
  } else {
    return <Navigate to={"/foretag/abonnemang"} />;
  }
};
