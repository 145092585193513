import React, { useContext } from "react";
import { NotificationItem } from "./NotificationItem/NotificationItem";

import * as style from "./Notifications.style";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { AppContext } from "../../AppProvider";

export const Notifications = () => {
  const notifications = useContext(AppContext)?.notifications; // useSelector(selectNotifications);
  const onCloseClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  return (
    <style.Notifications>
      <TransitionGroup className="notification-list">
        {notifications &&
          notifications.map((notification: any) => (
            <CSSTransition
              key={notification.id}
              timeout={3000}
              classNames="item"
            >
              <NotificationItem
                id={notification.id}
                type={notification.type}
                closeTimer={notification.closeTimer}
                title={notification.title}
                text={notification.text}
                onCloseClick={onCloseClick}
              ></NotificationItem>
            </CSSTransition>
          ))}
      </TransitionGroup>
    </style.Notifications>
  );
};
