import React from "react";
import { formatCost } from "../../utils";
import { Heading, CartItem, CartEnd } from "../../components";
import { ICart } from "../../types/Cart";
import * as style from "./Cart.style";
interface ICartProps {
  cart?: ICart | null;
  removeCartItem: Function;
  monthTotal: number;
  shippingCost: number;
  cartTotal: number;
}

export const Cart = ({
  cart,
  removeCartItem,
  monthTotal,
  shippingCost,
  cartTotal,
}: ICartProps) => {
  return (
    <React.Fragment>
      <style.Wrapper>
        <style.Header>
          <Heading size={3} title={`Din varukorg (${cart?.item_count ?? 0})`} />
        </style.Header>
        {cart?.items?.map((item) => (
          <CartItem
            key={item.item_key}
            selectedOptions={item.cart_item_data?.selected_options}
            subscription={item.cart_item_data?.subscription}
            onClick={() => removeCartItem(item.item_key)}
            image={item.featured_image}
            title={item.title}
            price={"" + item.totals.total}
            quantity={item.quantity.value}
            variationId={item.id}
            productId={item.id}
          />
        ))}
        {cart?.item_count === 0 && <p>Din varukorg är tom</p>}
      </style.Wrapper>
      <style.Bottom>
        <CartEnd
          small={false}
          monthlyCost={monthTotal}
          shippingCost={"" + shippingCost}
          cartTotal={formatCost(cartTotal)}
        />
      </style.Bottom>
    </React.Fragment>
  );
};
