import React, { useContext, useEffect } from "react";

import { Notification } from "../../../types/Notifications";
import * as style from "../Notifications.style";
import { Cross } from "../../../assets/icons";
import { AppContext } from "../../../AppProvider";

interface props extends Notification {}
export const NotificationItem: React.FC<props> = ({
  id,
  type,
  text,
  closeTimer,
  title,
  onCloseClick,
}) => {
  const context = useContext(AppContext);

  const onCloseClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    context?.removeNotification?.(id);
  };
  useEffect(() => {
    let timer: number;
    if (closeTimer) {
      timer = window.setTimeout(() => {
        context?.removeNotification?.(id);
      }, closeTimer);
    }
    return () => {
      if (timer) {
        window.clearTimeout(timer);
      }
    };
  }, [closeTimer, id]);
  return (
    <style.Notification type={type} role="alert">
      <button onClick={onCloseClick2}>
        <Cross stroke="#fff" />
      </button>
      {title && <h5>{title}</h5>}
      <p>{text}</p>
    </style.Notification>
  );
};
export default NotificationItem;
