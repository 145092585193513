import React, { useContext } from "react";

import { DashNavContent } from "./DashNav.content";
import { UserContext } from "../../providers/UserProvider";

export const DashNav = () => {
  const userContext = useContext(UserContext);

  const onLogoutClick = (e: any) => {
    userContext?.logOut?.();
  };

  return (
    <DashNavContent user={userContext?.user} onLogoutClick={onLogoutClick} />
  );
};
