import axios from "axios";
import { defaultHeaders } from ".";
import { Order } from "../types/Order";

const baseUrl = '/wp-json/api/orders';
export class OrderApi {

    static async all():Promise<Order[]> {
        const response = await axios.get<Order[]>(`${baseUrl}`,{headers:defaultHeaders() as any});
        return response.data;
    }

    static async create(cart: any, options: any): Promise<Order> {
        if (!cart || !options) {
          Promise.reject('Missing cart or options');
        }
        const response = await axios.post<Order>(baseUrl,{cart:cart,options:options},{headers:defaultHeaders() as any})
        return response.data;
    };

    static async attest(id:number):Promise<Order>{
        const response = await axios.post<Order>(`${baseUrl}/attest/${id}`,{},{headers:defaultHeaders() as any})
        console.log("New order ",response.data);
        return response.data;
    }
}