import { createGlobalStyle } from 'styled-components'
import reset from "styled-reset";

const GlobalStyle = createGlobalStyle`

${reset}



* {
  box-sizing: border-box;
}

body {
  background-color: #fff;
  font-family: "Montserrat", sans-serif;
  line-height: 1.6;
  height: 100%;
  position: relative;
  min-height: 100vh;
}

body,
#root,
#App {
  min-height: 100vh;
}

#App {
  display: flex;
  flex-direction: column;
}

#page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

#footer {
  margin-top: auto;
}

body.is-bg {
  background-color: #f7f7f7;
}

body.modal-open,
body.menu-open {
  overflow: hidden;
}

body.dropdown-open #nav.top {
  background-color: #fff !important;
  color: #000 !important;
}

body.modal-open::after {
  opacity: 1;
  height: 100vh;
  transform: translateY(0);
  transition: opacity 0.3s;
}

body::after {
  content: "";
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 20, 32, 0.2);
  transition: opacity 0.3s;
  transform: translateY(-100%);
  opacity: 0;
  z-index: 1031;
}

a {
  text-decoration: none;
  color: inherit;
}

strong {
  font-weight: 600;
}

.subtitle {
  font-size: 14px;
  color: #979797;
  text-transform: uppercase;
  font-weight: 600;
}

h2, h3, h4 {
  font-weight: 600;
  line-height: 1.2;
}

h1 {
  font-weight: 700;
  font-size: 48px;
  line-height: 1.2;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

.has-x-large-font-size {
  font-size:42px;
}
.has-large-font-size {
  font-size:36px;
}
.has-medium-font-size {
  font-size:20px;
}
.has-small-font-size {
  font-size:13px;
}
`

export default GlobalStyle;
