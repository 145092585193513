import React, { useContext } from "react";
import { formatCost, getProductUrl } from "../../utils";
import { Product } from "../../types/Product";
import * as style from "./ProductItem.style";

import { AppContext } from "../../AppProvider";
import { UserContext } from "../../providers/UserProvider";
interface Props extends Product {}
export const ProductItem: React.FC<Props> = (product) => {
  const user = useContext(UserContext)?.user; //useSelector(selectUser);
  const baseProductSlug = useContext(AppContext).settings?.product_slug; //useSelector(selectProductSlug);
  return (
    <style.Item to={getProductUrl(product, baseProductSlug || "")}>
      <style.Image>
        <div>
          {product.images.length > 0 && (
            <img src={product.images[0]} alt={product.title} />
          )}
        </div>
      </style.Image>
      <strong>{product.title}</strong>
      {product.price && user?.company && (
        <p>{`${formatCost(product.price)} kr`}</p>
      )}
    </style.Item>
  );
};
