import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Spinner } from "../../components";
import { Container, Page } from "../../modules";
import { GutenbergBlock } from "../../gutenbergBlocks";
import { callGetPageBySlug } from "../../api";
import { NotFound } from "..";
import { AppContext } from "../../AppProvider";

export const DynamicRoute = () => {
  const context = useContext(AppContext);
  const location: any = useLocation() as any;
  const pathname = location.location?.pathname ?? location.pathname; //useLocation();
  const pages = context?.pages ?? [];
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(
    pages.find((page) => page?.slug === pathname)
  );

  const loadPage = async (slug: string) => {
    console.log("loadPage ", slug);
    try {
      const p = await callGetPageBySlug(slug);
      setPage(p);
    } catch (e: any) {
      if (e.status === 404) {
        setNotFound(true);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    loadPage(pathname);
  }, [pathname]);
  if (loading) {
    return (
      <Page paddedTop>
        <Container valign="center">
          <Spinner />
        </Container>
      </Page>
    );
  }
  if (notFound) {
    return <NotFound />;
  }
  if (page && page.blocks) {
    return (
      <Page>
        {page.blocks.map((props: any) => (
          <GutenbergBlock key={props.uid} {...props} />
        ))}
      </Page>
    );
  }
  return (
    <Container>
      <div>Error: Page could not be rendered.</div>
    </Container>
  );
};
