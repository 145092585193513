import React from "react";
import * as style from "./ColorScheme.style";
interface IProps {
  onVariantSelect: Function;
  values: string[];
  activeVariant: any;
}
export const ColorScheme: React.FC<IProps> = ({
  onVariantSelect,
  values,
  activeVariant,
}) => {
  const split = activeVariant.split("#");
  const name = split[0];
  return (
    <div
    // onClick={() =>
    //   onVariantSelect({ attribute: name, value: x})
    // }
    >
      Välj färg: <style.BoldText>{name}</style.BoldText>
      <style.Wrap>
        {values.map((val) => (
          <Color
            key={val}
            value={val}
            onVariantSelect={onVariantSelect}
            active={activeVariant === val}
          />
        ))}
      </style.Wrap>
      {/* {selected ? x} {activeVariant.attributes[`attribute_${name}`] === x ? 'X' : ''}  */}
    </div>
  );
};

interface ColorIProps {
  onVariantSelect: Function;
  value: string;
  active: boolean;
}
const Color: React.FC<ColorIProps> = ({ onVariantSelect, value, active }) => {
  const split = value.split("#");
  const hex = split[1];
  return (
    <style.ColorWrap onClick={() => onVariantSelect(value)} active={active}>
      <style.Bubble color={hex}></style.Bubble>
    </style.ColorWrap>
  );
};
