import styled from "styled-components"
export const Wrap = styled.div`
  display: flex;
  margin-top: 10px;
`
export const BoldText = styled.span`
  font-weight: 800;
`
interface ColorWrapIProps {
  active: boolean
}
export const ColorWrap = styled.div<ColorWrapIProps>`
  background: #F1F1F1;
  border: ${props => props.active ? '1px solid #BE8567' : 'none'};
  padding: 8px;
  margin-right: 10px;
  transition: opacity .5s ease;
  cursor: pointer;
  &:hover {
    opacity: .4;
  }
`
interface BubbleIProps {
  color: string
}
export const Bubble = styled.div<BubbleIProps>`
  background: ${props => props.color ? `#${props.color}` : '#ccc'};
  border-radius: 100%;
  width: 30px;
  height: 30px;
`
