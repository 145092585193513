import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { DashNav, Container, Page } from "../../modules";
import { useTransition, animated } from "react-spring";

import * as style from "./index.style";
import { Documents } from "./Documents/Documents";
import { Orders } from "./Orders/Orders";
import { Subscriptions } from "./Subscriptions/Subscriptions";
import { Settings } from "./Settings/Settings";
import { OrderSummary } from "./OrderSummary/OrderSummary";
import { Subscription } from "./Subscription/Subscription";
import { Users } from "./Users/Users";
import { Attestations } from "./Orders/Attestations";

export const BusinessFolder = () => {
  const location = useLocation();
  const transitions = useTransition(location, (location) => location.pathname, {
    config: { duration: 200 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });
  const { tab, id } = useParams();

  return (
    <Page paddedTop>
      <style.BusinessWrapper>
        <DashNav />
        <Container>
          {transitions.map(({ item: location, props, key }) => (
            <animated.div key={key} style={props}></animated.div>
          ))}
          {
            {
              bestallning: id ? <OrderSummary /> : <Orders />,
              dokument: <Documents />,
              abonnemang: id ? <Subscription /> : <Subscriptions />,
              installning: <Settings />,
              anvandare: <Users />,
              attesteringar: <Attestations />,
            }[tab ?? "bestallning"]
          }
        </Container>
      </style.BusinessWrapper>
    </Page>
  );
};
