import styled from "styled-components";
import { colors } from "../../assets/style/variables";

export const Wrapper = styled.div`
ul {
  display: block;

  @media screen and (max-width: 767px) {
    margin-bottom: 32px;
  }

  li {
    display: block;

    @media screen and (min-width: 768px) {
      display: inline-block;
    }

    + li {
      @media screen and (min-width: 768px) {
        margin-left: 40px;
      }
    }

    a {
      display: block;

      &.active {
        font-weight: 600;

        @media screen and (min-width: 768px) {
          height: 40px;
          border-bottom: 3px solid #be8567;
        }
      }
    }
  }
}
`

export const InnerWrapper = styled.div`
background-color: #fff;
border-bottom: 1px solid;
border-bottom-color: ${colors.darkgray};
padding-top: 32px;
margin-bottom: 32px;

@media screen and (min-width: 768px) {
  margin-bottom: 64px;
}

nav {
  text-align: center;
}
`

export const Header = styled.div`
text-align: center;
margin-bottom: 32px;

@media screen and (min-width: 768px) {
  margin-bottom: 64px;
}

p {
  color: #979797;
}
`
