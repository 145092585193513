import styled from "styled-components";

export const Alert = styled.div`
margin-top: 20px;
`;

export const Inputs = styled.div`
margin-top: 20px;

  label {
    color: #979797;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;

    span {
      color: #DB3639;
    }
  }

  input {
    width: 100%;
    margin-top: 9px;
    margin-bottom: 20px;
  }

  > button {
    margin-top: 37px;
  }
`;
