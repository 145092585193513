import React, { useContext } from "react";

import { getRootCategories } from "../../utils";
import { AppContext } from "../../AppProvider";
import { RelatedCategoriesContent } from "./RelatedCategories.content";
interface Props {
  title: string;
  categoryIds: number[];
}
export const RelatedCategories: React.FC<Props> = ({ title, categoryIds }) => {
  const context = useContext(AppContext);
  const categories = context?.categories ?? [];
  const relatedCategories = getRootCategories(categories).filter(
    (cat) => !categoryIds.includes(cat.id)
  );
  return relatedCategories && relatedCategories.length > 0 ? (
    <RelatedCategoriesContent title={title} categories={relatedCategories} />
  ) : null;
};
