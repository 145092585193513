import styled from "styled-components";
import { Link } from "react-router-dom";
import { colors } from "../../../assets/style/variables";

export const Header = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 32px;
padding-bottom: 32px;
border-bottom: 1px solid;
border-bottom-color: ${colors.lightgray};
`

export const Status = styled.div`
text-align: right;
`

export const IconLink = styled(Link)`
display: flex;
align-items: center;
margin-bottom: 32px;

svg {
  margin-right: 20px;
}
`
interface CardProps {
  spacing:boolean;
}
export const Card = styled.div<CardProps>`
background-color: #fff;
padding: 32px 0;
margin: ${(props) => props.spacing ? "32px 0" : "0"};
`

export const Cart = styled.div`
background-color: #fff;
`

export const Container = styled.div`
margin: auto;
max-width: 1200px;
width: 100%;
padding: 0 32px;
`

export const Flex = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
`

export const Details = styled(Flex)`
border-bottom: 1px solid;
border-bottom-color: ${colors.lightgray};
padding-bottom: 32px;
`
