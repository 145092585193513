import React from "react";
import { FooterColumns } from "../../types/Settings";
import * as style from "./Footer.style";

import { RichText, WPLink } from "../../components";
interface Props {
  isLoggedIn: boolean;
  columns?: FooterColumns;
  footerText?: string;
}
export const FooterContent: React.FC<Props> = ({
  isLoggedIn,
  columns,
  footerText,
}) => {
  return (
    <style.Footer as="footer" id="footer">
      <style.Container>
        <style.Content>
          {columns?.map((column) => (
            <style.FooterColumn key={column.id}>
              <ul>
                {column.column.map((item) => (
                  <li key={item.id}>
                    <WPLink to={item.page.url} target={item.page.target}>
                      {item.page.title}
                    </WPLink>
                  </li>
                ))}
              </ul>
            </style.FooterColumn>
          ))}
          {footerText && (
            <style.FooterColumn>
              <style.FooterText>
                <RichText markup={footerText} />
              </style.FooterText>
            </style.FooterColumn>
          )}
          <style.FooterColumn></style.FooterColumn>
        </style.Content>
      </style.Container>
    </style.Footer>
  );
};
