import React from "react";
import { NavLink, Link } from "react-router-dom";

import { Heading } from "../../components";

import * as style from "./DashNav.style";
import { User } from "../../types/User";
interface IDashNavProps {
  user?: User;
  onLogoutClick: (e: any) => void;
}
export const DashNavContent = ({ user, onLogoutClick }: IDashNavProps) => (
  <style.Wrapper>
    <style.InnerWrapper>
      {user?.company && (
        <style.Header>
          <Heading size={3} title={user.company.companyName} />
          <p>{`${user.firstName} ${user.lastName} (${user.company.companyId})`}</p>
        </style.Header>
      )}

      <nav>
        <ul>
          <li>
            <NavLink to="/foretag/bestallning">Orderhistorik</NavLink>
          </li>
          <li>
            <NavLink to="/foretag/dokument">Dokument</NavLink>
          </li>
          <li>
            <NavLink to="/foretag/abonnemang">Abonnemang</NavLink>
          </li>
          <li>
            <NavLink to="/foretag/installning">Kontouppgifter</NavLink>
          </li>
          {user?.company?.users && (
            <li>
              <NavLink to="/foretag/anvandare">Användare</NavLink>
            </li>
          )}
          {user?.company?.attestations && (
            <li>
              <NavLink to="/foretag/attesteringar">Attesteringar</NavLink>
            </li>
          )}
          <li>
            <Link to="/" onClick={onLogoutClick}>
              Logga ut
            </Link>
          </li>
        </ul>
      </nav>
    </style.InnerWrapper>
  </style.Wrapper>
);
