export const checkOk = (res: Response): Promise<Response> => {
  if (res.ok) {
    return Promise.resolve(res);
  }
  return Promise.reject(res);
};
export const onImageErrorHandler = (fallbackImage: string) => (
  event: React.SyntheticEvent<HTMLImageElement, Event>
): void => {
  const imageElem = event.target as HTMLImageElement;
  imageElem.src = fallbackImage;
};
