import React, { useContext, useEffect, useState } from "react";

import { Container, Page } from "../../modules";
import { Spinner } from "../../components";
import { GutenbergBlock } from "../../gutenbergBlocks";
import { UspBlock } from "../../blocks";
import { AppContext } from "../../AppProvider";
import { callGetFrontPage } from "../../api";

export const Start = (props: any) => {
  const context = useContext(AppContext);
  const pages = context?.pages;

  const [content, setContent] = useState(
    pages?.find((page) => page.slug === "start-page")
  );
  const [contentLoading, setContentLoading] = useState(false);

  useEffect(() => {
    if (!content && !contentLoading) {
      setContentLoading(true);

      callGetFrontPage()
        .then((data: any) => setContent(data))
        .finally(() => setContentLoading(false));
    }
    setContentLoading(false);
  }, []);

  return contentLoading ? (
    <Page paddedTop>
      <Container valign="center">
        <Spinner />
      </Container>
    </Page>
  ) : content ? (
    <>
      {content.blocks?.map((block: any) => (
        <GutenbergBlock key={block.uid} {...block} />
      ))}
      <UspBlock />
    </>
  ) : (
    <Container>
      <div>Error: Page could not be rendered.</div>
    </Container>
  );
};
