import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Order } from "../../../types/Order";
import { formatOrderDate, mapOrderStatus } from "../../../utils";
import { Arrow } from "../../../assets/icons";
import { Link } from "react-router-dom";

interface IOrdersTableProps {
  baseUrl: string;
  orders: Order[];
}

export const OrdersTable = ({ baseUrl, orders }: IOrdersTableProps) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableCell>Ordernr</TableCell>
          <TableCell>Beställningsdatum</TableCell>
          <TableCell>Status</TableCell>
          <TableCell></TableCell>
        </TableHead>
        <TableBody>
          {orders.map((order) => (
            <TableRow>
              <TableCell>
                <strong>#{order.orderData.id}</strong>
              </TableCell>
              <TableCell>
                {formatOrderDate(order.orderData.date_created.date)}
              </TableCell>
              <TableCell>{mapOrderStatus(order.orderData.status)}</TableCell>
              <TableCell>
                <Link to={`${baseUrl}${order.orderData.id}`}>
                  <Arrow />
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
