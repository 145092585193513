import styled from "styled-components";

interface IListItemProps {
  isSelected:boolean;
}
export const ListItem = styled.div`
  position: relative;
  border: #dbdbdb solid 1px;
  border-radius: 2px;
  padding: 20px 20px 20px 50px;
  cursor: pointer;
  margin-bottom: 20px;

  &:hover {
    background-color: #f7f7f7;
  }

  &:focus {
    outline: none;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 20px;
    left: 16px;
    border: #BF8667 solid 2px;
    border-radius: 50%;
    width: 19px;
    height: 19px;
    background-color: #fff;
  }

  &:after {
    content: '';
    display: ${(props:IListItemProps) => props.isSelected ? 'block' : 'none'};
    position: absolute;
    top: 20px;
    left: 16px;
    margin-top: 5px;
    margin-left: 5px;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    background-color: #BF8667;
  }
`;
