import React, { useContext, useEffect } from "react";
import { Cart, CartNav, Container, Page } from "../../modules";
import { Pagination } from "../../components";
import { ICart } from "../../types/Cart";
import { Product } from "../../types/Product";
import { UserContext } from "../../providers/UserProvider";

interface ICartSummaryProps {
  removeItem: any;
  shippingTotal?: number;
  cart?: ICart | null;
  products: Product[];
  monthTotal: number;
  cartTotal: number;
  loading: boolean;
}
export const CartSummaryContent = ({
  shippingTotal,
  cart,
  products,
  removeItem,
  monthTotal,
  cartTotal,
  loading,
}: ICartSummaryProps) => {
  useEffect(() => {
    document.body.classList.add("is-bg");
    return () => {
      document.body.classList.remove("is-bg");
    };
  }, []);
  const context = useContext(UserContext);
  const cartEmpty = cart?.item_count === 0;

  return (
    <Page paddedTop loading={loading}>
      <Container>
        <CartNav cartEmpty={cartEmpty} />
        <div onClick={() => context?.clearCart?.()}>Töm varukorgen</div>
        <Cart
          removeCartItem={removeItem}
          cart={cart}
          monthTotal={monthTotal}
          shippingCost={shippingTotal ? Number(shippingTotal) : 0}
          cartTotal={cartTotal}
        />
        {/* TODO: Make shure that previous button really links to previous page. Mabye thru state */}
        <Pagination next={!cartEmpty ? "/kundvagn/bestall" : ""} previous="/" />
      </Container>
    </Page>
  );
};
