import React from "react";
import { Modal, Input, Alert } from "../../../components";

import * as style from "../Product.style";
import { AlertTypes } from "../../../types/Enums";
interface Props {
  node: any;
  fullName: string;
  validationAlert: boolean;
  onAddToCartClick: EventListener;
  onFullNameChange: EventListener;
  onCloseClick: EventListener;
}
export const PhoneModal: React.FC<Props> = ({
  node,
  fullName,
  validationAlert,
  onAddToCartClick,
  onFullNameChange,
  onCloseClick,
}) => (
  <Modal
    node={node}
    title="Användare av telefonen"
    description="Vem på företaget tilldelas telefonen."
    onSubmit={onAddToCartClick}
    handleClose={onCloseClick}
  >
    <style.Label>
      Fullständigt namn
      <Input
        type="input"
        placeholder="Fullständigt namn"
        onChange={onFullNameChange}
        value={fullName}
        name="fullName"
      />
      {validationAlert && (
        <Alert type={AlertTypes.DANGER} text="Du måste fylla i alla fält" />
      )}
    </style.Label>
  </Modal>
);
