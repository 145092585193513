import styled from "styled-components";
export const Overlay = styled.div`
position: fixed;
top: 0px;
width: 100%;
height: 100%;
background-color: rgba(0,0,0,0.5);
z-index: 999;
`

type TWrapper = {
  minHeight?: string
  topPosition?: string
}
export const Wrapper = styled.div<TWrapper>`
position: fixed;
z-index: 1032;
background-color: #fff;
max-width: 800px;
width: 100%;
left: 50%;
top: ${props => props.topPosition ? `calc(${props.topPosition} - 20px) ` : '50%'};
${props => props.minHeight && `min-height:${props.minHeight}`};
max-height:70vh;
margin: 20px;
padding: 32px;
overflow:auto;
transform: ${props => props.topPosition ?'translate(-50%)' :'translate(-50%, -50%)'};
box-shadow: 0 2px 200px rgba(0,0,0,0.3);


@media screen and (max-width: 768px) {
  width: 100%;
  margin: 0;
  height: 100vh;
  top: ${props => props.topPosition ? props.topPosition : '50%'};
  max-width: none;
}
`

export const Header = styled.div`
display: flex;
justify-content: space-between;

button {
  all: unset;
  cursor: pointer;
  height: 29px;
  width: 29px;
  text-align: right;
}
`

export const Fields = styled.div`
margin-top: 32px;
margin-bottom: 32px;
`

export const Button = styled.div`
button {
  width: 100%;
}
`
