import { CartItemOptions, NewNumberSubscriptionOption, PhoneOption, KeepNumberSubscriptionOption } from "../types/Cart";
/**
 * Here we can define TS type guards for filtering and type checking purposes
 *
 * For more knowledge see TS docs here:
 * https://www.typescriptlang.org/docs/handbook/advanced-types.html#type-guards-and-differentiating-types
 *
 */
/**
* Checks if the give item is a non empty string
* @param {any} item The item to type check
* @returns {boolean} True if item is string and not empty, else false
*/
export const isNotEmptyString = (item: any): item is string =>
  isString(item) && item.length > 0;
/**
 * Checks if the given item is a string
 *
 * @param item Them item to type check
 * @returns {boolean} True if item is a string, else false
 */
export const isString = (item: any): item is string =>
  typeof item === 'string';
export const isNumber = (item: any): item is number =>
  typeof item === 'number';
export const isNewNumberSubscriptionOption = (
  option?: CartItemOptions
): option is NewNumberSubscriptionOption =>
  option
    ? 'numberOption' in option && option.numberOption === 'new_number'
    : false;
export const isKeepNumberSubscriptionOption = (
  option?: CartItemOptions
): option is KeepNumberSubscriptionOption =>
  option
    ? 'numberOption' in option && option.numberOption === 'keep_number'
    : false;
export const isPhoneOption = (
  option?: CartItemOptions
): option is PhoneOption =>
  option
    ? !('numberOption' in option) && 'fullName' in option
    : false;
export const isSubscriptionOption = (
  option?: CartItemOptions
): option is (NewNumberSubscriptionOption | KeepNumberSubscriptionOption) =>
  option ? 'numberOption' in option : false;
