import React, { useContext, useRef, useState } from "react";

import {
  ListView,
  IListViewCell,
  IListViewRow,
} from "../../../components/ListView/ListView";
import { UserContext } from "../../../providers/UserProvider";
import { AddUserModal } from "./AddUserModal";
import { User, UserRoles } from "../../../types/User";
import { LargeIcon, MediumIcon, SmallIcon } from "../../../components/Icons";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

export const Users = () => {
  const [showAddUser, setShowAddUser] = useState<boolean>(false);
  const [editUser, setEditUser] = useState<User | undefined>();
  const userContext = useContext(UserContext);
  const user = userContext?.user; //useSelector(selectUser);
  const users = user?.company?.users;
  const [coworkers, setCoworkers] = useState<User[]>(users ?? []);
  const modalRef = useRef<HTMLDivElement>();
  const header: IListViewCell[] = [
    {
      content: "Namn",
    },
    {
      content: "Email",
    },
  ];

  const onRemoveUser = async (id: number) => {
    try {
      const user = await userContext.removeUser?.(id);
      if (user) {
        setCoworkers((prev) => prev.filter((coworker) => coworker.id !== id));
      }
    } catch {}
  };

  const onEditUser = async (id: number) => {
    setEditUser(coworkers.find((coworker) => coworker.id == id));
    setShowAddUser(true);
  };
  const onAddUser = async (user: User) => {
    setCoworkers((prev) => prev.concat(user));
    setShowAddUser(false);
  };
  const onUpdateUser = (user: User) => {
    console.log("user ", user);
    setCoworkers((prev) =>
      prev.map((coworker) => {
        if (coworker.id === user.id) return user;
        return coworker;
      })
    );
    setShowAddUser(false);
  };
  const getRoles = (roles: string[]) => {
    console.log("roles ", roles);
    try {
      let rolenames = UserRoles.filter((r) => roles.indexOf(r.value) >= 0).map(
        (r) => r.title
      );
      console.log(rolenames);
      return rolenames.join(", ");
    } catch (e) {
      console.log(e);
    }
    return "";
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableCell>Namn</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Roll</TableCell>
            <TableCell></TableCell>
          </TableHead>
          <TableBody>
            {coworkers.map((u) => {
              return (
                <TableRow key={u.id}>
                  <TableCell>
                    {u.firstName} {u.lastName}
                  </TableCell>
                  <TableCell>{u.loginName}</TableCell>
                  <TableCell>{getRoles(u.roles)}</TableCell>
                  <TableCell>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <IconButton
                        size="medium"
                        onClick={() => onEditUser(u.id)}
                      >
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        size="medium"
                        onClick={() => onRemoveUser(u.id)}
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Button variant="contained" onClick={() => setShowAddUser(true)}>
        Lägg till
      </Button>
      {showAddUser && (
        <AddUserModal
          handleClose={() => setShowAddUser(false)}
          userAdded={(user: User) => onAddUser(user)}
          userUpdated={(user: User) => onUpdateUser(user)}
          modalRef={modalRef}
          user={editUser}
        ></AddUserModal>
      )}
    </>
  );
  /*(
    <div>
      <ListView header={header} content={[]} />
      <div
        style={{
          display: "grid",
          gap: "20px",
          gridTemplateColumns: "auto auto auto auto",
          padding: "10px 32px",
        }}
      >
        <>
          <div style={{ backgroundColor: "#0ff000" }}>Namn</div>
          <div>Email</div>
          <div>Roller</div>
          <div>Actions</div>
        </>
        {coworkers.map((u) => {
          return (
            <>
              <div>
                {u.firstName} {u.lastName}
              </div>
              <div>{u.loginName}</div>
              <div>{getRoles(u.roles)}</div>
              <div style={{ display: "flex", gap: "10px" }}>
                <SmallIcon>
                  <Edit onClick={() => onEditUser(u.id)} />
                </SmallIcon>
                <SmallIcon>
                  <Trash onClick={() => onRemoveUser(u.id)} />
                </SmallIcon>
              </div>
            </>
          );
        })}
      </div>
      <button onClick={() => setShowAddUser(true)}>Lägg till</button>
      {showAddUser && (
        <AddUserModal
          handleClose={() => setShowAddUser(false)}
          userAdded={(user: User) => onAddUser(user)}
          userUpdated={(user: User) => onUpdateUser(user)}
          modalRef={modalRef}
          user={editUser}
        ></AddUserModal>
      )}
    </div>
  );*/
};
