import styled from "styled-components";
import { Link } from "react-router-dom";
export const Subscription = styled.div`
  margin-top: 35px;
  background: #fff;
  padding: 32px;
  @media screen and (min-width: 767px) {
    padding: 60px;
  }
`
export const GoBack = styled(Link)`
  font-size: 22px;
  font-weight: 600;
  svg {
    margin-right: 10px;
  }
`

export const h3 = styled.h3`
  padding-bottom: 10px;
  font-size: 24px;
`
export const span = styled.span`
  font-size: 18px;
`
export const Columns = styled.div`
  border-top: 2px solid #DBDBDB;
  padding-top: 30px;
  margin-top: 30px;
  @media screen and (min-width: 768px) {
    display: flex;
  }
`
export const Column = styled.div`
  flex: 1;
`
export const Heading = styled.div`
  font-weight: 600;
  font-size: 18px;
`
export const Description = styled.div`
  padding: 5px 0 15px 0;
  font-size: 18px;
`
