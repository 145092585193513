import styled from "styled-components";
import { colors } from "../../assets/style/variables";
import Checkmark from '../../assets/icons/Checkmark-white.svg';

export const Wrapper = styled.div`
max-width: 100%;
width: 484px;
margin: 32px auto;
padding: 54px 52px 24px;
background-color: #fff;

@media screen and (max-width: 768px) {
  padding: 24px 22px 12px;
}

input {
  display: block;
  width: 100%;
  margin-bottom: 20px;
}
`

export const Alert = styled.div`
  margin-bottom: 20px;
`

export const Header = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
`

export const Label = styled.div`
padding: 8px 12px;
background-color: #000;
color: #fff;
font-size: 12px;
text-transform: uppercase;
font-weight: 600;
`

export const Fields = styled.div`
margin: 32px 0%;
`

export const FieldHeading = styled.h5`
  margin-top: 32px;
  margin-bottom: 20px;
  color: #000000;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
`;

export const Checkbox = styled.div`
  position: relative;

  label {
    display: flex;
    align-items: center;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    margin-left: 31px;
  }

  input {
    position: absolute;
    top: 50%;
    left: 0;
    width: 0;
    margin: 0;
    transform: translate(0, -50%);
    appearance: none;


    &:before {
      content: '';
      display: block;
      position: absolute;
      background-color: ${colors.accent};
      border-radius: 2px;
      width: 19px;
      height: 19px;
      margin-top: -9px;
    }

    &:checked:before {
      content: '';
      background: ${colors.accent} url(${Checkmark}) no-repeat;
      background-size: 60%;
      background-position: center;
    }
  }
`

export const Links = styled.ul`
  display: flex;
  justify-content: center;
  margin: 0 -16px;
  flex-wrap: wrap;

  li {
    margin: 0 0 16px;
    padding: 0 16px;

    a {
      color: ${colors.accent};
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
    }
  }
`;
