import React, { useContext, useState } from "react";

import { useNavigate } from "react-router-dom";

import { CheckoutContent } from "./Checkout.content";
import { UserContext } from "../../providers/UserProvider";
import { CartContext } from "../../providers/CartProvider";
import { AppContext } from "../../AppProvider";

export const Checkout = () => {
  const userContext = useContext(UserContext);
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const user = userContext?.user; //useSelector(selectUser);
  const cart = userContext?.cart; //useSelector(selectCart);
  const settings = appContext?.settings; //useSelector(selectSettings);
  const totals = cart?.totals; //useSelector(selectTotals);
  const [hidden, setHidden] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    installment: "invoice",
    invoicePreference: user?.company.invoiceType,
    costPlace: user?.company.costCenters,
  });
  const [selectedBillingAddressId, setSelectedBillingAddressId] = useState(
    user?.company.billing && user.company.billing.length > 0
      ? user.company.billing[0].id
      : null
  );
  const [selectedShippingAddressId, setSelectedShippingAddressId] = useState(
    user?.company.shipping && user.company.shipping.length > 0
      ? user.company.shipping[0].id
      : null
  );
  const monthlyTotal =
    cart?.items
      .filter((x: any) => x.cart_item_data?.subscription === true)
      .reduce((acc: any, curr: any) => acc + curr.totals.total, 0) ?? 0;
  const cartTotal = totals ? Number(totals.total) - monthlyTotal : 0;
  const handleChange = (event: { target: HTMLInputElement }) => {
    const value = event.target.value;
    setState({
      ...state,
      [event.target.name]: value,
    });
  };
  const onCreateOrder = () => {
    const options = {
      user,
      shippingAddress:
        user?.company.shipping && user.company.shipping.length > 0
          ? user.company.shipping.find(
              (adr) => adr.id === selectedShippingAddressId
            )
          : null,
      billingAddress:
        user?.company.billing && user.company.billing.length > 0
          ? user.company.billing.find(
              (adr) => adr.id === selectedBillingAddressId
            )
          : null,
      selected_options: {
        payment_option: state.installment,
        invoice_preference: state.invoicePreference,
        cost_place: state.costPlace,
      },
    };
    setLoading(true);
    userContext
      ?.createOrder?.(cart!!, options)
      .catch(() => setLoading(false))
      .then(() => navigate("/kundvagn/tack"));
  };
  return cart ? (
    <CheckoutContent
      user={user}
      cart={cart}
      onCreateOrder={onCreateOrder}
      settings={settings!!}
      setHidden={setHidden}
      hidden={hidden}
      costPlace={state.costPlace}
      installment={state.installment}
      invoicePreference={state.invoicePreference?.toString()}
      monthTotal={monthlyTotal}
      cartTotal={cartTotal}
      handleChange={handleChange}
      selectedBillingAddressId={selectedBillingAddressId}
      selectedShippingAddressId={selectedShippingAddressId}
      setSelectedBillingAddressId={setSelectedBillingAddressId}
      setSelectedShippingAddressId={setSelectedShippingAddressId}
      loading={loading}
    />
  ) : null;
};
