import { get as getLS } from 'local-storage';
import { checkOkAndJSON, JSONResponse } from './utils';
import { InvoiceTypes } from '../types/Company';
import { Subscription } from '../types/Subscription';
import { FrontPage, Page } from '../types/Page';

import { Order } from '../types/Order';
import {
  HeaderBullets,
  FooterIcons,
  FooterColumns,
  NavigationItem,
  PhoneSubscriptionAlternative,
  AddToCartCTAItems,
} from '../types/Settings';
import { ProductCategoryHeroColor } from '../types/Category';
interface IHeader {
  'Content-Type':string;
  'Authorization'?:string;
  'Accept'?:string;
}
export const defaultHeaders = () => {
  let header:IHeader = {'Content-Type': 'application/json',"Accept":"application/json"}
  
  const jwt = getLS('limitado_jwt') as string;
  if(jwt) {
    header.Authorization = 'Bearer '+jwt;
  }
  return header;
}

export const callGetCategories = () =>
  fetch('/wp-json/api/categories', {
    method: 'GET',
    headers: {
      ...defaultHeaders(),
    },
    credentials: 'same-origin'
  })
    .then(checkOkAndJSON);


export const callGetProducts = () =>
  fetch('/wp-json/api/products', {
    method: 'GET',
    headers: {
      ...defaultHeaders(),
    },
    credentials: 'same-origin'
  })
    .then(checkOkAndJSON);
export interface GetSettingsSuccessResponse {
  site_url: string,
  shippingCost: number,
  header_bullets: HeaderBullets,
  footer_icons: FooterIcons,
  footer_columns: FooterColumns,
  footer_text: string,
  product_slug: string,
  category_slug: string,
  products_hero_header: string,
  products_hero_text: string,
  products_hero_image: string,
  products_hero_text_color: ProductCategoryHeroColor,
  primary_navigation: NavigationItem[],
  phone_subscription_alternatives: PhoneSubscriptionAlternative[],
  checkout_success_message: string,
  add_to_cart_cta: AddToCartCTAItems,
  instalment_payment_description: string,
};
type GetSettingsResponse = JSONResponse<GetSettingsSuccessResponse>;

export const callGetSettings = (): Promise<any> =>
  fetch('/wp-json/api/settings', {
    method: 'GET',
    headers: {
      ...defaultHeaders(),
    },
    credentials: 'same-origin'
  })
    .then(checkOkAndJSON);

export interface CreateOrderSuccessResponse extends Order { };
type CreateOrderResponse = JSONResponse<CreateOrderSuccessResponse>;
/**
* Tries to create a new order
*
* @param {Object} cart Cart to dispatch
*/

export interface GetPagesBySlugSuccessResponse extends Page { };
type GetPagesBySlugResponse = JSONResponse<GetPagesBySlugSuccessResponse>;
export const callGetPageBySlug = (slug: string): Promise<Page> =>
  fetch(`/wp-json/api/page?slug=${slug}`, {
    method: 'GET',
    headers: {
      ...defaultHeaders(),
    },
    credentials: 'same-origin',
  })
    .then(checkOkAndJSON);
export const callSetBillingAddressDefaultIndex = (newDefaultIndex: string) =>
  fetch('/wp-json/api/company/billing', {
    method: 'PUT',
    headers: {
      ...defaultHeaders(),
    },
    credentials: 'same-origin',
    body: JSON.stringify({ defaultIndex: newDefaultIndex }),
  })
    .then(checkOkAndJSON);
export const callSetShippingAddressDefaultIndex = (newDefaultIndex: string) =>
  fetch('/wp-json/api/company/shipping', {
    method: 'PUT',
    headers: {
      ...defaultHeaders(),
    },
    credentials: 'same-origin',
    body: JSON.stringify({ defaultIndex: newDefaultIndex }),
  })
    .then(checkOkAndJSON);
export interface SetCostCenterSuccessResponse {
  data: string,
};
type SetCostCenterResponse = JSONResponse<SetCostCenterSuccessResponse>;
export const callSetCostCenter = (costCenter: string): Promise<SetCostCenterResponse> =>
  fetch('/wp-json/api/company/costcenter', {
    method: 'PUT',
    headers: {
      ...defaultHeaders(),
    },
    credentials: 'same-origin',
    body: JSON.stringify({ defaultValue: costCenter })
  })
    .then(checkOkAndJSON);
export interface SetInvoiceTypeSuccessResponse {
  data: InvoiceTypes,
};

type SetInvoiceTypeResponse = JSONResponse<SetInvoiceTypeSuccessResponse>;

export const callSetInvoiceType = (invoiceType: InvoiceTypes): Promise<SetInvoiceTypeResponse> =>
  fetch('/wp-json/api/company/invoiceType', {
    method: 'PUT',
    headers: {
      ...defaultHeaders(),
    },
    credentials: 'same-origin',
    body: JSON.stringify({ defaultValue: invoiceType })
  })
    .then(checkOkAndJSON);
export interface RequestAccountSuccessResponse {
  status: string,
};
type RequestAccountResponse = JSONResponse<RequestAccountSuccessResponse>;
export const callRequestAccount = (formData: any): Promise<RequestAccountResponse> =>
  fetch('/wp-json/api/user/requestaccount', {
    method: 'POST',
    headers: {
      ...defaultHeaders(),
    },
    body: JSON.stringify(formData),
  })
    .then(checkOkAndJSON);
interface RequestChangePasswordSuccessResponse {
  once: string,
  status: string,
};
type RequestChangePasswordResponse = JSONResponse<RequestChangePasswordSuccessResponse>;
export const callChangePassword = (currentPassword: string, newPassword: string): Promise<RequestChangePasswordResponse> =>
  fetch('/wp-json/api/user/changepassword', {
    method: 'POST',
    headers: {
      ...defaultHeaders(),
    },
    body: JSON.stringify({
      old_pwd: currentPassword,
      new_pwd: newPassword,
    }),
  })
    .then(checkOkAndJSON);
//interface RequestResetPasswordResponse { };
export const callResetPassword = (username: string): Promise<void> =>
  new Promise((resolve,reject) => {
    window.setTimeout(() => resolve(), 1000);
  });
// fetch('/wp-json/api/user/resetpassword', {
//   method: 'POST',
//   headers: {
//     ...defaultHeaders(),
//   },
//   body: JSON.stringify({
//     user: username,
//   }),
// })
//   .then(checkOkAndJSON);
/*
export interface GetUserSubscriptionsSuccessResponse {
  data: Subscription[],
};
type GetUserSubscriptionsResponse = JSONResponse<GetUserSubscriptionsSuccessResponse>;
export const callGetUserSubscriptions = (): Promise<any> =>
  fetch('/wp-json/api/subscriptions', {
    method: 'GET',
    headers: {
      ...defaultHeaders(),
    },
    credentials: 'same-origin',
  })
    .then(checkOkAndJSON);
*/
export interface GetFrontpageSuccessResponse extends FrontPage { };
export type GetFrontpageResponse = JSONResponse<GetFrontpageSuccessResponse>;
export const callGetFrontPage = (): Promise<GetFrontpageResponse> =>
  fetch('/wp-json/api/frontpage', {
    method: 'GET',
    headers: {
      ...defaultHeaders(),
    },
    credentials: 'same-origin',
  })
    .then(checkOkAndJSON);
