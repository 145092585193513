export interface IAddress {
  id: string,
  company: string,
  recipient?: string,
  address: string,
  post_code: string,
  city: string,
};
export interface IBillingAddress {
  id: string,
  billing_company: string,
  billing_recipient?: string,
  billing_address: string,
  billing_post_code: string,
  billing_city: string,
};
export interface IShippingAddress {
  id: string,
  shipping_company: string,
  shipping_recipient?: string,
  shipping_address: string,
  shipping_post_code: string,
  shipping_city: string,
};
export const remapBillingAddress = (address: IBillingAddress): IAddress => ({
  id: address.id,
  company: address.billing_company,
  recipient: address.billing_recipient,
  address: address.billing_address,
  post_code: address.billing_post_code,
  city: address.billing_city,
});
export const remapShippingAddress = (address: IShippingAddress): IAddress => ({
  id: address.id,
  company: address.shipping_company,
  recipient: address.shipping_recipient,
  address: address.shipping_address,
  post_code: address.shipping_post_code,
  city: address.shipping_city,
});
