import React from "react";
import * as style from "./Container.style";
interface IProps {
  children?: any;
  tight?: boolean;
  noPadding?: boolean;
}
export const Container = ({ children, tight, noPadding }: IProps) => (
  <style.Container tight={tight} noPadding={noPadding}>
    {children}
  </style.Container>
);
