import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { mainRoutes } from "./Router.config";

import { isString } from "./utils";
import { sanitizePath } from "./utils/sanitizers";
import { AppContext } from "./AppProvider";
interface RoutesProps {
  isLoggedIn: boolean;
}
export const MainRoutes: React.FC<RoutesProps> = ({ isLoggedIn }) => {
  const context = useContext(AppContext);
  const product_slug = context?.settings?.product_slug;
  const categorySlug = context?.settings?.category_slug;
  return (
    <Routes>
      {mainRoutes.map((route, i) => {
        const { isProduct, path, isCategory, component } = route;

        let newPath = path;
        if (isString(path) && product_slug && categorySlug) {
          if (isProduct) {
            newPath = sanitizePath(
              path.replace("PRODUCT_ROOT_SLUG", product_slug)
            );
          } else if (isCategory) {
            newPath = sanitizePath(
              path.replace("CATEGORY_ROOT_SLUG", categorySlug)
            );
          }
        }
        return <Route key={i} path={newPath} Component={component} />;
      })}
    </Routes>
  );
};
