import React, { EventHandler } from "react";
import { NavLink, Link } from "react-router-dom";
import { User } from "../../types/User";
import * as style from "./UserModal.style";
interface Props {
  wrapperRef: any;
  user?: User;
  onLogoutClick: EventHandler<React.MouseEvent<HTMLElement>>;
  onModalCloseClick: EventHandler<React.MouseEvent<HTMLElement>>;
}

export const UserModalContent: React.FC<Props> = ({
  wrapperRef,
  user,
  onLogoutClick,
  onModalCloseClick,
}) => {
  const LoggedInMenu = () => {
    return (
      <>
        <div>
          <style.Header>
            {user && (
              <>
                <strong>{user.company.companyName}</strong>
                <p>{`${user.firstName} ${user.lastName}`}</p>
              </>
            )}
            <style.Close onClick={onModalCloseClick} />
          </style.Header>
          <ul>
            <style.MobileLink>
              <NavLink to="/foretag/bestallning">
                <li>Mina sidor</li>
              </NavLink>
            </style.MobileLink>
            <NavLink to="/foretag">
              <li>Orderhistorik</li>
            </NavLink>
            <NavLink to="/foretag/dokument">
              <li>Dokument</li>
            </NavLink>
            <NavLink to="/foretag/abonnemang">
              <li>Abonnemang</li>
            </NavLink>
            <NavLink to="/foretag/installning">
              <li>Kontouppgifter</li>
            </NavLink>
            {user?.company?.users && (
              <NavLink to="/foretag/users">
                <li>Användare</li>
              </NavLink>
            )}
          </ul>
        </div>
        <Link to="/" onClick={onLogoutClick}>
          <style.Bottom>Logga ut</style.Bottom>
        </Link>
      </>
    );
  };

  const NotLoggedInMenu = () => {
    return (
      <ul>
        <NavLink to="/login">
          <li>Logga in</li>
        </NavLink>
      </ul>
    );
  };
  return (
    <style.Wrapper
      ref={(instance) => (wrapperRef.current = instance)}
      id="user-modal"
      role="dialog"
    >
      <style.InnerWrapper>
        {user?.company ? <LoggedInMenu /> : <NotLoggedInMenu />}
      </style.InnerWrapper>
    </style.Wrapper>
  );
};
