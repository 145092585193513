import React, { useContext, useEffect, useRef } from "react";
import { Wrapper } from "./RichText.style";

import { useNavigate } from "react-router-dom";
import { AppContext } from "../../AppProvider";
interface Props {
  markup: string;
}
export const RichText: React.FC<Props> = ({ markup }) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const context = useContext(AppContext);
  const siteHostname = context?.settings?.site_url; // (seslectSiteHostname);
  const navigate = useNavigate();
  // Takes care of internal links, catching any clicks on an anchor tag
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const link = target.closest("a");
      if (link) {
        if (
          // If link is on current domain
          (link.hostname === siteHostname ||
            link.hostname.includes("localhost")) &&
          // If link is on current domain but points to an uploaded file
          !link.hostname.includes("/wp-content/") &&
          // If link doesn't have a custom target set, (eg. open in new tab)
          link.target === ""
        ) {
          event.preventDefault();
          navigate(link.pathname);
        }
      }
    };
    if (wrapperRef.current) {
      wrapperRef.current.addEventListener("click", handleClick);
    }
    return () => {
      if (wrapperRef.current) {
        wrapperRef.current.removeEventListener("click", handleClick);
      }
    };
  }, [siteHostname, wrapperRef]);
  return (
    <Wrapper
      dangerouslySetInnerHTML={{ __html: markup }}
      ref={(instance) => (wrapperRef.current = instance)}
    />
  );
};
