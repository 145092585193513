import React from "react";
import { Block } from "../../types/Page";

import { Container, Wrapper } from "../../components";
import { WrapperCallback } from "../../components/Wrapper/Wrapper";

export interface IHeadingBlockProps extends Block {
  headline: string;
  headlineSize: "h1" | "h2" | "h3" | "h4";
  align: "left" | "center" | "right";
}
export const HeadingBlock: React.FC<IHeadingBlockProps> = ({
  headline,
  headlineSize,
  align,
}) => {
  let wrapperStyle: React.CSSProperties = {
    textAlign: align,
    margin: "1em 0",
    whiteSpace: "pre-wrap",
  };
  let wrapperFn: WrapperCallback;
  switch (headlineSize) {
    case "h2":
      wrapperFn = (children) => <h2 style={wrapperStyle}>{children}</h2>;
      break;
    case "h3":
      wrapperFn = (children) => <h3 style={wrapperStyle}>{children}</h3>;
      break;
    case "h4":
      wrapperFn = (children) => <h4 style={wrapperStyle}>{children}</h4>;
      break;
    default:
      wrapperFn = (children) => <h1 style={wrapperStyle}>{children}</h1>;
  }
  return (
    <Container noPadding>
      <Wrapper wrapperFn={wrapperFn}>{headline}</Wrapper>
    </Container>
  );
};
