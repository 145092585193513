import React from 'react';
import styled from "styled-components";
import { Link } from "react-router-dom";
import { colors } from "../../assets/style/variables";
import { IButtonLinkProps } from './ButtonLink';

const HANDLE_SIZE = (size:string) => {
  switch (size) {
    case "large":
      return "20px 52px";
    case "medium":
      return "15px 32px";
    case "small":
      return "12px 20px";
    default:
      return "15px 32px";
  }
};
export interface IButtonLinkStyleProps {
  rounded?: boolean;

  to: string;
  size: string;
}
export const ButtonLink = styled(Link)`
all: unset;
cursor: pointer;
text-decoration: none;
border: 1px solid;
border-color: ${colors.accent};
background-color: ${colors.accent};
transition: background-color 0.3s ease;
color: #fff;
text-align: center;
font-weight: 600;
display: inline-block;
box-sizing: border-box;
border-radius: ${({ rounded }: IButtonLinkStyleProps) => rounded ? "99px" : "0px"};
padding: ${({ size }: IButtonLinkStyleProps) => HANDLE_SIZE(size)};

:focus {
  background-color: ${colors.darkaccent};
  border-color: ${colors.darkaccent};
}

:hover {
  background-color: ${colors.darkaccent};
  border-color: ${colors.darkaccent};
}
`
