export interface TaxonomyTerm {
  count: number,
  description: string,
  filter: string,
  name: string,
  parent: number,
  slug: string,
  taxonomy: string
  term_group: number,
  term_id: number,
  term_taxonomy_id: number,
  icon?: string,
}
export enum ProductCategoryHeroColor {
  White = "White",
  Black = "Black",
};
export interface ProductCategory {
  id: number,
  name: string,
  slug: string,
  product_count: number,
  parent?: number,
  description?: string,
  heroImage?: string,
  bottomTextLeft?: string,
  bottomTextRight?: string,
  heroTextColor: ProductCategoryHeroColor,
  icon?: string,
};
export interface CategoryHero {
  header: string,
  text_color: ProductCategoryHeroColor,
  image: string,
  text: string,
};
