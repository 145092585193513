import styled from "styled-components"
interface CardProps {
  noHeading: boolean
}
export const Card = styled.div<CardProps>`
  border-top: ${(props) => props.noHeading ? '0' : '1px solid #DBDBDB'};
  padding: ${(props) => props.noHeading ? '0' : '30px 0'};}
  h4 {
    color: #000;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 20px;
  }
  ul {
    margin-bottom: 20px;
  }
  li {
    color: #000;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
`;
export const ChangeAddressButton = styled.div`
  margin-top: 20px;
  color: #BE8567;
  cursor: pointer;
`
