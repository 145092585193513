import { Shipping } from "../../../types/Order";

interface IShippingDetailsProps {
  shipping: Shipping;
}
export const ShippingDetails = ({ shipping }: IShippingDetailsProps) => {
  return (
    <ul>
      <li>{shipping.company}</li>
      <li>
        {shipping.first_name} {shipping.last_name}
      </li>
      <li>{shipping.address_1}</li>
      <li>{shipping.address_2}</li>
      <li>
        {shipping.postcode}, {shipping.city}
      </li>
      <li>{shipping.email}</li>
      <li>{shipping.phone}</li>
    </ul>
  );
};
