import styled from 'styled-components';

export const Wrapper = styled.div`
  h1,h2,h3,h4,h5,h6 {
    margin-bottom: 1em;
  }

  p {
    margin-bottom: 1em;
  }

  ul {
    list-style: disc;
    margin-left: 1em;
    margin-bottom: 1em;
  }

  a {
    text-decoration: underline;
  }

  >*:last-child {
    margin-bottom: 0;
  }
`;
