import React from "react";
import * as style from "./Documents.style";
import { format } from "date-fns";
import { DocumentModel } from "../../../types/Document";
import {
  Document as DocumentIcon,
  Arrow as ArrowIcon,
} from "../../../assets/icons";
import { formatBytes } from "../../../utils/formatBytes";
import { Heading } from "../../../components";

interface IProps {
  documents: DocumentModel[];
}
export const DocumentsContent: React.FC<IProps> = ({ documents }) => {
  console.log("documents ", documents);
  return (
    <React.Fragment>
      <Heading size={3} title="Avtal och fullmakter" />
      {documents?.length > 0 ? (
        <style.Documents>
          {documents.map(({ document }, index) => (
            <style.DocumentCard
              key={`${index}${document.id}`}
              href={document.url}
              download
            >
              <style.LeftWrap>
                <DocumentIcon />
                <style.Meta>
                  <style.h3>{document.filename}</style.h3>
                  <style.span>
                    {document.date &&
                      format(new Date(document.date), "dd MMMM yyyy")}
                    ,{" "}
                    <style.FileSize>
                      {formatBytes(document.filesize)}
                    </style.FileSize>
                  </style.span>
                </style.Meta>
              </style.LeftWrap>
              <style.RotatedElement>
                <ArrowIcon />
              </style.RotatedElement>
            </style.DocumentCard>
          ))}
        </style.Documents>
      ) : (
        "Inga dokument"
      )}
    </React.Fragment>
  );
};
