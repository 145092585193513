import React, { useContext, useEffect } from "react";

import { Spinner } from "../../components";
import { MainRoutes } from "../../Routes";

import { SpinnerWrap } from "../Page/Page.style";
import { Container, Page } from "..";
import { UserContext } from "../../providers/UserProvider";
import { AppContext } from "../../AppProvider";

export const LoginWrap = () => {
  const isLoggedIn = !!useContext(UserContext).user;
  const isLoading = useContext(AppContext)?.isLoading ?? false;

  useEffect(() => {
    console.log("Fix this");
    //dispatch(checkIfAuthenticated());
  }, []);

  if (isLoading) {
    return (
      <Page paddedTop>
        <Container valign="center">
          <SpinnerWrap>
            <Spinner />
          </SpinnerWrap>
        </Container>
      </Page>
    );
  }

  return <MainRoutes isLoggedIn={isLoggedIn} />;
};
