import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { colors } from "../../assets/style/variables";

export const Nav = styled.nav`
display: flex;
justify-content: center;
margin-bottom: 32px;
margin-top: 32px;
`

export const NavItem = styled(NavLink)`
display: flex;
margin-right: 20px;

&.active span {
  background-color: #000;
  color: #fff;
}

&.disabled {
  span, strong {
    color: #ccc;
  }
}

span {
  font-weight: 600;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-right: 8px;
  background-color: ${colors.lightgray};
  color: #000;
  width: 29px;
  height: 29px;
  border-radius: 29px;
}
`
