import React, { useContext } from "react";
import { Link, LinkProps } from "react-router-dom";
import { isString } from "../../utils";
import { AppContext } from "../../AppProvider";
interface Props extends LinkProps {}
export const WPLink: React.FC<Props> = ({ to, children, ...rest }) => {
  const context = useContext(AppContext);

  const siteUrl = context?.settings?.site_url ?? ""; //useSelector(selectSiteUrl);
  if (isString(to)) {
    if ((to.length > 0 && to[0] === "/") || (siteUrl && to.includes(siteUrl))) {
      return (
        <Link to={to.replace(siteUrl, "")} {...rest}>
          {children}
        </Link>
      );
    }
    return (
      <a href={to} {...rest}>
        {children}
      </a>
    );
  }
  return (
    <Link to={to} {...rest}>
      {children}
    </Link>
  );
};
export default WPLink;
