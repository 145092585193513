import styled from 'styled-components'

import { colors } from "../../assets/style/variables";


type TInput ={
    ref?: any
}

export const Input = styled.input<TInput>`
all: unset;
cursor: auto;
width: 100%;
padding: 12px 10px;
padding-right: 32px;
font-size: 16px;
border: 1px solid;
border-color: ${colors.darkgray};
border-radius: 2px;
color: #000;
background-color: #fff;
font-family: inherit;
box-sizing: border-box;

::placeholder {
  color: #979797;
}

:focus {
  outline: none;
  border-color: ${colors.accent};
}
`

export const InputContainer = styled.div`
position: relative;
width: 100%;

max-width: calc(100% - 52px);
 

`

export const IconHolder = styled.div`
      position: absolute;
    right: 8px;
    top: 50%;
    translate: 0% -50%;
    display:grid;
    place-content: center;
    cursor:pointer;
`