import styled from "styled-components";

export const Wrapper = styled.div`
display: flex;
justify-content: space-between;
flex-wrap: wrap;
margin: 0 -20px;
padding-bottom: 64px;

@media screen and (max-width: 768px) {
  padding-bottom: 32px;
}
`

export const Header = styled.div`
margin-bottom: 20px;
`
