import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Expand } from "../../assets/icons";
import {
  formatCost,
  remapBillingAddress,
  remapShippingAddress,
} from "../../utils";
import { CartNav, Container, Page } from "../../modules";
import {
  CartItem,
  CartEnd,
  Heading,
  Input,
  Select,
  RadioGroup,
  AddressCard,
  WrapText,
} from "../../components";

import * as style from "./Checkout.style";

import AnimateHeight from "react-animate-height";

import { ICart } from "../../types/Cart";

import { User } from "../../types/User";
import { ButtonSizes } from "../../types/Enums";
import { ISettingsState } from "../../types/Settings";
import { AppContext } from "../../AppProvider";
import { LoadingButton } from "@mui/lab";

interface ICheckoutProps {
  settings: ISettingsState;
  cart: ICart;
  user?: User;
  onCreateOrder: () => void;
  setHidden: any;
  hidden: boolean;
  costPlace?: string;
  installment: string;
  invoicePreference?: string;
  monthTotal: number;
  cartTotal: number;
  handleChange: (event: { target: HTMLInputElement }) => void;
  selectedBillingAddressId: string | null;
  selectedShippingAddressId: string | null;
  setSelectedBillingAddressId: any;
  setSelectedShippingAddressId: any;
  loading: boolean;
}
export const CheckoutContent = ({
  settings,
  cart,
  user,
  onCreateOrder,
  setHidden,
  hidden,
  costPlace,
  installment,
  invoicePreference,
  monthTotal,
  cartTotal,
  handleChange,
  selectedBillingAddressId,
  selectedShippingAddressId,
  setSelectedBillingAddressId,
  setSelectedShippingAddressId,
  loading,
}: ICheckoutProps) => {
  useEffect(() => {
    console.log("Checkout");
    document.body.classList.add("is-bg");

    return () => {
      document.body.classList.remove("is-bg");
    };
  }, []);

  const cartIsEmpty = cart?.item_count <= 0;
  const context = useContext(AppContext);
  const paymentByInstalmentText =
    context?.settings?.instalment_payment_description ?? "";

  return user && cart ? (
    <Page paddedTop>
      <Container>
        <CartNav cartEmpty={cart?.item_count === 0} />

        <style.Wrapper>
          <style.LargeColumn>
            <style.Card>
              <style.Flex>
                <style.FlexItem>
                  <style.Heading>
                    <Heading size={4} title="Leveransadress" />
                  </style.Heading>
                  {user.company.shipping && user.company.shipping.length > 0 ? (
                    <AddressCard
                      addresses={user.company.shipping.map((adr) =>
                        remapShippingAddress(adr)
                      )}
                      onSave={setSelectedShippingAddressId}
                      modalSubmitLabel="Välj"
                      selectedId={selectedShippingAddressId!}
                    />
                  ) : (
                    <strong>Adress saknas</strong>
                  )}
                </style.FlexItem>

                <style.FlexItem>
                  <style.Heading>
                    <Heading size={4} title="Fakturaadress" />
                  </style.Heading>
                  {user.company.billing && user.company.billing.length > 0 ? (
                    <AddressCard
                      addresses={user.company.billing.map((adr) =>
                        remapBillingAddress(adr)
                      )}
                      onSave={setSelectedBillingAddressId}
                      modalSubmitLabel="Välj"
                      selectedId={selectedBillingAddressId!}
                    />
                  ) : (
                    <strong>Adress saknas</strong>
                  )}
                </style.FlexItem>
              </style.Flex>
            </style.Card>

            <style.Card>
              <style.Heading>
                <Heading size={4} title="Välj betalsätt" />
              </style.Heading>

              <RadioGroup
                options={[
                  {
                    name: "installment",
                    value: "invoice",
                    label: "Faktura",
                    checked: installment === "invoice",
                    onChange: handleChange,
                    size: "large",
                  },
                  {
                    name: "installment",
                    value: "payment",
                    label: "Delbetalning",
                    checked: installment === "payment",
                    onChange: handleChange,
                    size: "large",
                  },
                ]}
              />

              {installment === "invoice" && (
                <style.SelectWrapper>
                  <style.Select>
                    <label>
                      <strong>Skicka faktura som</strong>
                      <Select
                        size="large"
                        onChange={handleChange}
                        value={invoicePreference!}
                        name="invoicePreference"
                        options={[
                          {
                            label: "E-post",
                            value: "email",
                          },
                          {
                            label: "Post",
                            value: "mail",
                          },
                        ]}
                      />
                    </label>
                  </style.Select>
                </style.SelectWrapper>
              )}

              {installment === "invoice" ? (
                <style.FlexBetween>
                  {invoicePreference! === "email" && (
                    <style.FlexItem>
                      <strong>Skicka fakturor till e-post</strong>
                      <p>{user.company.billingEmail}</p>
                    </style.FlexItem>
                  )}

                  <style.FlexItem>
                    <style.Label>
                      <strong>Kostnadställe</strong>
                      <Input
                        name="costPlace"
                        value={costPlace!}
                        onChange={handleChange}
                        type="input"
                        size="large"
                      />
                    </style.Label>
                  </style.FlexItem>
                </style.FlexBetween>
              ) : (
                <p>
                  <WrapText text={paymentByInstalmentText} />
                </p>
              )}
            </style.Card>

            <style.Submit>
              <Link to="/">
                <strong>Tillbaka</strong>
              </Link>
              <LoadingButton
                onClick={onCreateOrder}
                loading={loading}
                disabled={Object.values(cart).length <= 0}
              >
                Slutför beställning
              </LoadingButton>
            </style.Submit>
          </style.LargeColumn>

          <style.SmallColumn>
            <style.CartHeader>
              <div>
                <Heading size={4} title="Summering av order" />
                {cartIsEmpty ? (
                  "Din varukorg är tom"
                ) : !hidden ? (
                  <p>Visa hela varukorgen ({cart.item_count})</p>
                ) : (
                  <p>Visa mindre</p>
                )}
              </div>
              {!cartIsEmpty && (
                <style.Toggle
                  active={hidden}
                  onClick={() => setHidden(!hidden)}
                >
                  <Expand />
                </style.Toggle>
              )}
            </style.CartHeader>

            <AnimateHeight duration={500} height={hidden ? "auto" : 0}>
              {cart.items.map((item, i) => {
                return (
                  <style.CartItem key={item.item_key + `${i}`}>
                    <CartItem
                      price={"" + item.totals.total}
                      subscription={item.cart_item_data?.subscription ?? false}
                      selectedOptions={item.cart_item_data?.selected_options}
                      title={item.title}
                      productId={item.id}
                      variationId={item.id}
                    />
                  </style.CartItem>
                );
              })}
            </AnimateHeight>

            <CartEnd
              monthlyCost={monthTotal}
              shippingCost={"" + settings.shippingCost ?? "0"}
              small={true}
              cartTotal={formatCost(cartTotal)}
            />
          </style.SmallColumn>
        </style.Wrapper>
      </Container>
    </Page>
  ) : null;
};
