import React, {
  EventHandler,
  ReactEventHandler,
  useContext,
  useState,
} from "react";
import { Heading } from "..";
import { Input } from "../../components";
import * as style from "./ProductCard.style";

import {
  ProductAttribute,
  ProductVariation,
  ProductAttributeTypes,
  PhoneVariationAttributes,
  ProductVariationAttributes,
  SubscriptionVariationAttributes,
  UnkownVariationAttributes,
} from "../../types/Product";

import { formatCost } from "../../utils";
import { DefaultVariants } from "./Variants/DefaultVariants/DefaultVariants";
import { ColorScheme } from "./Variants/ColorScheme/ColorScheme";
import { UserContext } from "../../providers/UserProvider";
import { Button } from "@mui/material";
interface ProductCardProps {
  image: string;
  title: string;
  price: number;
  description: string;
  attributes: Array<ProductAttribute>;
  onVariantSelect: (variant: { attribute: string; value: string }) => void;
  activeVariant?: ProductVariation;
  setQuantity: React.Dispatch<React.SetStateAction<number>>;
  onClick: EventHandler<React.MouseEvent>;
  onToDetailsClick?: ReactEventHandler<HTMLAnchorElement>;
}
export const ProductCard: React.FC<ProductCardProps> = ({
  image,
  title,
  price,
  description,
  attributes,
  onVariantSelect,
  activeVariant,
  setQuantity,
  onClick,
  onToDetailsClick,
}) => {
  const [localQuantity, setLocalQuantity] = useState<number | null>(1);
  const user = useContext(UserContext)?.user;
  const onQuantityChange = (e: any) => {
    const temp = Number(e.target.value);
    if (e.target.value !== "" && temp > 0) {
      setQuantity(temp);
      setLocalQuantity(temp);
    } else {
      setLocalQuantity(null);
    }
  };
  return (
    <style.Product>
      <style.ImageOuter>
        <style.ImageInner>
          <img src={image} alt={title} />
        </style.ImageInner>
      </style.ImageOuter>
      <style.Content>
        {user && user.company && <small>{formatCost(price) + " kr"}</small>}
        <Heading title={title} />
        <style.Description>
          <div dangerouslySetInnerHTML={{ __html: description }} />
          {onToDetailsClick && (
            <a
              className="prod-details"
              href="#product-details"
              onClick={onToDetailsClick}
            >
              <strong>Produktdetaljer</strong>
            </a>
          )}
        </style.Description>
        {activeVariant &&
          attributes.map((attribute, index) => (
            <VariationHandler
              key={index}
              attribute={attribute}
              onVariantSelect={onVariantSelect}
              activeVariant={activeVariant}
            />
          ))}
        {user && user.company && (
          <style.Bottom>
            <small>Pris exkl. moms</small>
            <Heading
              size={3}
              title={formatCost(price * (localQuantity ?? 0)) + " kr"}
            />
            <Input
              size="small"
              type="number"
              placeholder=""
              name="quantity"
              min={1}
              value={localQuantity !== null ? localQuantity.toString() : ""}
              onChange={(e: any) => onQuantityChange(e)}
              required={true}
            />
            <Button
              variant="contained"
              disabled={localQuantity === null || localQuantity < 1}
              onClick={(e: any) => {
                setQuantity(localQuantity ? localQuantity : 1);
                onClick(e);
              }}
            >
              Lägg i varukorg
            </Button>
          </style.Bottom>
        )}
      </style.Content>
    </style.Product>
  );
};
interface VariationHandlerProps {
  attribute: ProductAttribute;
  onVariantSelect: (variant: { attribute: string; value: string }) => void;
  activeVariant: ProductVariation;
}
const VariationHandler: React.FC<VariationHandlerProps> = ({
  attribute,
  onVariantSelect,
  activeVariant,
}) => {
  const { name, values } = attribute;
  let attributes: ProductVariationAttributes;
  switch (name) {
    case ProductAttributeTypes.Memory:
      attributes = activeVariant.attributes as PhoneVariationAttributes;
      return (
        <DefaultVariants
          values={values}
          activeVariant={attributes.attribute_memory}
          onVariantSelect={(value) =>
            onVariantSelect({ attribute: name, value })
          }
          placeholder={"Lagringsutrymme"}
        />
      );
    case ProductAttributeTypes.Color:
      attributes = activeVariant.attributes as PhoneVariationAttributes;
      return (
        <ColorScheme
          values={values}
          activeVariant={attributes.attribute_color}
          onVariantSelect={(value: string) =>
            onVariantSelect({ attribute: name, value })
          }
        />
      );
    case ProductAttributeTypes.Months:
      attributes = activeVariant.attributes as SubscriptionVariationAttributes;
      return (
        <DefaultVariants
          values={values}
          activeVariant={attributes.attribute_months}
          onVariantSelect={(value) =>
            onVariantSelect({ attribute: name, value })
          }
          placeholder={"Bindningstid"}
        />
      );
    default:
      attributes = activeVariant.attributes as UnkownVariationAttributes;
      return (
        <DefaultVariants
          values={values}
          activeVariant={attributes[`attribute_${name}`]}
          onVariantSelect={(value) =>
            onVariantSelect({ attribute: name, value })
          }
          placeholder={"Attribut"}
        />
      );
  }
};
