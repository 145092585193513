import React, { useContext } from "react";

import { AppContext } from "../../AppProvider";
import { UspBlockContent } from "./UspBlock.content";
interface Props {}
export const UspBlock: React.FC<Props> = () => {
  const items = useContext(AppContext)?.settings?.footer_icons; //useSelector(selectFooterIcons);
  return (
    <UspBlockContent
      items={
        items
          ? items.map((item: any) => ({
              id: item.id,
              title: item.text,
              icon: item.icon,
            }))
          : []
      }
    />
  );
};
