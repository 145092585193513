import React from "react";
import * as style from "./UspBlock.style";
interface Props {
  items?: {
    id: string;
    title: string;
    icon: string;
  }[];
}
export const UspBlockContent: React.FC<Props> = ({ items }) => (
  <style.Wrapper>
    {items?.map((item) => (
      <style.Item key={item.id}>
        <img src={item.icon} alt="" />
        {item.title}
      </style.Item>
    ))}
  </style.Wrapper>
);
