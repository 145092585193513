import React from "react";

import { RichText, Heading } from "../../components";

import * as style from "./TwoColBlock.style";
interface ITwoColBlockProps {
  subtitle?: string;
  title?: string;
  description?: string;
  image?: string;
}
export const TwoColBlock = ({
  title,
  subtitle,
  description,
  image,
}: ITwoColBlockProps) => {
  return (
    <style.Wrapper>
      <style.Content>
        {subtitle && <p className="subtitle">{subtitle}</p>}
        {title && (
          <style.Title>
            <Heading size={2} title={title} />
          </style.Title>
        )}

        {description && <RichText markup={description} />}
      </style.Content>

      {image && (
        <style.Image>
          <img src={image} alt={title} />
        </style.Image>
      )}
    </style.Wrapper>
  );
};
