import React from "react";
import * as style from "./DefaultVariants.style";
interface IProps {
  onVariantSelect: (value: string) => void;
  values: string[];
  activeVariant: any;
  placeholder: string;
}
export const DefaultVariants: React.FC<IProps> = ({
  onVariantSelect,
  values,
  activeVariant,
  placeholder,
}) => {
  const name = activeVariant && activeVariant.split("#")[0];
  return (
    <style.DefaultVariants
    // onClick={() =>
    //   onVariantSelect({ attribute: name, value: x})
    // }
    >
      {placeholder}: <style.BoldText>{name}</style.BoldText>
      <style.Wrap>
        {values.map((val, index) => (
          <TextBubble
            key={index}
            value={val}
            onVariantSelect={onVariantSelect}
            active={activeVariant === val}
          />
        ))}
      </style.Wrap>
      {/* {selected ? x} {activeVariant.attributes[`attribute_${name}`] === x ? 'X' : ''}  */}
    </style.DefaultVariants>
  );
};
interface ColorIProps {
  onVariantSelect: Function;
  value: string;
  active: boolean;
}
const TextBubble: React.FC<ColorIProps> = ({
  onVariantSelect,
  value,
  active,
}) => {
  // const split = value.split('#');
  // const hex = split[1];
  return (
    <style.TextWrap onClick={() => onVariantSelect(value)} active={active}>
      {value}
    </style.TextWrap>
  );
};
