import styled from 'styled-components';
export const TextHiddenLarge = styled.span`
  @media screen and (min-width: 768px) {
    display: none;
  }
`;
export const TextHiddenSmall = styled.span`
  @media screen and (max-width: 767px) {
    display: none;
  }
`;
export const TextHiddenXSmall = styled.span`
  @media screen and (max-width: 567px) {
    display: none;
  }
`;
