import styled from "styled-components";
import { colors } from "../../assets/style/variables"

interface IWrapperProps {
  small:boolean
}
export const Wrapper = styled.div<IWrapperProps>`
background-color: ${colors.lightgray};
padding: ${props => props.small ? "32px" : "52px"};
`

export const Content = styled.div`
display: flex;
justify-content: space-between;
flex-wrap: wrap;
margin-bottom: 30px;
`

export const Break = styled.div`
border-bottom: 1px solid;
border-bottom-color: ${colors.darkgray};
margin-bottom: 30px;
`

export const Vat = styled.div`
  text-align: right;
`;
