import React, { useEffect } from "react";
import {
  Heading,
  AddressCard,
  ChangePassword,
  InfoCard,
  Input,
  Select,
} from "../../../components";
import {
  remapBillingAddress,
  remapShippingAddress,
  setEventValue,
} from "../../../utils";

import * as style from "./Settings.style";
import { ISettingsPageProps } from "./Settings";
import { InvoiceTypes } from "../../../types/Company";
import { LoadingButton } from "@mui/lab";

export const SettingsContent = ({
  user,
  costPlace,
  setCostPlace,
  onCostAndInvoiceSave,
  costAndInvoiceSaveLoading,
  invoiceType,
  setInvoiceType,
}: ISettingsPageProps) => {
  useEffect(() => {
    document.body.classList.add("is-bg");

    return () => {
      document.body.classList.remove("is-bg");
    };
  }, []);

  const setDefaultBillingAddress = async (id: string) => {
    console.log("Fix this");

    /*return dispatch(setDefaultBilling(id)).catch((err: any) => {
      // TODO: Handle err
    });*/
  };

  const setDefaultShippingAddress = async (id: string) => {
    console.log("Fix this");
    /*
    return dispatch(setDefaultShipping(id)).catch((err) => {
      // TODO: handle err
    });
    */
  };

  return (
    <React.Fragment>
      <style.Header>
        <Heading size={3} title="Kontouppgifter" />
      </style.Header>

      <style.Flex>
        <style.Column>
          {user?.company && (
            <style.Card>
              <InfoCard
                heading="Företagsuppgifter"
                items={[
                  ["Företagsnamn", user.company.companyName],
                  ["Organisationsnr.", user.company.companyId],
                ]}
              />

              {user.company.shipping && user.company.shipping.length > 0 ? (
                <AddressCard
                  heading="Leveransadress"
                  addresses={user.company.shipping.map((adr, index) =>
                    remapShippingAddress(adr)
                  )}
                  selectedId={user.company.shipping[0].id}
                  onSaveAsync={setDefaultShippingAddress}
                  modalSubmitLabel="Spara"
                />
              ) : (
                <strong>Adress saknas</strong>
              )}

              {user.company.billing && user.company.billing.length > 0 ? (
                <AddressCard
                  heading="Fakturaadress"
                  addresses={user.company.billing.map((adr) =>
                    remapBillingAddress(adr)
                  )}
                  selectedId={user.company.billing[0].id}
                  onSaveAsync={setDefaultBillingAddress}
                  modalSubmitLabel="Spara"
                />
              ) : (
                <strong>Adress saknas</strong>
              )}

              <style.Select>
                <label>
                  <strong>Skicka faktura som</strong>
                  <Select
                    size="large"
                    onChange={setEventValue(setInvoiceType)}
                    value={invoiceType.toString()}
                    name="invoiceType"
                    options={[
                      {
                        label: "E-post",
                        value: "email",
                      },
                      {
                        label: "Post",
                        value: "mail",
                      },
                    ]}
                  />
                </label>
              </style.Select>

              {invoiceType === InvoiceTypes.email &&
                (user.company.billingEmail ? (
                  <InfoCard
                    items={[
                      ["Fakturor skickas till", user.company.billingEmail],
                    ]}
                  />
                ) : (
                  "Faktura e-post saknas"
                ))}

              <style.CostCenters>
                <label>
                  Kostnadsställe
                  <Input
                    type="text"
                    size="small"
                    name="costPlace"
                    value={costPlace}
                    onChange={setEventValue(setCostPlace)}
                  />
                </label>
              </style.CostCenters>

              <LoadingButton
                onClick={onCostAndInvoiceSave}
                loading={costAndInvoiceSaveLoading}
              >
                Spara
              </LoadingButton>
            </style.Card>
          )}
        </style.Column>

        <style.Column>
          <style.Card>
            <InfoCard
              heading="Kontaktperson"
              items={[
                ["Namn", user!!.company.contactName],
                ["E-postadress", user!!.company.contactEmail],
                ["Telefonnummer", user!!.company.contactPhone],
              ]}
            />

            <InfoCard
              heading="Användaruppgifter"
              items={[["Användarnamn", user!!.loginName]]}
            />

            <ChangePassword />
          </style.Card>
        </style.Column>
      </style.Flex>
    </React.Fragment>
  );
};
