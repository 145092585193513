import styled from "styled-components";
import { colors } from "../../assets/style/variables";

export const Card = styled.div`
text-align: center;
background-color: #fff;
padding: 53px 130px;
width: 640px;
margin: 0 auto;
`

export const Divider = styled.div`
border-bottom: 1px solid;
border-bottom-color: ${colors.lightgray};
padding-bottom: 32px;
margin-bottom: 32px;
`

export const Section = styled.div`
margin-bottom: 32px;
`

export const Button = styled.div`
padding-top: 32px;

a {
  width: 100%;
}
`
