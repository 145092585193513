import styled from "styled-components";

export const Footer = styled.div`
  width: 100%;
  background-color: #000;
  color: #fff;
`

export const Container = styled.div`
  max-width: 1200px;
  margin: auto;
  width: 100%;
  padding: 64px 32px;
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: top;
  flex-wrap: wrap;

  li {
    a {
      font-weight: 600;
    }
  }

  svg {
    padding: 25px 0;
  }
`;

export const FooterColumn = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  padding: 10px 0;
  text-align: center;

  @media screen and (min-width: 568px) {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 10px 0;
    text-align: left;
  }

  @media screen and (min-width: 992px) {
    padding: 0;
    flex: 0 0 20%;
    max-width: 20%;
  }
`;

export const FooterText = styled.div`
  font-size: 14px;
  font-weight: 400;
`;
