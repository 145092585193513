import React, { useEffect } from "react";

import { PageWrap, SpinnerWrap } from "./Page.style";
//import { setPaddedTop } from "../../actions";
import { Spinner } from "../../components";

interface PageProps {
  paddedTop?: boolean;
  loading?: boolean;
}
export const Page: React.FC<PageProps> = ({ paddedTop, loading, children }) => {
  useEffect(() => {
    if (paddedTop) {
      console.log("Fix this");
      //dispatch(setPaddedTop(true));
    }
    return () => {
      console.log("Fix this");
      //dispatch(setPaddedTop(false));
    };
  }, [paddedTop]);
  return (
    <PageWrap paddedTop={paddedTop} $loading={loading} id="page-wrap">
      {children}

      {loading && (
        <SpinnerWrap>
          <Spinner />
        </SpinnerWrap>
      )}
    </PageWrap>
  );
};
