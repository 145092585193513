import React, { useContext, useEffect, useState } from "react";

import { Order } from "../../../types/Order";
import { OrdersContent } from "./Orders.content";
import { AppContext } from "../../../AppProvider";
import { UserContext } from "../../../providers/UserProvider";

export const Orders = () => {
  const context = useContext(UserContext);
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(false);
  /*
  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const orders = await callGetOrders();
      setOrders((await callGetOrders()) ?? []);
      setLoading(false);
    };
    init();
  }, []);
*/
  return <OrdersContent loading={loading} orders={context?.orders ?? []} />;
};
