import axios from "axios";
import { defaultHeaders } from ".";
import { User } from "../types/User";
import { checkOkAndJSON } from "./utils";

const baseUrl = '/wp-json/api/user';
interface LoginResponse {
    token: string,
    user_email: string
    user_nicename: string
    user_display_name: string
  }

export class UserApi {
    public static async get() {
        const response = await axios.get<User>(`${baseUrl}`,{headers:defaultHeaders() as any});
        return response.data;
    }
    public static async add(firstname:string,lastname:string,email:string,password:string,role:string) {
        let data = {
            firstname:firstname,
            lastname:lastname,
            email:email,
            password:password,
            role:role
        }
        const response = await axios.post<User>(`${baseUrl}/create`,data,{headers:defaultHeaders() as any});
        return response.data;
    }
    public static async update(id:number,firstname:string,lastname:string,email:string,role:string) {
        console.log('update user ',firstname,lastname,email,role);
        let data = {
            id:id,
            firstname:firstname,
            lastname:lastname,
            email:email,
            role:role
        }
        const response = await axios.post<User>(`${baseUrl}/update`,data,{headers:defaultHeaders() as any})
        return response.data;
    }

    // Returns the deleted user
    public static async remove(userId:number):Promise<User> {
        const response = await axios.delete<User>(`${baseUrl}/remove?id=${userId}`,{headers:defaultHeaders() as any});
        return response.data
    }

    public static async login(username: string, password: string, remember: boolean):Promise<LoginResponse> {
        if (!username || !password) {
            Promise.reject('Missing user or password');
        }
        const response = await axios.post<any>('/wp-json/jwt-auth/v1/token/',{ username, password },{headers:defaultHeaders() as any});
        console.log(response);
        return response.data
    }
    public static async logout() {
        const response = await axios.post('/wp-json/api/logout',{},{headers:defaultHeaders() as any})
        console.log(response);
        return response.data;
    }

}
