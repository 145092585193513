import React, { useState, ChangeEvent, useEffect, useContext } from "react";

import { useParams, useNavigate, useLocation } from "react-router-dom";

import {
  findProductsByCategory,
  getChildCategories,
  makeCategorySlug,
} from "../../utils";

import { Product } from "../../types/Product";
import { ProductCategory } from "../../types/Category";
import { CategoryContent } from "./Category.content";
import { AppContext } from "../../AppProvider";
interface CategoryContainerProps {}
export const Category: React.FC<CategoryContainerProps> = () => {
  const context = useContext(AppContext);
  const params = useParams<{
    categorySlug: string | undefined;
    filterCategorySlug: string | undefined;
  }>();
  const location = useLocation();
  const navigate = useNavigate();
  const categoryBaseSlug = context?.settings?.category_slug ?? ""; //useSelector(selectCategorySlug);
  const products = context?.products ?? []; //useSelector(selectProducts);
  const categories = context?.categories ?? []; //useSelector(selectCategories);
  const productsHero = context?.settings?.products_hero; //useSelector(selectProductsHero);
  const [selectedCategory, setSelectedCategory] = useState<ProductCategory>();
  const [selectedProducts, setSelectedProducts] = useState<Array<Product>>([]);
  const [selectedFilter, setSelectedFilter] = useState(
    params.filterCategorySlug
  );
  const handleFilterChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedFilter(event.target.value);
    if (event.target.value) {
      const nextCategory = categories.find(
        (category) => category.slug === event.target.value
      );
      if (nextCategory) {
        navigate(makeCategorySlug(nextCategory, categories, categoryBaseSlug), {
          state: { scroll: window.scrollY },
        });
        return;
      }
    }
    const nextCategory = categories.find(
      (category) => category.slug === params.categorySlug
    );
    if (nextCategory) {
      navigate(makeCategorySlug(nextCategory, categories, categoryBaseSlug), {
        state: { scroll: window.scrollY },
      });
      return;
    }
    return;
  };
  useEffect(() => {
    if (location.state && location.state.scroll) {
      window.scrollTo({ top: location.state.scroll });
    }
  }, [location]);
  useEffect(() => {
    setSelectedCategory(
      categories.find((cat) => cat.slug === params.categorySlug)
    );
  }, [params, products, categories]);
  useEffect(() => {
    const currentCategoryFilter = categories.find(
      (category) =>
        category.slug === (params.filterCategorySlug || params.categorySlug)
    );
    if (currentCategoryFilter) {
      setSelectedProducts(
        findProductsByCategory(products, categories, currentCategoryFilter)
      );
    } else {
      setSelectedProducts(products);
    }
  }, [params, products, categories]);
  const childCategories = getChildCategories(categories, selectedCategory);
  return (
    <CategoryContent
      category={selectedCategory}
      productsHero={productsHero}
      productsInCategory={selectedProducts}
      childCategories={childCategories}
      onFilterChange={handleFilterChange}
      selectedFilter={selectedFilter!}
    />
  );
};
