import React from "react";
import { Block } from "../../types/Page";
import { TaxonomyTerm } from "../../types/Category";
import { CategoryBar } from "../../modules";
export interface ICategoryBlockProps extends Block {
  categories: number[];
}
export const CategoryBlock: React.FC<ICategoryBlockProps> = ({
  categories,
}) => {
  return <CategoryBar categories={categories} />;
};
