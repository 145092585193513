/**
 * Check if two tables are equally with shallow comparison.
 * @param {object} object First object
 * @param {object} object Second object
 * @return {boolean} Returns true/false
 */
export const areEqualShallow = (a: any, b: any) => {
  for (let key in a) {
    if (!(key in b) || a[key] !== b[key]) {
      return false;
    }
  }
  for (let key in b) {
    if (!(key in a) || a[key] !== b[key]) {
      return false;
    }
  }
  return true;
};
