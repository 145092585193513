import styled from "styled-components";
import { colors } from "../../assets/style/variables";
export const Wrapper = styled.div`
  width: 50%;
  padding: 0 20px;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 30px;
  }
`
interface WrapperProps {
  image?: string,
};
export const InnerWrapper = styled.div`
  position: relative;
  min-height: 370px;
  background-color: ${colors.lightgray};
  background-image: url("${(props: WrapperProps) => props.image}");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  @media screen and (max-width: 1068px) {
  min-height: 250px;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    background-color: rgba(0,0,0,0.2);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  h3 {
    margin-bottom: 10px;
  }
`
export const Content = styled.div`
  display: inline-block;
  font-weight: 600;
  text-align: center;
  z-index: 1;
  span {
    border-bottom: 3px solid #fff;
  }
`
