import { remove as removeLS } from 'local-storage';
/**
 * Combination of checkOk() and getJSON(). Will check if ok and return JSON.
 *
 * @param {Object} res Fetch result object
 * @returns {Object|Promise} Will return the json object or rejected Promise if
 * not ok or bad JSON.
 */
export const checkOkAndJSON = (res: Response) => checkOk(res).then(getJSON);
/**
 * Checks if the fetch result is flagged "OK".
 *
 * @param {Object} res Fetch result object
 * @returns {Promise} Returns a resolved promise if OK and rejected if not
 */
export const checkOk = (res: Response): Promise<Response> => {

  if (res.ok) {
    return Promise.resolve(res);
  }
  if(res.status === 403) {
    removeLS('limitado_jwt');
  }
  return Promise.reject(res);
};
export type JSONResponse<T> = Response | T;
/**
 * Takes a fetch result object, checks if it has a json header and tries to
 * convert the payload to json.
 *
 * @param {Object} res Fetch result object
 * @returns {Object|Promise} Returns a json object or a rejected Promise with
 * the result object
 */
export const getJSON = (res: Response): Promise<JSONResponse<any>> => {
  const contentType = res.headers.get('content-type');
  if (contentType && contentType.includes('application/json')) {
    return res.json();
  }
  return Promise.reject(res);
};
