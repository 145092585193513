import styled from "styled-components";
import { colors } from "../../assets/style/variables";

export const Wrapper = styled.div`
display: flex;
justify-content: space-between;
flex-wrap: wrap;
margin: auto;
max-width: 1200px;
width: 100%;
padding: 64px 32px;

@media screen and (max-width: 768px) {
  padding: 32px;
}
`

export const Item = styled.div`
font-weight: 600;
display: flex;
align-items: center;

img {
  margin-right: 20px;
}

@media screen and (max-width: 992px) {
  width: 50%;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px solid;
  border-bottom-color: ${colors.lightgray};
  padding-bottom: 20px;

  img {
    display: none;
  }
}
`
