import styled from "styled-components";
import { colors } from "../../assets/style/variables";
interface IInputStyleProps{
  inputSize?:string
}
export const Input = styled.input`
all: unset;
cursor: auto;
padding: ${({ inputSize }:IInputStyleProps) => inputSize !== "large" ? "12px 10px" : "15px 20px"}; 
font-size: 16px;
border: 1px solid;
border-color: ${colors.darkgray};
border-radius: 2px;
color: #000;
background-color: #fff;
font-family: inherit;
box-sizing: border-box;

::placeholder {
  color: #979797;
}

:focus {
  outline: none;
  border-color: ${colors.accent};
}
`
