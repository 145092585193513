export interface Page {
  id: number,

  date: string,
  date_gmt: string,
  modified: string,
  modified_gmt: string,
  link: string,
  parent: number,
  slug: string,
  template: string,
  title: {
    rendered: string,
  },
  type: string,
  blocks: Block[],
}
export interface FrontPage extends Page { };
export enum TextAlign {
  left = 'left',
  right = 'right',
};
export enum BlockTypes {
  TextBlock = 'limitado/text-block',
  HeadingBlock = 'limitado/heading-block',
  BannerBlock = 'limitado/banner-block',
  TextImageBlock = 'limitado/text-image-block',
  HighlightedProductsBlock = 'limitado/highlighted-products-block',
  CategoryBlock = 'limitado/category-block',
  ParagraphBlock = 'core/paragraph'
};
export interface Block {
  name: BlockTypes,
  uid: string,
  data:any
};
export interface Image {
  url: string,
  widht: number,
  height: number,
};
export interface IBreadcrumbs extends Array<{ id: number, slug: string, label: string }> { };
