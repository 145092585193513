import React from "react";
import * as style from "./Container.style";
interface Props {
  valign?: style.valign,
};
export const Container: React.FC<Props> = props => (
  <style.Container valign={props.valign}>
    {props.children}
  </style.Container>
);

