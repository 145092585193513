import React from "react";
import * as style from "./InfoCard.style";
interface IProps {
  heading?: string;
  items: [string, string][];
}

export const InfoCard = ({ items, heading }: IProps) => {
  return (
    <style.Card>
      {heading && <h4>{heading}</h4>}
      <ul>
        {items.map(([key, value], index) => (
          <li key={index}>
            <strong>{key}</strong>
            <p>{value}</p>
          </li>
        ))}
      </ul>
    </style.Card>
  );
};
