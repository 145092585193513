import styled from "styled-components";

export const Wrapper = styled.div`
display: flex;
flex-wrap: wrap-reverse;
justify-content: space-between;
align-items: center;
padding-bottom: 64px;

@media (max-width: 767px) {
  padding-bottom: 32px;
}
`

export const Link = styled.div`
font-weight: 600;

a {
  text-decoration: none;
  color: inherit;
}

svg {
  margin-right: 10px;
}
`

export const Button = styled.div`
width: 40%;

a {
  width: 100%;
}

@media (max-width: 767px) {
  width: 100%;
  margin-bottom: 20px;
}
`
