import React, { useState } from "react";
import { setEventValue, valueIsDefinedNonEmpty } from "../../utils";
import { callChangePassword } from "../../api";
import { Input, Alert } from "../";
import * as style from "./ChangePassword.style";
import { AlertTypes } from "../../types/Enums";
import { LoadingButton } from "@mui/lab";

export const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newRepeatPassword, setNewRepeatPassword] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showValidationAlert, setShowValidationAlert] = useState(false);
  const onClick = () => {
    setShowErrorAlert(false);
    setShowSuccessAlert(false);
    setShowValidationAlert(false);
    if (
      valueIsDefinedNonEmpty(currentPassword) &&
      valueIsDefinedNonEmpty(newPassword) &&
      valueIsDefinedNonEmpty(newRepeatPassword) &&
      newPassword === newRepeatPassword
    ) {
      setLoading(true);
      return callChangePassword(currentPassword, newPassword)
        .then(() => {
          setShowSuccessAlert(true);
        })
        .catch((err) => {
          setShowErrorAlert(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setShowValidationAlert(true);
  };
  return (
    <form onSubmit={onClick}>
      <style.Inputs>
        <label>
          <span>*</span> Nuvarande lösenord
          <Input
            size="small"
            type="password"
            name="currentPassword"
            autoComplete="current-password"
            required
            value={currentPassword}
            onChange={setEventValue(setCurrentPassword)}
          />
        </label>
        <br />
        <label>
          <span>*</span> Nytt lösenord
          <Input
            size="small"
            type="password"
            name="newPassword"
            autoComplete="new-password"
            required
            value={newPassword}
            onChange={setEventValue(setNewPassword)}
          />
        </label>
        <br />
        <label>
          <span>*</span> Bekräfta lösenord
          <Input
            size="small"
            type="password"
            name="newPasswordRepeat"
            autoComplete="new-password"
            required
            value={newRepeatPassword}
            onChange={setEventValue(setNewRepeatPassword)}
          />
        </label>
        <LoadingButton onClick={onClick} loading={loading}>
          Spara
        </LoadingButton>
        <style.Alert>
          {showErrorAlert && (
            <Alert
              type={AlertTypes.DANGER}
              title="Det gick inte att byta ditt lösenord"
              text="Kontrollera att du angivit korrekta uppgifter."
            />
          )}
          {showValidationAlert && (
            <Alert
              type={AlertTypes.DANGER}
              title="Du har angett felaktiga uppgifter"
              text="Se till så att alla fält är ifyllda samt att lösenorden stämmer överrens."
            />
          )}
          {showSuccessAlert && (
            <Alert
              type={AlertTypes.SUCCESS}
              closeTimer
              title="Ditt lösenord är uppdaterat"
              text="Använd ditt nya lösenord nästa gång du loggar in."
            />
          )}
        </style.Alert>
      </style.Inputs>
    </form>
  );
};
