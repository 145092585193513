import styled from 'styled-components';
interface PageWrapProps {
  paddedTop?: boolean,
  $loading?: boolean,
};
export const PageWrap = styled.div<PageWrapProps>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: ${(props) => props.paddedTop ? '113px' : '0'};
  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 32px);
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: ${(props) => props.$loading ? 'block' : 'none'};
  }
`;
export const SpinnerWrap = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display:flex;
  justify-content:center;
`;
