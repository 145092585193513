import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { ProductCategory } from "../../types/Category";

import { sanitizePath } from "../../utils/sanitizers";

import * as style from "./CategoryItem.style";
import { Heading } from "..";
import { UserContext } from "../../providers/UserProvider";
import { AppContext } from "../../AppProvider";
interface Props extends ProductCategory {}
export const CategoryItem: React.FC<Props> = (props) => {
  const categorySlug = useContext(AppContext)?.settings?.category_slug; //useSelector(selectCategorySlug);
  const user = useContext(UserContext)?.user; //useSelector(selectUser);
  return (
    <style.Wrapper>
      <Link to={sanitizePath(`/${categorySlug}/${props.slug}`)}>
        <style.InnerWrapper image={props.heroImage}>
          <style.Content>
            <Heading title={props.name} size={3} />
            {user?.company ? <span>Köp nu</span> : <span>Läs mer</span>}
          </style.Content>
        </style.InnerWrapper>
      </Link>
    </style.Wrapper>
  );
};
