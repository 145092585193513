/**
 * Creates an event handler for a generic React form event.
 * Will call `setter` with the `event.target.value`
 *
 * @param {Function} setter Callback setter function
 */
export const setEventValue = (setter: (value: any) => void) =>
  (event: (React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLInputElement>)): void => {
    const target = event.target as HTMLInputElement;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    return setter(value);
  };
