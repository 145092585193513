import React, { useState, useEffect } from "react";
import { AlertTypes } from "../../types/Enums";
import { Cross } from "../../assets/icons";
import * as style from "./Alert.style";
export interface Props {
  type: AlertTypes;
  closeTimer?: boolean;
  title?: string;
  text: string;
}

export const Alert: React.FC<Props> = ({ type, closeTimer, title, text }) => {
  const [isClosed, setIsClosed] = useState<Boolean>(false);
  const onCloseClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsClosed(true);
  };

  useEffect(() => {
    let timeoutID: number;
    if (closeTimer) {
      timeoutID = window.setTimeout(() => {
        setIsClosed(true);
      }, 5000);
    }
    return () => {
      if (timeoutID) {
        window.clearTimeout(timeoutID);
      }
    };
  }, [closeTimer]);

  if (isClosed) {
    return null;
  }

  return (
    <style.Alert type={type} role="alert">
      <button onClick={onCloseClick}>
        <Cross stroke="#fff" />
      </button>
      {title && <h5>{title}</h5>}
      <p>{text}</p>
    </style.Alert>
  );
};
