export enum AlertTypes {
    DANGER = "danger",
    SUCCESS = "success",
  }

  export enum ButtonTypes {
    PRIMARY,
    SECONDARY,
  }
  export enum ButtonSizes {
    LARGE,
    MEDIUM,
    SMALL,
  };