import styled from "styled-components";
import { colors } from "../../assets/style/variables";

const Column = styled.div`
  padding: 0 32px;
`

export const Wrapper = styled.div`
display: flex;
justify-content: space-between;
flex-wrap: wrap-reverse;
margin: 0 -32px;
padding-bottom: 64px;

@media (max-width: 767px) {
  padding-bottom: 32px;
}
`

export const LargeColumn = styled(Column)`
width: 60%;

@media screen and (max-width: 1268px) {
  width: 50%;
}

@media screen and (max-width: 968px) {
  width: 100%;
}
`

export const SmallColumn = styled(Column)`
width: 40%;

@media screen and (max-width: 1268px) {
  width: 50%;
}

@media screen and (max-width: 968px) {
  width: 100%;
  margin-bottom: 32px;
}
`

export const Flex = styled.div`
display: flex;
flex-wrap: wrap;
`

export const FlexItem = styled.div`
width: 50%;

@media screen and (max-width: 468px) {
  width: 100%;
  margin-bottom: 32px;
}
`

export const Heading = styled.div`
margin-bottom: 20px;
`

export const FlexBetween = styled(Flex)`
justify-content: space-between;
`

export const SelectWrapper = styled.div`
margin: 0 -10px;

@media screen and (max-width: 468px) {
  margin: 0;
}
`

export const Select = styled.div`
margin: 32px 0;
padding: 0 10px;
width: 50%;
display: block;

@media screen and (max-width: 468px) {
  width: 100%;
  padding: 0;
}

select {
  width: 100%;
}
`

export const Submit = styled(FlexBetween)`
align-items: center;
flex-wrap: wrap-reverse;

button {
  width: 60%;

  @media screen and (max-width: 468px) {
    width: 100%;
    margin-bottom: 20px;
  }
}
`

export const Card = styled.div`
background-color: #fff;
padding: 32px;
margin-bottom: 32px;
`

export const CartItem = styled.div`
background-color: #fff;
padding: 32px 32px 0 32px;
border-bottom: 1px solid;
border-bottom-color: ${colors.lightgray};
`

export const CartHeader = styled.div`
background-color: #fff;
padding: 32px;
border-bottom: 1px solid;
border-bottom-color: ${colors.lightgray};
display: flex;
justify-content: space-between;
align-items: center;
`
interface ToggleProps {
  active:boolean;
}
export const Toggle = styled.button<ToggleProps>`
  all: unset;
  cursor: pointer;
  display: grid;
  place-content: center;
  width: 40px;
  height: 40px;
  background-color: ${colors.lightgray};
  border-radius: 2px;

  svg {
    margin: 0 auto;
    transform: ${props => props.active ? "rotate(180deg)" : ""};
    transition: transform 0.2s ease;
  }
`

export const Label = styled.label`
input {
  width: 100%;
}

strong {
  display: block;
}
`
