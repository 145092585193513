import { Company } from './Company';
export interface User {
  id: number,
  loginName: string,
  firstName: string,
  lastName: string,
  displayName: string,
  company: Company,
  roles:string[]
};

export interface IAddUser {
  id?:number,
  firstname: string,
  lastname: string,
  username: string,
  password: string,
  role: string
}
export const UserRoles = [
  {
    value: "company_level_1",
    title: "Kund",
  },
  {
    value: "company_level_2",
    title: "Företagsadmin",
  },
  {
    value: "company_level_3",
    title: "Administratör",
  },
];