import styled from 'styled-components';
export const Wrapper = styled.div`
  margin-bottom: 136px;
  padding: 136px 0;
  background-color: #F5F5F5;
  @media screen and (max-width: 767px) {
    padding: 36px 0;
    margin-bottom: 36px;
  }
`;
export const InnerWrapper = styled.div`
  &:first-child {
    border-top: 1px solid #ccc;
  }
`;
export const Item = styled.div`
  position: relative;
  border-bottom: 1px solid #ccc;
  padding-right: 45px;
  > h2 {
    margin: 0;
    padding: 32px 0;
    cursor: pointer;
    word-break: break-word;
    hyphens: auto;
    @media screen and (max-width: 767px) {
      font-size: 21px;
      padding: 23px 0;
    }
  }
`;
export const ItemInner = styled.div`
  padding-bottom: 32px;
`;
interface CloseIconProps {
  isOpen: boolean,
};
export const CloseIcon = styled.div`
  position: absolute;
  top: 43px;
  right: 3px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  @media screen and (max-width: 767px) {
    top: 23px;
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    margin-top: -1px;
    background-color: #000;
    transition: transform .2s cubic-bezier(0.680, -0.550, 0.265, 1.550);
  }
  &::before {
    transform: ${({ isOpen }: CloseIconProps) =>
    isOpen ? 'rotate(135deg)' : 'rotate(90deg)'};
  }
  &::after {
    transform: ${({ isOpen }: CloseIconProps) =>
    isOpen ? 'rotate(45deg)' : 'rotate(0)'};
  }
`;
