import styled from "styled-components";
import { colors } from "../../assets/style/variables";

interface StyleProps {
  selectSize?: string,
};

export const Select = styled.select`
all: unset;
-webkit-appearance: none;
border-radius: 0;
cursor: auto;
padding: ${({ selectSize }: StyleProps) => selectSize !== "large" ? "12px 10px" : "15px 20px"};
font-size: ${({ selectSize }: StyleProps) => selectSize !== "large" ? "14px" : "16px"};
border: 1px solid;
border-color: ${colors.darkgray};
border-radius: 2px;
color: #000;
background-color: #fff;
font-family: inherit;
box-sizing: border-box;

:focus {
  outline: none;
  border-color: ${colors.accent};
}
`
