import { DocumentModel } from './Document';
export enum InvoiceTypes {
  mail,
  email,
};
export interface Company {
  name: string
  role:string
  companyId: string
  companyName: string
  contactName: string
  contactEmail: string
  contactPhone: string
  billingEmail: string
  invoiceType: InvoiceTypes
  billing: BillingAddress[] | null
  shipping: ShippingAddress[] | null
  costCenters: string
  documents: DocumentModel[]
  users:any[]
  attestations:any[]
}
export interface BillingAddress {
  billing_company: string
  billing_recipient: string
  billing_address: string
  billing_city: string
  billing_post_code: string
  id: string
}
export interface ShippingAddress {
  shipping_company: string
  shipping_recipient: string
  shipping_address: string
  shipping_city: string
  shipping_post_code: string
  id: string
}
