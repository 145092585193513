import styled from 'styled-components';
import { TextAlign } from '../../types/Page';
interface WrapperProps {
  image?: string,
};
export const Wrapper = styled.div`
  position: relative;
  background-size: cover;
  background-position: center;
  padding: 190px 0;
  display: flex;
  align-items: flex-end;
  background-image: url("${({ image }: WrapperProps) => image}");
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  background-color: lightgray;
  &:before {
    content: '';
    display: block;
    position: absolute;
    background-color: rgba(0,0,0,0.2);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`
interface FlexProps {
  align?: TextAlign,
};
export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ align }: FlexProps) =>
    align === TextAlign.right
      ? 'flex-end'
      : 'flex-start'
  };
  justify-content: center;
`
export const Content = styled.div`
  position: relative;
  width: 50%;
  margin: 32px 0;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`
export const Button = styled.div`
  margin-top: 32px;
`
