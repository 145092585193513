import React from "react";
import { Block, Image } from "../../types/Page";
import { Container } from "../../modules";
import { TwoColBlock } from "../../blocks";
export interface ITextImageBlockProps extends Block {
  headline: string;
  subHeadline?: string;
  text: string;
  image?: Image;
}
export const TextImageBlock = (props: ITextImageBlockProps) => {
  return (
    <Container>
      <TwoColBlock
        subtitle={props.subHeadline}
        title={props.headline}
        description={props.text}
        image={props.image?.url}
      />
    </Container>
  );
};
