export const valueIsDefinedNonEmpty =
  (data: string | [] | boolean | null | undefined): boolean => {
    if (!data) {
      return false;
    }
    if (typeof data === 'string' || Array.isArray(data)) {
      if (data.length <= 0) {
        return false;
      }
    }
    return true;
  };
