import React, { useState, useRef, useContext } from "react";
import { useParams } from "react-router-dom";

import {
  isMobileDevice,
  findRelatedProducts,
  valueIsDefinedNonEmpty,
} from "../../utils";
import { ProductContent } from "./Product.content";
import { ProductKind } from "../../types/Product";
import { NotificationType } from "../../types/Notifications";
import { AppContext } from "../../AppProvider";
import { addToCart } from "../../utils/cart";
import { UserContext } from "../../providers/UserProvider";

const defaultState = {
  numberOption: "keep_number",
  fullName: "",
  phoneNumber: "",
  operator: "",
};

export const Product = () => {
  const node = useRef<HTMLDivElement>();

  const params: any = useParams();

  // States
  const [state, setState] = useState({ ...defaultState });
  const [modal, setModal] = useState(false);
  const [alert, setAlert] = useState(false);
  const [addSales, setAddSales] = useState(false);
  const [validationAlert, setValidationAlert] = useState(false);

  const context = useContext(AppContext);
  const userContext = useContext(UserContext);
  const products = context?.products ?? [];
  const categories = context?.categories ?? [];
  // Selectors
  //const products = useSelector(selectProducts);
  const product = products.find((p) => p.slug === params.product_slug);
  //const categories = useSelector(selectCategories);
  const productsInCategory = findRelatedProducts(
    products,
    categories,
    product!
  );

  const hideAlert = () => setAlert(false);

  const hideModal = () => {
    let site = document.getElementsByTagName("body")[0];
    site.classList.remove("modal-open");

    setModal(false);
    setAddSales(false);
  };

  const showModal = () => {
    let site = document.getElementsByTagName("body")[0];
    site.classList.add("modal-open");
    setModal(true);
  };

  const handleOutsideClick = (e: any) => {
    if (node?.current?.contains(e.target)) {
      return;
    }
    hideModal();
  };

  const handleChange = (evt: any) => {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  const dispatchCart = (productId: number, quantity: number) => {
    setAlert(false);
    console.log("dispathCart");
    let selected_options = {};

    // Make sure we have filled in required fields
    const { fullName, phoneNumber, operator, numberOption } = state;
    if (product?.product_type === ProductKind.Subscription) {
      if (numberOption === "keep_number") {
        if (
          !valueIsDefinedNonEmpty(fullName) ||
          !valueIsDefinedNonEmpty(phoneNumber) ||
          !valueIsDefinedNonEmpty(operator)
        ) {
          setValidationAlert(true);
          return;
        } else {
          selected_options = {
            fullName: state.fullName,
            phoneNumber: state.phoneNumber,
            operator: state.operator,
            numberOption: numberOption,
          };
        }
      } else {
        if (!valueIsDefinedNonEmpty(fullName)) {
          setValidationAlert(true);
          return;
        } else {
          selected_options = {
            fullName: state.fullName,
            numberOption: numberOption,
          };
        }
      }
    } else if (product?.product_type === ProductKind.Phone) {
      if (!valueIsDefinedNonEmpty(fullName)) {
        setValidationAlert(true);
        return;
      } else {
        selected_options = {
          fullName: state.fullName,
        };
      }
    }

    setValidationAlert(false);
    hideModal();
    setAlert(true);

    const usedModal =
      product?.product_type === ProductKind.Subscription ||
      product?.product_type === ProductKind.Phone;
    if (usedModal && quantity === 1 && !isMobileDevice()) {
      setAddSales(true);
      setTimeout(() => {
        showModal();
      }, 600);
    }

    userContext?.addToCart?.({
      id: "" + productId,
      quantity: "" + (quantity ?? 1),
      item_data: {
        subscription: product?.product_type === ProductKind.Subscription,
        selected_options: selected_options,
      },
    });

    setState({ ...defaultState });
  };

  if (!product) {
    return null;
  }

  return (
    <ProductContent
      product={product}
      dispatchCart={dispatchCart}
      productsInCategory={productsInCategory ?? []}
      alert={alert}
      addSales={addSales}
      modal={modal}
      showModal={showModal}
      hideModal={hideModal}
      fullName={state.fullName}
      phoneNumber={state.phoneNumber}
      operator={state.operator}
      numberOption={state.numberOption}
      handleChange={handleChange}
      node={node}
      handleOutsideClick={handleOutsideClick}
      validationAlert={validationAlert}
    />
  );
};
