import React, { useContext } from "react";

import { AppContext } from "../../AppProvider";
import { TopBarContent } from "./TopBar.content";

interface Props {}
export const TopBar: React.FC<Props> = () => {
  const context = useContext(AppContext);

  return (
    <TopBarContent headerBullets={context?.settings?.header_bullets ?? []} />
  );
};
