import React, { useState } from "react";
import {
  Wrapper,
  InnerWrapper,
  Item,
  CloseIcon,
  ItemInner,
} from "./TabList.style";

import AnimateHeight from "react-animate-height";
import { Container, RichText } from "..";
interface TabListItemProps {
  label: string;
  text: string;
}
const TabListItem: React.FC<TabListItemProps> = ({ label, text }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => setIsOpen((prevState) => !prevState);
  return (
    <Item>
      <CloseIcon onClick={handleClick} isOpen={isOpen} />
      <h2 onClick={handleClick}>{label}</h2>
      <AnimateHeight duration={500} height={isOpen ? "auto" : 0}>
        <ItemInner>
          <RichText markup={text} />
        </ItemInner>
      </AnimateHeight>
    </Item>
  );
};
interface TabListProps {
  items: {
    id: string;
    label: string;
    text: string;
  }[];
}
export const TabList: React.FC<TabListProps> = ({ items }) => (
  <Wrapper>
    <Container tight>
      <InnerWrapper>
        {items.map((item) => (
          <TabListItem key={item.id} label={item.label} text={item.text} />
        ))}
      </InnerWrapper>
    </Container>
  </Wrapper>
);
