/**
 * Format number with appropriate decimal points and thousand delimiters.
 * @param {number} num Number to format.
 * @return {string} Result is the argument formatted.
 */
export const formatCost = (cost?: any): string => {
  if (!cost) {
    return '0';
  };
  let number = cost;
  if (typeof cost === 'string') {
    number = cost.replace(',', '.').replace(' ', '');
  }
  number = Number(number);
  return number
    .toFixed(2)
    .toString()
    // Inserts a space on every third number, goes from right to left
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .replace('.', ',')
    // .replace(',00', '');
};
