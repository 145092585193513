import styled from "styled-components";

type TSearchItemTitle = {
  paddingBottom: number
}

export const SearchModalHeader = styled.div`
display:flex;
justify-content: space-between;
`

export const CloseButton = styled.button`
  all: unset;
  cursor: pointer;
  height: 29px;
  width: 29px;
  text-align: center;
`

export const SearchGroupTitle = styled.h3`
text-transform: capitalize;
margin-top: 32px;
color:#255985;
`

export const SearchItemTitle =styled.h4<TSearchItemTitle>`
padding-bottom: ${props => props.paddingBottom}px;

`