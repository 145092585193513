import styled from "styled-components";
import { colors } from "../../assets/style/variables";
import { AlertTypes } from "../../types/Enums";

interface StyleProps {
  type: AlertTypes,
};
const getBackgroundColor = (type: AlertTypes): string => {
  switch (type) {
    case AlertTypes.DANGER:
      return colors.red;
    case AlertTypes.SUCCESS:
      return colors.green;
    default:
      return 'transparent';
  }
};
export const Alert = styled.div`
  position: relative;
  color: #fff;
  font-family: 'Montserrat',sans-serif;
  padding: 20px;
  border-radius: 2px;
  background-color: ${({ type }: StyleProps) => getBackgroundColor(type)};
  h5 {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 0.5em;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
  }
  button {
    position: relative;
    display: block;
    float: right;
    margin-left: 20px;
    margin-bottom: 20px;
    border: 0;
    cursor: pointer;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: rgb(0, 0, 0, .2);
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 10px;
      height: 10px;
    }
  }
`;
