export interface Notification {
  id: number,
  type: NotificationType,
  closeTimer?: number,
  title?: string,
  text: string,
  onCloseClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
}
export enum NotificationType {
  ERROR = 'error',
  SUCCESS = 'success',
};