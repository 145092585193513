import styled from "styled-components";
import { colors } from "../../assets/style/variables";

export const Product = styled.div`
display: flex;
flex-wrap: wrap;
margin: 0 -32px;
`

export const Content = styled.div`
width: 50%;
padding: 0 32px;

h1 {
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  width: 100%;
}
`

export const ImageOuter = styled.div`
padding: 0 32px;
width: 50%;

@media screen and (max-width: 768px) {
  width: 100%;
  margin-bottom: 32px;
}
`

export const ImageInner = styled.div`
background-color: ${colors.lightgray};
padding: 32px;

img {
  box-sizing: border-box;
  width: 100%;
}
`

export const Description = styled.div`
  margin: 32px 0;

  p {
    margin-bottom: 20px;
  }

  .prod-details {
    display: block;
    margin-top: 1em;
  }
`

export const Bottom = styled.div`
background-color: ${colors.lightgray};
padding: 32px;
margin-top: 45px;

input {
  width: 57px;
  height: 57px;
  margin-right: 20px;
  padding: 17px 10px 16px;
  text-align: center;
}

button {
  display: inline-block;
  width: calc(100% - 77px);
  margin-top: 20px;
}
`
