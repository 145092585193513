import React from "react";
import { Block } from "../../types/Page";
import { Container, RichText } from "../../components";

export interface ITextBlockProps extends Block {
  text: string;
}
export const TextBlock: React.FC<ITextBlockProps> = ({ text }) => {
  return (
    <Container>
      <RichText markup={text} />
    </Container>
  );
};
