import axios from "axios";
import { defaultHeaders } from ".";

export class SubscriptionsApi {
    static async all() {
        const response = await axios.get('/wp-json/api/subscriptions',{headers:defaultHeaders() as any})
        console.log('SubscriptionsApi.all ',response);
        return response.data;
    }
    static async remove() {

    }
    static async create() {

    }
    static async update() {

    }
}