import styled from "styled-components";
import { colors } from "../../assets/style/variables";
interface StyleProps {
  size?: string,
  checked:boolean
};
export const Label = styled.label`
all: unset;
-webkit-appearance: none;
display: flex;
align-items: center;
border-radius: 0;
cursor: pointer;
padding: ${({ size }:StyleProps) => size !== "large" ? "12px 10px" : "15px 20px"};
font-size: ${({ size }:StyleProps) => size !== "large" ? "14px" : "16px"};
border: 1px solid;
border-color: ${({ checked }:StyleProps) => checked ? colors.accent : colors.darkgray};
border-radius: 2px;
color: #000;
background-color: ${colors.lightgray};
font-family: inherit;
box-sizing: border-box;

svg {
  margin-right: 12px;
  width: 14px;
  height: 14px;
}

input[type="radio"] {
  -webkit-appearance: none;
}
`
