import styled from 'styled-components';
interface ContainerProps {
  tight?: boolean,
  noPadding?: boolean,
};
export const Container = styled.div`
  position: relative;
  margin: auto;
  max-width: ${(props: ContainerProps) => props.tight ? '900px' : '1200px'};
  width: 100%;
  padding: ${(props: ContainerProps) => props.noPadding ? '0 32px' : '32px'};
`;
