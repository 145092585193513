import { createContext, useContext, useEffect, useRef, useState } from "react";
import { User } from "../types/User";
//import { callGetUserSubscriptions } from "../api";
import { UserApi } from "../api/user";
import { set as setLS } from "local-storage";
import { remove as removeLS } from "local-storage";
import { AppContext } from "../AppProvider";
import { CartProvider } from "./CartProvider";
import { CartItem, ICart } from "../types/Cart";
import { CartApi } from "../api/cart";
import { Item } from "../utils/cart";
import { Subscription } from "../types/Subscription";
import { Order } from "../types/Order";
import { OrderApi } from "../api/order";
import { SubscriptionsApi } from "../api/subscriptions";

export interface IUserContext {
  user: User;
  cart: ICart;
  orders: Order[];
  subscriptions: Subscription[];
  isCartLoading: boolean;
  attestations: Order[];
  logIn: (
    username: string,
    password: string,
    remember: boolean
  ) => Promise<void>;

  logOut: () => Promise<void>;

  removeUser: (id: number) => void;
  // Cart functions
  clearCart: () => void;
  addToCart: (item: Item) => void;
  removeFromCart: (id: string) => void;
  createOrder: (cart: ICart, options: any) => Promise<void>;
  getOrder: (id: number) => Order | undefined;
  updateOrder: (order: Order) => void;
}

export const UserContext = createContext<Partial<IUserContext>>({});

export const UserProvider = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  // useRef to avoid double re-render (we're calling app provider)
  const userRef = useRef<User>();
  const appContext = useContext(AppContext);
  const [cart, setCart] = useState<ICart>();
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [orders, setOrders] = useState<Order[]>([]);

  useEffect(() => {
    const init = async () => {
      try {
        userRef.current = (await UserApi.get()) ?? undefined;
        if (userRef.current) {
          await loadUserData();
        }
        appContext.setLoggedIn?.(!!userRef.current);
      } catch {
        console.error("failed to load user");
        appContext.setLoggedIn?.(false);
      }
    };
    init();
  }, []);

  const loadUserData = async () => {
    try {
      setCart(await CartApi.get());
      setOrders((await OrderApi.all()) ?? []);
      setSubscriptions(await SubscriptionsApi.all());
    } catch {
      console.error("Failed to load data");
    }
  };

  const unloadUserData = async () => {
    setCart(undefined);
    setOrders([]);
    setSubscriptions([]);
  };

  const getOrder = (id: number) => {
    let order = orders?.find((x) => x.orderData.id === id);
    return (
      order ??
      userRef?.current?.company.attestations.find((x) => x.orderData.id === id)
    );
  };
  const login = async (
    username: string,
    password: string,
    remember: boolean
  ) => {
    try {
      const data = await UserApi.login(username, password, remember);
      console.log("token ", data.token);
      if (data.token) {
        setLS("limitado_jwt", data.token);
        userRef.current = (await UserApi.get()) ?? undefined;
        await loadUserData();
        appContext.setLoggedIn?.(!!userRef.current);
      }
    } catch {}
  };

  const logout = async () => {
    try {
      await UserApi.logout();
      removeLS("limitado_jwt");
      userRef.current = undefined;
      unloadUserData();
      appContext.setLoggedIn?.(false);
    } catch {
      console.error("Failed to log out");
    }
  };

  const addToCart = async (item: Item) => {
    try {
      const result = await CartApi.add(item);
      setCart(result);
    } catch {
      console.error("Failed to add to cart");
    }
  };

  const removeFromCart = async (line_item_key: string) => {
    try {
      const cart = await CartApi.remove(line_item_key);
      console.log(cart);
      setCart(cart);
    } catch {
      console.error("Failed to remove from cart");
    }
  };

  const clearCart = async () => {
    try {
      const cart = await CartApi.clear();
      console.log("Clear cart ", cart);
      setCart(cart);
    } catch {
      console.error("Failed to clear cart");
    }
  };

  const removeUser = async (id: number) => {
    return await UserApi.remove(id);
  };

  const addUser = async (
    firstname: string,
    lastname: string,
    username: string,
    password: string,
    role: string
  ): Promise<User | null> => {
    return await UserApi.add(firstname, lastname, username, password, role);
    //return await callAddUser(firstname, lastname, username, password, role);
  };

  const createOrder = async (cart: ICart, options: any) => {
    try {
      const res = await OrderApi.create(cart, options);
      await clearCart();
      // Add new order to the list
    } catch {
      console.error("Failed to create order");
    }
  };

  const updateOrder = (order: Order) => {
    setOrders((prev) =>
      prev.map((x) => (x.orderData.id === order.orderData.id ? order : x))
    );
  };

  let state: Partial<IUserContext> = {
    logIn: login,
    logOut: logout,
    addToCart: addToCart,
    removeFromCart: removeFromCart,
    clearCart: clearCart,
    createOrder: createOrder,
    removeUser: removeUser,
    getOrder: getOrder,
    updateOrder: updateOrder,
    get user() {
      return userRef.current;
    },
    get cart() {
      return cart;
    },
    get orders() {
      return orders;
    },
    get subscriptions() {
      console.log("subscriptions ", subscriptions);
      return subscriptions;
    },
    get isCartLoading() {
      return false;
    },
    get attestations() {
      return userRef.current?.company?.attestations ?? [];
    },
  };
  return (
    <UserContext.Provider value={state}>
      <CartProvider>{children}</CartProvider>
    </UserContext.Provider>
  );
};
