import React, { useContext } from "react";

import { RadioGroup, Modal, Select, Input, Alert } from "../../../components";

import * as style from "../Product.style";
import { AlertTypes } from "../../../types/Enums";
import { AppContext } from "../../../AppProvider";
interface Props {
  node: any; // TODO: Type me
  numberOption: any; // TODO: Type me
  onCloseClick: EventListener;
  onOperatorChange: EventListener;
  onAddToCartClick: EventListener;
  onNumberOptionChange: EventListener;
  onFullNameChange: EventListener;
  onPhoneNumberChange: EventListener;
  operator: string;
  fullName: string;
  phoneNumber: string;
  validationAlert: boolean;
}
export const SubscriptionModal: React.FC<Props> = ({
  node,
  onCloseClick,
  numberOption,
  operator,
  fullName,
  phoneNumber,
  onOperatorChange,
  onAddToCartClick,
  onNumberOptionChange,
  onFullNameChange,
  onPhoneNumberChange,
  validationAlert,
}) => {
  const context = useContext(AppContext);
  const phoneSubscriptionAlternatives =
    context?.settings?.phone_subscription_alternatives ?? [];
  return (
    <Modal
      node={node}
      title="Nummer"
      description="Behålla eller skaffa nytt nummer till abonnemanget."
      onSubmit={onAddToCartClick}
      handleClose={onCloseClick}
    >
      <RadioGroup
        options={[
          {
            name: "numberOption",
            value: "keep_number",
            label: "Behåll nummer",
            checked: numberOption === "keep_number",
            onChange: onNumberOptionChange,
          },
          {
            name: "numberOption",
            value: "new_number",
            label: "Nytt nummer",
            checked: numberOption === "new_number",
            onChange: onNumberOptionChange,
          },
        ]}
      />
      {numberOption === "keep_number" && (
        <style.Label>
          Nuvarande operatör
          <Select
            name="operator"
            onChange={onOperatorChange}
            value={operator}
            options={[
              {
                label: "Välj operatör",
                value: "",
              },
              ...phoneSubscriptionAlternatives?.map((item) => ({
                label: item.name,
                value: item.name,
              })),
            ]}
          />
        </style.Label>
      )}
      <style.Label>
        Fullständigt namn
        <Input
          type="input"
          placeholder="Fullständigt namn"
          onChange={onFullNameChange}
          value={fullName}
          name="fullName"
        />
      </style.Label>
      {numberOption === "keep_number" && (
        <style.Label>
          Nuvarande mobilnummer
          <Input
            type="input"
            placeholder="Nuvarande mobilnummer"
            onChange={onPhoneNumberChange}
            value={phoneNumber}
            name="phoneNumber"
          />
        </style.Label>
      )}
      {validationAlert && (
        <Alert type={AlertTypes.DANGER} text="Du måste fylla i alla fält" />
      )}
    </Modal>
  );
};
