import React from "react";

import * as style from "./CartNav.style";

interface ICartNav {
  cartEmpty: boolean;
}
export const CartNav = ({ cartEmpty }: ICartNav) => (
  <style.Nav>
    <style.NavItem to="/kundvagn">
      <span>1</span>
      <strong>Varukorg</strong>
    </style.NavItem>

    <style.NavItem
      className={cartEmpty ? "disabled" : ""}
      to="/kundvagn/bestall"
      onClick={(e) => cartEmpty && e.preventDefault()}
    >
      <span>2</span>
      <strong>Slutför</strong>
    </style.NavItem>
  </style.Nav>
);
