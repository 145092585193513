import React from "react";
import { Link } from "react-router-dom";
import { Heading } from "../../components";
import { Container, Page } from "../../modules";
import { NotFoundWrapper } from "./NotFound.style";

export const NotFound = () => (
  <Page paddedTop>
    <NotFoundWrapper id="not-found">
      <Container>
        <Heading size={1} title="Förlåt! Sidan hittades inte" />
        <Link to="/">
          ⟵ Följ länken här för att komma tillbaka till startsidan.
        </Link>
      </Container>
    </NotFoundWrapper>
  </Page>
);
