import styled from "styled-components";

interface IScrollContainerProps {
  navFixed:boolean;
}
export const ScrollContainer = styled.div<IScrollContainerProps>`
  position: ${props => props.navFixed ? 'fixed' : 'absolute'};
  top: 0;
  z-index: 301;
  width: 100%;
`;

interface IScrollAnimationProps {
  isHidden:boolean;
  navHasBg:boolean;
  pageHasPaddedTop:boolean;
}

export const ScrollAnimation = styled.div<IScrollAnimationProps>`
  transition: transform .3s, background-color .3s;
  transform: ${props => props.isHidden ? 'translateY(-100%)' : 'translateY(0)'};
  background-color: ${props => (props.navHasBg || props.pageHasPaddedTop)
    ? '#fff'
    : 'transparent'
  };
  border-bottom: 1px solid ${props => (props.navHasBg || props.pageHasPaddedTop)
    ? '#DBDBDB'
    : 'transparent'
  };
`;

interface INavbarWrapperProps {
  navHasBg?:boolean;
  pageHasPaddedTop?:boolean;
}
export const NavbarWrapper = styled.div<INavbarWrapperProps>`
  display: flex;
  height: 80px;
  max-width: 1200px;
  margin: auto;
  width: 100%;
  padding: 0 32px;
  position: relative;
  color: ${props => (props.navHasBg || props.pageHasPaddedTop)
    ? 'inherit'
    : 'white'
  };
  justify-content: space-between;
`;

interface INavBarButtonProps {
  open:boolean;
}
export const NavBarButton = styled.button<INavBarButtonProps>`
  all: unset;
  cursor: pointer;
  position: relative;
  display: block;
  height: 32px;
  width: 32px;
  text-align: center;
  border-radius: 50%;
  background-color: white;

  :before,
  :after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center center;
    background-color: #000;
    height: 2px;
    border-radius: 1px;
    width: 18px;

    transition:
      transform .2s cubic-bezier(0.680, -0.550, 0.265, 1.550),
      margin-top .2s cubic-bezier(0.680, -0.550, 0.265, 1.550);
  }

  :before {
    margin-top: ${props => props.open ? '0' : '-4px'};
    transform:
      translate(-50%, -50%)
      ${props => props.open ? 'rotate(-45deg)' : 'rotate(0)'};
  }

  :after {
    margin-top: ${props => props.open ? '0' : '4px'};
    transform:
      translate(-50%, -50%)
      ${props => props.open ? 'rotate(45deg)' : 'rotate(0)'};
  }
`;

export const MobileNavWrap = styled.div`
  /* flex-grow: 1;
  flex-basis: 0; */
  flex: 3;
  display: none;
  align-items: center;

  @media screen and (max-width: 767px) {
    display: flex;
  }
`;

export const LogoWrap = styled.div`
  flex: 10;
  flex-basis: 0;
  display: flex;
  align-items: center;

  @media screen and (max-width: 767px) {
    justify-content: center;
  }

  #logo-desktop {
    display: block;

    @media (max-width: 767px) {
      display: none;
    }
  }

  #logo-mobile {
    display: none;

    @media (max-width: 767px) {
      display: block;
    }
  }
`;

export const LoggedInWrap = styled.div`
  /* flex-grow: 1;
  flex-basis: 0; */
  flex:3;
  display: flex;
  justify-content: flex-end;
  gap:10px;
  #toggle-dropdown {
    display: block;
    cursor: pointer;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .user-icon {
    all: unset;
    cursor: pointer;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: white;
    text-align: center;

    svg {
      display: inline-block;
      margin-top: 1px;
    }
  }
`;

export const InnerWrapper = styled.div`
`;

export const Links = styled.ul`
  display: flex;
  align-items: center;

  li {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    margin-right: 24px;
    display: flex;
    align-items:center;
    gap:4px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const NavCartAmount = styled.div`
    position: absolute;
    top: 50%;
    align-items: center;
    display: flex;
    width: 17px;
    justify-content: center;
    transform: translate(0, -50%);
    color: white;
    font-size: 12px;
`

export const NavItems = styled.div`
  max-width: 1200px;
  margin: auto;
  width: 100%;
  padding: 50px 32px 0 32px;

  @media screen and (max-width: 567px) {
    padding: 32px 32px 0;
    height: calc(100vh - 113px);
    overflow-y: scroll;
  }

  > div {
    display: flex;

    @media screen and (max-width: 767px) {
      display: block;
    }

    > * {
      flex: 0 0 50%;
      max-width: 50%;

      @media screen and (max-width: 767px) {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  a {
    display: block;
    padding: 6px 0;
    color: #000000;
    font-size: 16px;
    font-weight: 600;

    &:hover {
      color: colors.accent;
    }
  }

  > a {
    display: block;
    padding: 50px 0;
    text-align: center;
    text-decoration: underline;

    @media screen and (max-width: 767px) {
      padding: 32px 0;
    }
  }
`;

export const NavItemsText = styled.ul`
  display: grid;
  grid-template-columns: 50% 50%;

  @media screen and (max-width: 767px) {
    display: block;
  }
`;

interface INavExpanderProps {
  navOpen:boolean;
}
export const NavExpander = styled.div<INavExpanderProps>`
  background-color: #fff;
  transition: opacity 0.3s;
  opacity: ${props => props.navOpen ? '1' : '0'};
`;

export const NavItemsImage = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-end;

  @media screen and (max-width: 567px) {
    display: block;
  }

  li {
    padding-right: 15px;

    @media screen and (max-width: 767px) {
      margin-top: 32px;
    }

    @media screen and (max-width: 567px) {
      padding-right: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  li + li {
    padding-left: 15px;

    @media screen and (max-width: 567px) {
      padding-left: 0;
    }
  }
`;

interface INavItemImageProps {
  image:string|boolean
}
export const NavItemImage = styled.li<INavItemImageProps>`
  flex: 0 0 50%;
  max-width: 50%;

  @media screen and (max-width: 567px) {
    max-width: 100%;
    width: 100%;
  }

  a {
    position: relative;
    background-color: #F1F1F1;
    background-image: ${props => `url(${props.image})`};
    background-size: cover;
    background-position: center;
    padding: 0 0 50% 0;
    height: 0;
    color: #fff;

    span {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;


export const SearchInputWrapper = styled.form`
display:flex;
justify-content:center;
align-items:center;
flex:100;

`
export const SearchLabel = styled.span`
display:none ;
@media (min-width: 768px){
  display:inline-block ;
}
`

export const DesktopLi = styled.li`
display:none !important;
@media (min-width: 768px){
  display:flex !important;
  white-space:nowrap;
}
`