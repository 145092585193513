import styled from "styled-components";

export const Breadcrumb = styled.div`
margin-bottom: 32px;

li {
  display: inline-block;

  +li:before {
    content: '/';
    padding: 0 .5em;
  }
}
`
