import React, { useContext, useState } from "react";
import { InvoiceTypes } from "../../../types/Company";
import { SettingsContent } from "./Settings.content";
import { User } from "../../../types/User";
import { UserContext } from "../../../providers/UserProvider";

export interface ISettingsPageProps {
  user?: User | null;
  costPlace: string;
  setCostPlace: any;
  onCostAndInvoiceSave: any;
  costAndInvoiceSaveLoading: any;
  invoiceType: InvoiceTypes;
  setInvoiceType: any;
}
export const Settings = () => {
  const userContext = useContext(UserContext);
  //const dispatch = useDispatch();
  const user = userContext?.user; //useSelector(selectUser);
  const [costPlace, setCostPlace] = useState(user?.company?.costCenters ?? "");
  const [invoiceType, setInvoiceType] = useState(
    user?.company?.invoiceType ?? InvoiceTypes.email
  );
  const [costAndInvoiceSaveLoading, setCostAndInvoiceSaveLoading] =
    useState(false);

  const onCostAndInvoiceSave = () => {
    setCostAndInvoiceSaveLoading(true);
    console.log("Fix this");
    /*
    Promise.all([
      dispatch(setDefaultCostPlace(costPlace)),
      dispatch(setDefaultInvoiceType(invoiceType)),
    ])
      .then(() => {
        // TODO: Handle success
      })
      .catch(() => {
        // TODO: Handle error
      })
      .finally(() => setCostAndInvoiceSaveLoading(false));
      */
  };

  return (
    <SettingsContent
      user={user}
      costPlace={costPlace}
      setCostPlace={setCostPlace}
      onCostAndInvoiceSave={onCostAndInvoiceSave}
      costAndInvoiceSaveLoading={costAndInvoiceSaveLoading}
      invoiceType={invoiceType}
      setInvoiceType={setInvoiceType}
    />
  );
};
