import React, { useContext, useEffect } from "react";

import { ButtonLink, Heading, RichText } from "../../components";
import { Container } from "../../modules";

import * as style from "./ThankYou.style";
import { AppContext } from "../../AppProvider";

export const ThankYou = () => {
  const context = useContext(AppContext);
  const message = context?.settings?.checkout_success_message; //useSelector(selectCheckoutSuccessMessage);

  useEffect(() => {
    document.body.classList.add("is-bg");

    return () => {
      document.body.classList.remove("is-bg");
    };
  }, []);

  return (
    <Container>
      <style.Card>
        <style.Divider>
          <Heading size={3} title="Tack för din order!" />
          <p>
            Vi skickar en bekräftelse till dig så fort vi har behandlat din
            order.
          </p>
        </style.Divider>

        <style.Section>
          {message && <RichText markup={message} />}
        </style.Section>

        <style.Button>
          <ButtonLink path="/" label="Tillbaka" />
        </style.Button>
      </style.Card>
    </Container>
  );
};
