import React from "react";
import PropTypes from "prop-types";
import { formatCost } from "../../utils";
import * as style from "./PriceTable.style";

const HANDLE_COST_TYPE = (type: string) => {
  switch (type) {
    case "month":
      return "Månadsavgift";
    case "shipping":
      return "Frakt & avgifter";
    case "total":
      return "Total";
    default:
      return "Total";
  }
};

interface IWrapStrong {
  strong: boolean;
  children: any;
}
const WrapStrong = ({ strong, children }: IWrapStrong) =>
  strong ? <strong>{children}</strong> : children;

interface IPriceTableProps {
  small: boolean;
  costs: any[];
}
export const PriceTable = (props: IPriceTableProps) => (
  <style.Wrapper small={props.small || false}>
    {props.costs.map((item, i) => (
      <style.Item key={i}>
        <p>
          <WrapStrong strong={item.type === "total"}>
            {HANDLE_COST_TYPE(item.type)}
          </WrapStrong>
        </p>
        <p>
          <WrapStrong strong={item.type === "total"}>
            {`${item.cost ? formatCost(item.cost) : 0} kr`}
          </WrapStrong>
        </p>
      </style.Item>
    ))}
  </style.Wrapper>
);
