import React from "react";
import { Cross } from "../../assets/icons";
import { Heading } from "..";

import * as style from "./Modal.style";
import { ButtonSizes, ButtonTypes } from "../../types/Enums";
import { LoadingButton } from "@mui/lab";

type IModalProps = {
  node: any;
  title?: string;
  handleClose: any;
  description?: string;
  submitLabel?: string;
  onSubmit?: any;
  loading?: boolean;
  customHeader?: React.ReactNode;
  children?: React.ReactNode;
  minHeight?: string;
  topPosition?: string;
};

export const Modal = ({
  node,
  title,
  handleClose,
  description,
  children,
  submitLabel,
  onSubmit,
  loading,
  customHeader,
  minHeight,
  topPosition,
}: IModalProps) => {
  return (
    <style.Wrapper
      ref={node}
      topPosition={topPosition}
      onClick={(e: any) => e.stopPropagation()}
      minHeight={minHeight}
    >
      {customHeader ? (
        customHeader
      ) : (
        <style.Header>
          {title && <Heading spacing={20} size={3} title={title} />}
          <button onClick={handleClose}>
            <Cross stroke="#000" />
          </button>
        </style.Header>
      )}

      <p>{description}</p>

      <style.Fields>{children}</style.Fields>

      {onSubmit && (
        <LoadingButton
          variant="outlined"
          onClick={onSubmit}
          loading={loading}
          disabled={loading}
        >
          {submitLabel || "Fortsätt"}
        </LoadingButton>
      )}
    </style.Wrapper>
  );
};
