import { createContext, useEffect } from "react";
import { ICart } from "../types/Cart";

export interface ICartContext {
  cart: ICart;
  isLoading: boolean;
  addToCart: (item: any) => void;
  removeFromCart: (itemId: string) => void;
  clearCart: () => void;
  createOrder: (cart: ICart, options: any) => Promise<void>;
}

export const CartContext = createContext<Partial<ICartContext>>({});

export const CartProvider = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  let state: Partial<ICartContext> = {};
  useEffect(() => {
    const init = async () => {};
  }, []);
  return <CartContext.Provider value={state}>{children}</CartContext.Provider>;
};
