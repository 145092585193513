import React from "react";
import PropTypes from "prop-types";

import * as style from "./Select.style";

interface ISelectOption {
  value: string;
  label: string;
}
interface ISelectProps {
  size?: string;
  onChange: (ev: any) => void;
  options: ISelectOption[];
  value: string;
  name: string;
}

export const Select = ({
  size,
  options,
  value,
  name,
  onChange,
}: ISelectProps) => (
  <style.Select selectSize={size} onChange={onChange} value={value} name={name}>
    {options.map((option, i) => (
      <option key={i} value={option.value}>
        {option.label}
      </option>
    ))}
  </style.Select>
);

export default Select;
