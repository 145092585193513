import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import { Page, Container } from "../../../modules";
import { Spinner } from "../../../components";
import { OrderSummaryContent } from "./OrderSummary.content";
import { Order } from "../../../types/Order";
import { UserContext } from "../../../providers/UserProvider";
export const OrderSummary: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState<Order | null>();

  const context = useContext(UserContext);
  useEffect(() => {
    const init = async () => {
      setLoading(true);
      setOrder(undefined);
      setOrder(context?.getOrder?.(Number(id)));
      setLoading(false);
    };
    if (id) {
      init();
    }
  }, [id]);
  console.log("order ", order);
  if (loading) {
    return (
      <Page>
        <Container valign="center">
          <Spinner />
        </Container>
      </Page>
    );
  }
  if (!order) {
    return <p>Det gick inte att hitta ordern med nummer {id}. </p>;
  }
  return <OrderSummaryContent order={order} />;
};
