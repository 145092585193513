import React, {
  useEffect,
  useState,
  useRef,
  ReactEventHandler,
  useContext,
} from "react";
import { areEqualShallow, formatCost } from "../../utils";
import { Breadcrumb, ProductCard, TabList } from "../../components";
import {
  Container,
  RelatedCategories,
  RelatedProducts,
  Page,
} from "../../modules";
import { TwoColBlock } from "../../blocks";
import {
  Product as IProduct,
  ProductType,
  ProductKind,
} from "../../types/Product";

import * as style from "./Product.style";

import { SubscriptionModal } from "./Modals/SubscriptionModal";
import { PhoneModal } from "./Modals/PhoneModal";
import { AdditionalSalesModal } from "./Modals/AdditionalSalesModal";
import { AppContext } from "../../AppProvider";
interface Props {
  product: IProduct;
  dispatchCart: (arg0: any, quantity?: any) => void;
  productsInCategory: Array<IProduct>;
  alert: boolean;
  addSales: boolean;
  showModal: (arg1?: any) => void;
  hideModal: () => void;
  modal: boolean;
  fullName: string;
  phoneNumber: string;
  handleChange: EventListener;
  operator: string;
  numberOption: string;
  validationAlert: boolean;
  node: any; // TODO: type
  handleOutsideClick: EventListener;
}
export const ProductContent: React.FC<Props> = ({
  product,
  dispatchCart,
  productsInCategory,
  alert,
  addSales,
  showModal,
  hideModal,
  modal,
  fullName,
  phoneNumber,
  handleChange,
  operator,
  numberOption,
  validationAlert,
  node,
  handleOutsideClick,
}) => {
  const context = useContext(AppContext);
  const hasVariations = product.variations.length > 0;
  const detailsRef = useRef<HTMLDivElement>(null);
  const additionalSalesCTAItems = context?.settings?.add_to_cart_cta ?? []; //useSelector(selectAddToCartCTA);
  const [quantity, setQuantity] = useState<number>(1);
  const [activeVariant, setActiveVariant] = useState(
    hasVariations ? product.variations[0] : undefined
  );
  const validAttributes =
    hasVariations && activeVariant
      ? product.attributes.map((attr) => {
          return {
            ...attr,
            values: attr.values.filter((val) =>
              product.variations.some((variant) =>
                activeVariant && activeVariant.attributes
                  ? areEqualShallow(variant.attributes, {
                      ...activeVariant.attributes,
                      [`attribute_${attr.name}`]: val,
                    })
                  : false
              )
            ),
          };
        })
      : [];
  useEffect(() => {
    setActiveVariant(
      product.variations.length > 0 ? product.variations[0] : undefined
    );
  }, [product.variations]);
  useEffect(() => {
    modal &&
      document.addEventListener("mousedown", handleOutsideClick, {
        passive: true,
      });
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [modal, handleOutsideClick]);
  const onVariantSelect = (data: { attribute: string; value: string }) => {
    const nextSelection = product.variations.find((variant) =>
      activeVariant && activeVariant.attributes
        ? areEqualShallow(variant.attributes, {
            ...activeVariant.attributes,
            [`attribute_${data.attribute}`]: data.value,
          })
        : false
    );
    if (!nextSelection) {
      console.error("Could not find matching variant");
      return;
    }
    setActiveVariant(nextSelection);
  };
  const handleToDetailsClick: ReactEventHandler<HTMLAnchorElement> = (
    event
  ) => {
    event.preventDefault();
    if (detailsRef.current) {
      window.scrollTo({
        top: detailsRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  };
  if (!product) {
    return null;
  }
  const addToCart = () => {
    console.log("inside addToCart");
    if (
      (product.product_type === ProductKind.Subscription ||
        product.product_type === ProductKind.Phone) &&
      quantity > 1
    ) {
      setQuantity(quantity - 1);
      setTimeout(() => {
        showModal(true);
      }, 200);
      return dispatchCart(
        product.type === ProductType.Simple
          ? product.id
          : activeVariant
          ? activeVariant.variation_id
          : product.id
      );
    }
    console.log("will dispatch cart");
    return dispatchCart(
      product.type === ProductType.Simple
        ? product.id
        : activeVariant
        ? activeVariant.variation_id
        : product.id,
      quantity
    );
  };

  const onAddClick = () => {
    console.log("onAddClick");
    if (
      product.product_type === ProductKind.Phone ||
      product.product_type === ProductKind.Subscription
    ) {
      showModal();
    } else {
      console.log("onAddToCart");
      addToCart();
    }
  };
  return (
    <Page paddedTop>
      <style.ProductWrap>
        <Container>
          {modal &&
            product.product_type === ProductKind.Subscription &&
            !addSales && (
              <SubscriptionModal
                node={node}
                fullName={fullName}
                phoneNumber={phoneNumber}
                onCloseClick={hideModal}
                numberOption={numberOption}
                operator={operator}
                validationAlert={validationAlert}
                onFullNameChange={handleChange}
                onNumberOptionChange={handleChange}
                onOperatorChange={handleChange}
                onPhoneNumberChange={handleChange}
                onAddToCartClick={addToCart}
              />
            )}
          {modal && product.product_type === ProductKind.Phone && !addSales && (
            <PhoneModal
              node={node}
              fullName={fullName}
              onAddToCartClick={addToCart}
              onCloseClick={hideModal}
              onFullNameChange={handleChange}
              validationAlert={validationAlert}
            />
          )}

          {modal && addSales && (
            <AdditionalSalesModal
              node={node}
              onCloseClick={hideModal}
              ctaItems={additionalSalesCTAItems}
            />
          )}
          <Breadcrumb />
          <ProductCard
            image={product.images[0]}
            title={product.title}
            description={product.description}
            price={Number(product.price)}
            attributes={validAttributes}
            onVariantSelect={onVariantSelect}
            activeVariant={activeVariant}
            setQuantity={setQuantity}
            onClick={onAddClick}
            onToDetailsClick={
              product.details ? handleToDetailsClick : undefined
            }
          />
        </Container>
        {(product.banner_subtitle ||
          product.banner_headline ||
          product.banner_text ||
          product.banner_image) && (
          <Container>
            <TwoColBlock
              subtitle={product.banner_subtitle}
              title={product.banner_headline}
              description={product.banner_text}
              image={product.banner_image}
            />
          </Container>
        )}
        {product.details && (
          <>
            <div id="product-details" ref={detailsRef} />
            <TabList items={product.details} />
          </>
        )}
        {productsInCategory && productsInCategory.length > 0 && (
          <Container>
            <RelatedProducts
              title="Liknande produkter"
              relatedProducts={productsInCategory.slice(0, 4)}
            />
          </Container>
        )}
        <Container>
          <RelatedCategories
            title="Andra kategorier"
            categoryIds={product.categories}
          />
        </Container>
      </style.ProductWrap>
    </Page>
  );
};
