import styled from "styled-components";
import { colors } from "../../assets/style/variables";

export const Wrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
background-color: #fff;
padding: 20px 32px;
min-height: 60px;
transition: background-color 0.2s ease;
border-bottom: 1px solid;
border-bottom-color: ${colors.lightgray};

:hover {
  background-color: ${colors.lightgray};
  transition: background-color 0.2s ease;
}
`

export const InnerWrapper = styled.div`
display: flex;
justify-content: space-between;
align-items: center;

svg {
  transform: rotate(180deg);

  @media screen and (max-width: 767px) {
    display: none;
  }
}
`

export const Labels = styled.div`
  width: 70%;
  display: flex;

  @media screen and (max-width: 991px) {
    width: 90%;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  @media screen and (max-width: 567px) {
    flex-wrap: wrap;
  }

  div {
    width: 33.333%;

    @media screen and (max-width: 567px) {
      width: 50%;
    }

    + div + div {
      @media screen and (max-width: 567px) {
        width: 100%;
      }
    }
  }
`
