import React from "react";

import { Heading, Spinner, Container } from "../../../components";
import * as style from "./Orders.style";
import { Order } from "../../../types/Order";
import { OrdersTable } from "./OrdersTable";

interface IOrdersContentProps {
  orders: Order[];
  loading: boolean;
}
export const OrdersContent = ({ orders, loading }: IOrdersContentProps) => {
  if (loading) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }
  return (
    <div id="orders">
      <style.Header>
        <Heading size={3} title="Orderhistorik" />
      </style.Header>
      {orders?.length ? (
        <OrdersTable baseUrl="/foretag/bestallning/" orders={orders} />
      ) : (
        <p>Det finns inga registrerade ordrar ännu.</p>
      )}
    </div>
  );
};
