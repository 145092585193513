import React from "react";
import PropTypes from "prop-types";

import * as style from "./ButtonLink.style";

export interface IButtonLinkProps {
  rounded?: boolean;
  label: string;
  path: string;
  size?: string;
}
export const ButtonLink = ({
  rounded,
  label,
  path,
  size,
}: IButtonLinkProps) => (
  <style.ButtonLink
    rounded={rounded ?? false}
    size={size ?? "medium"}
    to={path}
  >
    {label}
  </style.ButtonLink>
);
