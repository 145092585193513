import styled from "styled-components";

import { Link } from "react-router-dom";

export const Header = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-between;
margin-bottom: 32px;
margin-top: 36px;
`

export const ProductItems = styled.div`
display: flex;
flex-wrap: wrap;
margin: 0 -15px;
padding-bottom: 32px;

@media screen and (max-width: 768px) {
  padding-bottom: 0;
}

@media screen and (max-width: 568px) {
  margin: 0;
  padding-bottom: 0;
}
`

export const LinkItem = styled(Link)`
display: flex;
align-items: center;

svg {
  margin-left: 10px;
}
`
