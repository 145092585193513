import React, { useContext } from "react";
import { CartSummaryContent } from "./CartSummary.content";
import { CartContext } from "../../providers/CartProvider";
import { AppContext } from "../../AppProvider";
import { UserContext } from "../../providers/UserProvider";

export const CartSummary = () => {
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);

  const products = appContext?.products ?? []; //useSelector(selectProducts);
  const cart = userContext?.cart; //useSelector(selectCart);
  const totals = cart?.totals; //useSelector(selectTotals);

  const cartLoading = userContext?.isCartLoading ?? true; // useSelector(selectCartLoading);
  const monthlyTotal =
    cart?.items
      .filter((x) => x.cart_item_data?.subscription === true)
      .reduce((acc, curr) => acc + curr.totals.total, 0) ?? 0;
  const cartTotal = totals ? Number(totals.total) - monthlyTotal : 0;
  const removeItem = (id: string) => userContext?.removeFromCart?.(id);
  return (
    <CartSummaryContent
      removeItem={removeItem}
      products={products}
      cart={cart}
      shippingTotal={(totals?.shipping_total ?? 0) / 100}
      monthTotal={monthlyTotal / 100}
      cartTotal={cartTotal / 100}
      loading={cartLoading}
    />
  );
};
