import { Billing, PaymentMethod } from "../../../types/Order";

interface IBillingDetailsProps {
  billing: Billing;
  paymentMethod: PaymentMethod;
}
export const BillingDetails = ({
  billing,
  paymentMethod,
}: IBillingDetailsProps) => {
  return (
    <ul>
      {paymentMethod === "Invoice to billing email" ? (
        <li>{billing.email}</li>
      ) : (
        <>
          <li>{billing.company}</li>
          <li>
            {billing.first_name} {billing.last_name}
          </li>
          <li>{billing.address_1}</li>
          <li>{billing.address_2}</li>
          <li>
            {billing.postcode}, {billing.city}
          </li>
          <li>{billing.email}</li>
          <li>{billing.phone}</li>
        </>
      )}
    </ul>
  );
};
