import React, { useContext, useState } from "react";

import {
  formatCost,
  onImageErrorHandler,
  formatVariationAttributes,
} from "../../utils";

import { CartItemOptions } from "../../types/Cart";
import { TextHiddenXSmall } from "../Styles/TextStyles";
import { Trash } from "../../assets/icons";
import * as style from "./CartItem.style";
import { CartItemOption } from "./CartItemOption";
import { AppContext } from "../../AppProvider";
interface Props {
  image?: string;
  title: string;
  onClick?: Function;
  subscription?: boolean;
  selectedOptions?: CartItemOptions;
  price: string;
  quantity?: number;
  variationId: number;
  productId: number;
}
export const CartItem: React.FC<Props> = ({
  image,
  title,
  onClick,
  subscription,
  selectedOptions,
  price,
  quantity,
  variationId,
  productId,
}) => {
  const [loading, setLoading] = useState(false);
  const context = useContext(AppContext);
  const products = context?.products;
  let variation = null;
  if (productId && variationId !== 0) {
    const product = products?.find((prod: any) => prod.id === productId);
    if (product) {
      variation = product.variations.find(
        (variant: any) => variant.variation_id === variationId
      );
    }
  }
  const handleOnClick = () => {
    if (!loading) {
      setLoading(true);
      onClick && onClick();
    }
  };
  return (
    <style.Wrapper $loading={loading}>
      {image && (
        <style.ImageWrap>
          <img
            src={image}
            alt={title}
            onError={onImageErrorHandler(
              "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8+B8AAucB8kTMRj8AAAAASUVORK5CYII="
            )}
          />
        </style.ImageWrap>
      )}
      <style.Content>
        <style.Header>
          <strong>{title}</strong>
          {onClick && (
            <button onClick={handleOnClick}>
              {
                // Nice UI but for now we can't handle multiple deletions at the
                // same time => we disable entire page instead of the item.
                /* {loading ? <Spinner /> : <Trash />} */
              }
              <Trash />
            </button>
          )}
        </style.Header>
        <style.Details>
          {variation && (
            <div>
              <p>
                {formatVariationAttributes(variation.attributes).join(", ")}
              </p>
            </div>
          )}
          {selectedOptions && (
            <div>
              <CartItemOption options={selectedOptions} />
            </div>
          )}
          <style.DetailsPrice>
            {quantity && quantity > 1 ? (
              <p>Antal: {quantity} st</p>
            ) : subscription ? (
              <TextHiddenXSmall>
                <p>Månadsavgift</p>
              </TextHiddenXSmall>
            ) : (
              <TextHiddenXSmall>
                <p>Pris</p>
              </TextHiddenXSmall>
            )}
            <p>
              {formatCost(price)} kr{subscription && "/mån"}
            </p>
          </style.DetailsPrice>
        </style.Details>
      </style.Content>
    </style.Wrapper>
  );
};
