import { useContext, useEffect, useState } from "react";

import { ButtonSizes, ButtonTypes } from "../../../types/Enums";
import { Fields } from "../../Login/Login.style";

import { Cross } from "../../../assets/icons";
import { IAddUser, User, UserRoles } from "../../../types/User";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { UserContext, UserProvider } from "../../../providers/UserProvider";
import { UserApi } from "../../../api/user";

interface IAddUserModalProps {
  handleClose: () => void;
  userAdded: (user: User) => void;
  userUpdated: (user: User) => void;
  user?: User;
  modalRef: any;
}
export const AddUserModal = ({
  handleClose,
  modalRef,
  userAdded,
  userUpdated,
  user,
}: IAddUserModalProps) => {
  const defaultUser = {
    id: user?.id ?? -1,
    username: "",
    password: "",
    firstname: "",
    lastname: "",
    role: "company_level_1",
  };
  const [userState, setUserState] = useState<IAddUser>({ ...defaultUser });
  const userContext = useContext(UserContext);
  useEffect(() => {
    if (user) {
      console.log(user);
      setUserState({
        id: user.id,
        firstname: user.firstName,
        lastname: user.lastName,
        username: user.loginName,
        password: "",
        role: user.roles.find((x) => x.startsWith("company_level_")) ?? "",
      });
    } else {
      setUserState({ ...defaultUser });
    }
  }, [user]);

  const addUser = async () => {
    if (user) {
      // Update

      const updatedUser = await UserApi.update(
        userState.id!!,
        userState.firstname,
        userState.lastname,
        userState.username,
        userState.role + " " + userContext?.user?.company?.role
      );
      if (updatedUser) {
        userUpdated(updatedUser);
      }
    } else {
      const user = await UserApi.add(
        userState.firstname,
        userState.lastname,
        userState.username,
        userState.password,
        userState.role + " " + userContext?.user?.company?.role
      );
      if (user) {
        userAdded(user);
      }
    }
    /*
    if (user) {
      let roles = [];

      const updatedUser = await callUpdateUser(
        user.id,
        firstname,
        lastname,
        role
      );
      if (updatedUser) {
        console.log(updatedUser);
        userUpdated(updatedUser);
      }
    }
    if (email && password) {
      const user = await callAddUser(
        firstname,
        lastname,
        email,
        password,
        role
      );
      if (user) {
        userAdded(user);
      }
    }
    */
    console.log(userContext?.user);
    console.log(userState);
  };

  const onChangeRole = (e: any) => {
    setUserState({ ...userState, role: e.target.value });
  };

  return (
    <Dialog open={true}>
      <DialogTitle>Lägg till/redigera användare</DialogTitle>
      <DialogContent>
        <Box>
          <TextField
            label="Förnamn"
            variant="outlined"
            value={userState?.firstname}
            onChange={(ev) =>
              setUserState({ ...userState, firstname: ev.target.value })
            }
          />
          <TextField
            label="Efternamn"
            variant="outlined"
            value={userState?.lastname}
            onChange={(ev) =>
              setUserState({ ...userState, lastname: ev.target.value })
            }
          />
          <TextField
            label="Email"
            variant="outlined"
            value={userState?.username}
            onChange={(ev) =>
              setUserState({ ...userState, username: ev.target.value })
            }
          />
          <TextField
            label="Lösenord"
            variant="outlined"
            value={userState?.password}
            onChange={(ev) =>
              setUserState({ ...userState, password: ev.target.value })
            }
          />
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Roll</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={userState.role}
              onChange={(e) =>
                setUserState({ ...userState, role: e.target.value })
              }
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="company_level_1"
                control={<Radio />}
                label="Kund"
              />
              <FormControlLabel
                value="company_level_2"
                control={<Radio />}
                label="Företagsadmin"
              />
              <FormControlLabel
                value="company_level_3"
                control={<Radio />}
                label="Administratör"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Avbryt</Button>
        <Button variant="contained" onClick={addUser}>
          Spara
        </Button>
      </DialogActions>
    </Dialog>
  ); /*
          <Modal
          handleClose={() => handleClose()}
          node={modalRef}
          customHeader={
            <div>
              Lägg till användare
              <div onClick={() => handleClose()}>
                <Cross stroke="#000" />
              </div>
            </div>
          }
        >
      <form onSubmit={addUser}>
        <Input
          placeholder="Förnamn"
          value={firstname ?? ""}
          onChange={(ev) => setFirstname(ev.currentTarget.value)}
        />
        <Input
          placeholder="Efternamn"
          value={lastname ?? ""}
          onChange={(ev) => setLastname(ev.currentTarget.value)}
        />
        <Fields>
          <Input
            size="small"
            type="email"
            name="email"
            placeholder="email"
            value={email ?? ""}
            disabled={user !== undefined}
            onChange={(ev) => setEmail(ev.currentTarget.value)}
          />
          <Input
            size="small"
            type="password"
            name="password"
            placeholder="Lösenord"
            value={password ?? ""}
            disabled={user !== undefined}
            onChange={(ev) => setPassword(ev.currentTarget.value)}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            {UserRoles.map((item: any) => {
              return (
                <label>
                  <input
                    type="radio"
                    name="role"
                    value={item.value}
                    checked={role === item.value}
                    className="form-check-input"
                    onChange={onChangeRole}
                  ></input>
                  {item.title}
                </label>
              );
            })}
          </div>

          <Button
            type={ButtonTypes.PRIMARY}
            rounded
            fullWidth
            margins
            size={ButtonSizes.SMALL}
            label="Spara"
            onClick={() => addUser()}
            loading={false}
          />
        </Fields>
      </form>
    </Modal>
  );*/
};
