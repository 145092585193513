import styled from "styled-components";

export const Header = styled.div`
margin-bottom: 32px;
`

export const Card = styled.div`
background-color: #fff;
padding: 32px;
`

export const Content = styled.div`
width: 100%;
padding: 0 32px 32px 32px;
margin-bottom: 32px;
`

export const Flex = styled.div`
display: flex;
justify-content: space-between;
margin: 0 -32px;

@media screen and (max-width: 767px) {
  display: block;
}
`

export const Column = styled.div`
margin: 0 32px;

@media screen and (min-width: 768px) {
  width: 50%;
}
`

export const CostCenters = styled.div`
  margin-bottom: 20px;
  color: #000;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;

  label {
    display: block;
  }

  input {
    margin-top: 12px;
    width: 100%;
    color: #000;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const Select = styled.div`
margin: 22px 0 20px;
padding: 0;
width: 100%;
display: block;
font-size: 14px;

@media screen and (max-width: 468px) {
  width: 100%;
  padding: 0;
}

select {
  width: 100%;
}

label {
  position: relative;
  strong {
    margin-bottom: 20px;
    display: inline-block;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    right: 20px;
    bottom: 0;
    width: 12px;
    height: 19px;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='19' viewBox='0 0 12 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L10 9.5L1 18' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
    background-size: 100% 100%;
    transform: rotate(90deg);
  }
}
`
