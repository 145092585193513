import React, { useState } from "react";
import * as style from "./AddressCard.style";
import AddressModal from "./AddressModal/AddressModal";
import { IAddress } from "../../utils";
interface IAddressCardProps {
  heading?: string;
  addresses: IAddress[];
  selectedId: string;
  modalSubmitLabel?: string;
  onSave?: (id: string) => void;
  onSaveAsync?: (id: string) => Promise<any>;
}
export const AddressCard = ({
  heading,
  addresses,
  selectedId,
  modalSubmitLabel,
  onSave,
  onSaveAsync,
}: IAddressCardProps) => {
  const [showModal, setShowModal] = useState(false);
  const selectedAddress =
    addresses && addresses.length > 0
      ? addresses.find((adr) => adr.id === selectedId)
      : undefined;
  const shouldHaveModal = Boolean(
    addresses.length > 1 && (onSave || onSaveAsync)
  );
  if (selectedAddress) {
    return (
      <>
        <style.Card noHeading={!heading}>
          {heading && <h4>{heading}</h4>}
          <ul>
            <li>{selectedAddress.company}</li>
            <li>{selectedAddress.recipient}</li>
            <li>{selectedAddress.address}</li>
            <li>
              {selectedAddress.post_code}, {selectedAddress.city}
            </li>
          </ul>
          {shouldHaveModal && (
            <style.ChangeAddressButton
              tabIndex={0}
              onClick={() => setShowModal(true)}
            >
              Byt adress
            </style.ChangeAddressButton>
          )}
        </style.Card>
        {showModal && (
          <AddressModal
            onSave={onSave}
            onSaveAsync={onSaveAsync}
            addresses={addresses}
            close={() => setShowModal(false)}
            selectedId={selectedId}
            submitLabel={modalSubmitLabel}
          />
        )}
      </>
    );
  }
  return <strong>Adress saknas</strong>;
};
