import React, { useContext, useEffect, useState } from "react";
import {
  formatCost,
  mapOrderStatus,
  formatOrderDate,
  mapPaymentMethod,
  isSubscriptionOption,
  mapLineItemAttributes,
} from "../../../utils";
import { CartEnd, Heading, CartItem } from "../../../components";
import { Arrow } from "../../../assets/icons";

import * as style from "./OrderSummary.style";
import { Order } from "../../../types/Order";
import { UserContext } from "../../../providers/UserProvider";
import { User } from "../../../types/User";
import { Button } from "@mui/material";
import { ShippingDetails } from "./ShippingDetails";
import { BillingDetails } from "./BillingDetails";
import { OrderApi } from "../../../api/order";
import { LoadingButton } from "@mui/lab";
interface IOrderSummaryContentProps {
  order: Order;
}
export const OrderSummaryContent = ({ order }: IOrderSummaryContentProps) => {
  const context = useContext(UserContext);
  const [orderedBy, setOrderedBy] = useState<User | undefined>();
  const [approving, setApproving] = useState<boolean>(false);
  const onApprove = async () => {
    setApproving(true);
    try {
      const result = await OrderApi.attest(order.orderData.id);
      context?.updateOrder?.(result);
    } catch {
      console.error("Failed to approve");
    }
    setApproving(false);
  };
  useEffect(() => {
    document.body.classList.add("is-bg");
    return () => {
      document.body.classList.remove("is-bg");
    };
  });
  useEffect(() => {
    if (context?.user?.company?.users) {
      setOrderedBy(
        context.user.company.users.find(
          (x) => x.id === order.orderData.customer_id
        )
      );
    }
  }, [order]);
  const monthlyTotal = order.lineItems
    .filter((item) =>
      item.attributes.some((attribute) => attribute.key === "numberOption")
    )
    .reduce((acc, curr) => acc + Number(curr.lineTotal), 0);

  const total = Number(order.orderData.total) - monthlyTotal;

  return (
    <React.Fragment>
      <style.IconLink to="/foretag/bestallning">
        <Arrow />
        <Heading size={4} title="Orderhistorik" />
      </style.IconLink>

      <style.Card spacing={false}>
        <style.Container>
          <style.Header>
            <Heading size={3} title={`Ordernr. #${order.orderData.id}`} />
            <p>
              {order.lineItems.length}{" "}
              {order.lineItems.length === 1 ? "produkt" : "produkter"}
            </p>
          </style.Header>

          {orderedBy && (
            <style.Details>
              Beställare {orderedBy.displayName}
              <LoadingButton
                loading={approving}
                variant="contained"
                onClick={() => onApprove()}
              >
                Attestera
              </LoadingButton>
            </style.Details>
          )}
          <style.Details>
            <div>
              <strong>Beställningsdatum</strong>
              <p>{formatOrderDate(order.orderData.date_created.date)}</p>
            </div>

            <style.Status>
              <strong>Status</strong>
              <p>{mapOrderStatus(order.orderData.status)}</p>
            </style.Status>
          </style.Details>
        </style.Container>
      </style.Card>

      <style.Cart>
        <style.Container>
          {order.lineItems.map((item) => {
            const options =
              item.attributes && item.attributes.length > 0
                ? mapLineItemAttributes(item.attributes)
                : undefined;
            return (
              <CartItem
                key={item.itemId}
                title={item.itemTitle}
                price={item.lineTotal}
                productId={item.productId}
                variationId={item.variationId}
                selectedOptions={options}
                subscription={isSubscriptionOption(options)}
              />
            );
          })}
        </style.Container>

        <CartEnd
          cartTotal={formatCost(total)}
          monthlyCost={monthlyTotal}
          shippingCost={order.orderData.shipping_total}
        />
      </style.Cart>

      <style.Card spacing={true}>
        <style.Container>
          <style.Header>
            <Heading size={3} title="Leverans" />
          </style.Header>

          <Heading size={4} spacing={8} title="Leveransadress" />
          <ShippingDetails shipping={order.orderData.shipping} />
        </style.Container>
      </style.Card>

      <style.Card spacing={false}>
        <style.Container>
          <style.Header>
            <Heading size={3} title="Betalsätt" />
            <p>{mapPaymentMethod(order.orderData.payment_method)}</p>
          </style.Header>
          <Heading size={4} spacing={8} title="Fakturaadress" />
          {order.orderData.invoice_pdf && (
            <a href={order.orderData.invoice_pdf}>Se faktura</a>
          )}
          <BillingDetails
            paymentMethod={order.orderData.payment_method}
            billing={order.orderData.billing}
          />
        </style.Container>
      </style.Card>
    </React.Fragment>
  );
};
