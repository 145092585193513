import styled from "styled-components";

export const Wrapper = styled.div`
display: flex;
flex-wrap: wrap-reverse;
margin: 0 -32px;
padding: 64px 0;

@media screen and (max-width: 768px) {
  padding: 32px 0;
}
`

export const Content = styled.div`
width: 50%;
padding: 0 32px;

@media screen and (max-width: 768px) {
  width: 100%;
}
`

export const Title = styled.div`
margin: 20px 0;
`

export const Image = styled.div`
position: relative;
overflow: hidden;
max-height: 350px;
width: 50%;
padding: 0 32px;

img {
  width: 100%;
    object-fit: cover;
    height: 100%;
}

@media screen and (max-width: 768px) {
  width: 100%;
  margin-bottom: 32px;
  max-height: 250px;
}
`
