import React from "react";

import { BlockTypes, Block } from "../types/Page";

import { ParagraphBlock } from "./Paragraph/ParagraphBlock";
import { ITextBlockProps, TextBlock } from "./TextBlock/TextBlock";
import { HeadingBlock, IHeadingBlockProps } from "./HeadingBlock/HeadingBlock";
import { BannerBlock, IBannerBlockProps } from "./BannerBlock/BannerBlock";
import {
  ITextImageBlockProps,
  TextImageBlock,
} from "./TextImageBlock/TextImageBlock";
import {
  HighlightedProductsBlock,
  IHighlightedProductsBlock,
} from "./HighlightedProductsBlock/HighlightedProductsBlock";
import { CategoryBlock } from "./CategoryBlock/CategoryBlock";

interface IProps extends Block {}
export const GutenbergBlock = (props: IProps) => {
  switch (props.name) {
    case BlockTypes.ParagraphBlock:
      return <ParagraphBlock {...props.data} />;
    case BlockTypes.TextBlock:
      const textBlockProps = props.data as ITextBlockProps;
      return <TextBlock {...textBlockProps} />;
    case BlockTypes.HeadingBlock:
      const headingBlockProps = props.data as IHeadingBlockProps;
      return <HeadingBlock {...headingBlockProps} />;
    case BlockTypes.BannerBlock:
      const bannerBlockProps = props.data as IBannerBlockProps;
      return <BannerBlock {...bannerBlockProps} />;
    case BlockTypes.TextImageBlock:
      const textImageBlockProps = props.data as ITextImageBlockProps;
      return <TextImageBlock {...textImageBlockProps} />;
    case BlockTypes.HighlightedProductsBlock:
      const highlightedProductsBlockProps =
        props.data as IHighlightedProductsBlock;
      return <HighlightedProductsBlock {...highlightedProductsBlockProps} />;
    case BlockTypes.CategoryBlock:
      const { categories, ...rest } = props.data;
      return (
        <CategoryBlock
          // For some reason wordpress refuses to give us an array
          categories={categories}
          {...rest}
        />
      );
    default:
      return (
        <div>
          <h1>Unkown block </h1>
          <pre
            style={{
              whiteSpace: "pre-wrap",
            }}
          >
            {JSON.stringify(props)}
          </pre>
        </div>
      );
  }
};
