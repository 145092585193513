import React, { useContext } from "react";

import { SubcriptionsContent } from "./Subscriptions.content";
import { AppContext } from "../../../AppProvider";
import { UserContext } from "../../../providers/UserProvider";

export const Subscriptions = () => {
  const context = useContext(UserContext);

  return (
    <SubcriptionsContent
      subscriptions={context?.subscriptions ?? []}
      loading={false}
    />
  );
};
