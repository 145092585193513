import styled from "styled-components";

export const ProductWrap = styled.div`
  margin-top: 32px;
`;

export const Alert = styled.div`
  position: fixed;
  right: 0;
  bottom: 32px;
  width: 350px;
  z-index: 1002;
`

export const Label = styled.label`
  display: grid;
  margin-bottom: 20px;
`
