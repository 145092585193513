import React, { useEffect, useRef } from "react";
import { SearchIcon } from "../Icons/SearchIcon";
import * as style from "./Searchbar.style";

type TSearchbar = {
  name: string;
  placeholder: string;
  value: string;
  onChange: (e: any) => void;
  onSubmit: () => void;
};

export const Searchbar = ({
  name,
  placeholder,
  value,
  onChange,
  onSubmit,
}: TSearchbar) => {
  const searchRef = useRef<HTMLInputElement>();
  useEffect(() => {
    searchRef?.current?.focus();
  }, []);
  const onKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === "Enter") {
      ev.preventDefault();
      onSubmit();
    }
  };
  return (
    <style.InputContainer>
      <style.Input
        ref={searchRef}
        type={"text"}
        name={name}
        value={value}
        onChange={onChange}
        onKeyDown={(ev: React.KeyboardEvent<HTMLInputElement>) => onKeyDown(ev)}
        placeholder={placeholder}
        required
      />
      <style.IconHolder onClick={() => onSubmit()}>
        <SearchIcon color={"#255958"} />
      </style.IconHolder>
    </style.InputContainer>
  );
};
