import axios from "axios";
import { defaultHeaders } from ".";
import { ICart } from "../types/Cart";

const baseUrl = '/wp-json/cocart/v2/cart';

export class CartApi {

  public static async add(item:Item):Promise<ICart> {
    const response = await axios.post<ICart>(`${baseUrl}/add-item`,item,{headers:defaultHeaders() as any})
    return response.data;
  }

  public static async remove(item_key:string):Promise<ICart> {
    const response = await axios.delete<ICart>(`${baseUrl}/item/${item_key}?return_cart=true&thumb=true`,{headers:defaultHeaders() as any})
    return response.data;
  }

  public static async get():Promise<ICart> {
    const response = await axios.get<ICart>(`${baseUrl}?thumb=true`,{headers:defaultHeaders() as any});
    return response.data;
  }

  public static async clear():Promise<ICart> {
    const response = await axios.post<ICart>(`${baseUrl}/clear`,{headers:defaultHeaders() as any})
    return response.data
  }
}

/**
* Add to cart
*
* @param {Object} item Item to add
*/
interface Item {
    id: string
    quantity: string
  }
