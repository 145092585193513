import styled from "styled-components";
import { colors } from "../../assets/style/variables";

import { Link } from "react-router-dom";

export const Item = styled(Link)`
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 15px;
  margin-bottom: 32px;

  @media screen and (max-width: 991px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media screen and (max-width: 567px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media screen and (max-width: 350px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
  }
`;

export const Image = styled.div`
  position: relative;
  height: 0;
  width: 100%;
  background-color: ${colors.lightgray};
  margin-bottom: 20px;
  padding-bottom: 115%;

  div {
    position: absolute;
    top: 50%;
    left: 10%;
    width: 80%;
    transform: translateY(-50%);
    text-align: center;
  }

  img {
    min-width: 0;
    max-width: 100%;
    height: auto;
    margin: auto;
  }
`;
