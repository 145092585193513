import { Block } from "../../types/Page";

interface IParagraphBlockStyle {
  color: {
    text?: string;
    background?: string;
  };
}
export interface IParagraphBlockProps extends Block {
  content: string;
  style?: IParagraphBlockStyle;
  fontSize?: string;
}
const getStyle = (style: IParagraphBlockStyle): React.CSSProperties => {
  let returnStyle: React.CSSProperties = {};
  if (style?.color?.text) {
    returnStyle.color = style.color.text;
  }
  if (style?.color?.background) {
    returnStyle.backgroundColor = style.color.background;
    returnStyle.padding = "1.25em 2.375em";
  }
  return returnStyle;
};

const getClassName = (fontSize?: string): string => {
  let cls = [];
  if (fontSize) {
    cls.push("has-" + fontSize + "-font-size");
  }
  return cls.join();
};

export const ParagraphBlock = ({
  content,
  style,
  fontSize,
}: IParagraphBlockProps) => {
  return (
    <p
      className={getClassName(fontSize)}
      style={style ? getStyle(style) : {}}
      dangerouslySetInnerHTML={{ __html: content }}
    ></p>
  );
};
