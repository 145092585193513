import { Product, ProductVariationAttributes } from "../types/Product";
import { ProductCategory } from "../types/Category";
import { IBreadcrumbs } from "../types/Page";
import { sanitizePath } from "./sanitizers";
/**
 * Retrive product by id.
 * @param {object} products All products in state.
 * @param {number} productId ID of the product you want to retrive.
 * @return {object} Result is an object that matches the ID provided.
 */
export const getProductById = (
  products: Array<Product>,
  productId: number,
): Product | undefined =>
  products.find(({ id }) => id === productId);
/**
 * Retrive product by id.
 * @param {object} products All products in state.
 * @param {number} productId ID of the product you want to retrive.
 * @return {object} Result is an object that matches the ID provided.
 */
export const getProductByVariantId = (
  products: Array<Product>,
  variantId: number): Product | undefined => {
  const match = products.find(({ variations }) =>
    variations.some(v => v.variation_id === variantId));
  return match;
}
export const findProductsByCategory = (
  products: Array<Product>,
  categories: Array<ProductCategory>,
  category?: ProductCategory
): Array<Product> => {
  if (products === undefined || categories === undefined) {
    return [];
  }
  if (!category) {
    return products;
  }
  return products.filter(product =>
    product.categories.some(productCategory =>
      isCategoryAncestor(category.id, productCategory, categories)
    )
  );
};
const isCategoryAncestor = (
  target: number,
  current: number,
  categories: Array<ProductCategory>,
): boolean => {
  if (target === current) {
    return true;
  }
  const parentId = categories.find(cat => cat.id === current)?.parent;
  if (parentId) {
    return isCategoryAncestor(target, parentId, categories);
  }
  return false;
};
export const getChildCategories = (
  categories: Array<ProductCategory>,
  category?: ProductCategory,
): Array<ProductCategory> => {
  if (category) {
    return categories.filter(cat => cat.parent === category.id);
  }
  return getRootCategories(categories);
};
/**
 * Find related categories given the category.
 *
 * @param categories Haystack
 * @param category Needle
 */
export const findRelatedCategories = (
  categories: Array<ProductCategory>,
  needles: Array<ProductCategory>,
): Array<ProductCategory> | undefined => {
  if (!categories || !needles) {
    return;
  }
  let relatedCategories: Array<ProductCategory> = [];
  needles.forEach(category => {
    const parent = getParentCategory(categories, category);
    if (parent) {
      const children = getChildCategories(categories, parent);
      if (children) {
        relatedCategories = relatedCategories.concat(children);
      }
    }
  });
  return filterDuplicatesAndNeedles(relatedCategories, 'id', needles);
};
/**
 * Gets all root categories (categories without a parent category)
 *
 * @param categories Categories to find root categories in
 */
export const getRootCategories = (
  categories: Array<ProductCategory>
): Array<ProductCategory> =>
  categories.filter(cat => !cat.parent);
/**
 * Find related products matching the same category.
 * @param {object} object All products.
 * @param {object} object Category ID that you want to filter by.
 * @return {object} Result is all products in your chosen category.
 */
export const findRelatedProducts = (
  products: Array<Product>,
  categories: Array<ProductCategory>,
  product: Product,
): Product[] | undefined => {
  if (
    !products ||
    !categories ||
    !product ||
    product.categories.length <= 0
  ) {
    return;
  }
  let currentCategory: ProductCategory | undefined = categories.find(
    cat => cat.id === product.categories[0]
  );
  let relatedProducts: Array<Product> = [];
  while (
    relatedProducts.length <= 4 &&
    (currentCategory)
  ) {
    const prods = findProductsByCategory(
      products,
      categories,
      currentCategory,
    );
    if (prods) {
      relatedProducts = relatedProducts.concat(prods);
    }
    if (currentCategory) {
      currentCategory = getParentCategory(categories, currentCategory);
    }
  }
  return filterDuplicatesAndNeedles(relatedProducts, 'id', [product]);
};
export const getProductUrl = (product: Product, productBaseSlug: string): string => {
  return sanitizePath(`/${productBaseSlug}/${product.slug}`);
};
export const getParentCategory = (
  categories: Array<ProductCategory>,
  category: ProductCategory,
): ProductCategory | undefined => {
  if (category.parent) {
    return categories.find(cat => cat.id === category.parent);
  }
  return;
};
export const makeCategorySlug = (
  category: ProductCategory,
  categories: Array<ProductCategory>,
  categoryBaseSlug: string,
): string => {
  let categoryTree: Array<ProductCategory> = [category];
  let currentCategory: ProductCategory | undefined = category;
  while ((currentCategory = getParentCategory(categories, currentCategory))) {
    categoryTree.unshift(currentCategory);
  }
  const slug =
    `/${categoryBaseSlug}/${categoryTree.map(cat => `/${cat.slug}`).join('')}`;
  return sanitizePath(slug);
};
export const makeProductBreadcrumb = (
  categories: Array<ProductCategory>,
  product: Product,
  categoryBaseSlug: string,
): IBreadcrumbs => {
  const breadcrumbs: IBreadcrumbs = [];
  // Traverse category tree to get the entire chain
  if (product.categories.length > 0) {
    const masterCategoryId = product.categories[0];
    const masterCategory = categories.find(cat => cat.id === masterCategoryId);
    if (masterCategory) {
      let current: ProductCategory | undefined = masterCategory;
      while (current) {
        breadcrumbs.unshift({
          id: current.id,
          slug: makeCategorySlug(
            current,
            categories,
            categoryBaseSlug,
          ),
          label: current.name,
        });
        current = getParentCategory(categories, current);
      }
    }
    // Adds root since it's not a category
    breadcrumbs.unshift({
      id: -1,
      slug: sanitizePath(`/${categoryBaseSlug}`),
      label: 'Produkter',
    });
  }
  return breadcrumbs.slice(0, 3);
};

interface comparisonObject {
  [key: string]: any
}
/**
 * Will filter out any duplicated objects in the array. If given it will compare
 * non-primitives by the `key` instead of direct comparison. Will also remove
 * the `needle` completely from the array if it is provided.
 *
 * @param array Array to filter
 * @param key The key to use for comparison when dealing with non primitives
 * @param needle The needle to remove completely
 */
export const filterDuplicatesAndNeedles = (
  array: any[],
  key?: string,
  needles?: any[]
): any[] => {
  // Determine comparison method. Either object equality or key equality
  const equals = (
    item1: comparisonObject,
    item2: comparisonObject
  ): boolean =>
    key
      ? item1[key] === item2[key]
      : item1 === item2;
  // Loop through array pick only non-needles and non-duplicates
  return array.reduce((filtered, current) => {
    if (
      !(needles && needles.some((needle: any) => equals(current, needle))) &&
      !filtered.some((item: any) => equals(item, current))
    ) {
      filtered.push(current);
    }
    return filtered;
  }, []);
}
export const formatVariationAttributes = (
  attributes: ProductVariationAttributes,
): any => {
  return Object.entries(attributes).map(([key, value]) => {
    if (key === 'attribute_color') {
      return value.replace(/#([0-9A-F]{8}|[0-9A-F]{6}|[0-9A-F]{3})/i, '').trim();
    }
    if (key === 'attribute_months') {
      return `${value} mån`;
    }
    return value;
  });
};
