import styled from "styled-components";
import { colors } from "../../assets/style/variables";

export const Wrapper = styled.div`
  position: absolute;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  background-color: #fff;
  color: #000;
  z-index: 302;
  top: 80px;
  right: 0;
  width: 280px;
  height: auto;
  border-radius: 4px;

  @media screen and (max-width: 767px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    z-index: 400;
    border-radius: 0;
    box-shadow: none;
  }

  :before,
  :after {
    bottom: 100%;
    right: 81px;
    border: solid transparent;
    content:  "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  :before {
    border-bottom-color: ${colors.lightgray};
    border-width: 8px;
    margin-left: -8px;
  }

  :after {
    border-bottom-color: ${colors.lightgray};
    border-width: 5px;
    margin-left: -5px;
  }

  ul {
    li {
      padding: 14px 20px;
      color: black;

      :hover {
        background-color: ${colors.gray};
      }
    }

    a {
      text-decoration: none;

      .active li {
        background-color: ${colors.gray};
      }
    }
  }
`

export const MobileLink = styled.span`
display:block;
@media (min-width: 768px){
  display:none;
}
`

export const Close = styled.div`
  position: absolute;
  top: 50%;
  right: 32px;
  width: 24px;
  height: 24px;
  margin-top: -12px;
  cursor: pointer;

  ::after,
  ::before {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -1px;
    width: 100%;
    height: 2px;
    background-color: #000;
  }

  &::after {
    transform: rotate(45deg);
  }

  &::before {
    transform: rotate(-45deg);
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const Header = styled.div`
  background-color: ${colors.lightgray};
  padding: 20px 64px 20px 20px;
  position: relative;
`

export const Bottom = styled.div`
  border-top: 1px solid;
  border-top-color: ${colors.lightgray};
  padding: 20px;
`
