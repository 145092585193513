import React, { useContext } from "react";
import { FooterContent } from "./Footer.content";
import { AppContext } from "../../AppProvider";
import { UserContext } from "../../providers/UserProvider";
export const Footer = () => {
  const context = useContext(AppContext);

  const isLoggedIn = !!useContext(UserContext)?.user; //useSelector(selectIsLoggedIn);
  const footerColumns = context?.settings?.footer_columns ?? []; //useSelector(selectFooterColumns);
  const footerText = context?.settings?.footer_text; //useSelector(selectFooterText);
  return (
    <FooterContent
      isLoggedIn={isLoggedIn}
      columns={footerColumns}
      footerText={footerText}
    />
  );
};
