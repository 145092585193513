import styled from "styled-components";
import { colors } from "../../assets/style/variables";

export const Wrapper = styled.div`
  position: relative;
  background-color: #fff;
  padding: 32px 52px;
`;
export const Header = styled.div`
  border-bottom: 1px solid;
  border-bottom-color: ${colors.lightgray};
  margin-bottom: 32px;
  padding-bottom: 32px;
`;
export const Bottom = styled.div`
  margin-bottom: 32px;
`;
