import { useEffect, useRef, useState } from "react";
import { Heading, Modal, Searchbar } from "../../components";
import { callSearch } from "../../api/search";
import { Link } from "react-router-dom";

import { Cross } from "../../assets/icons";
import * as style from "./SearchResultModal.style";

interface ISearchGroup {
  title: string;
  items: any[];
}
interface ISearchResultModalProps {
  handleClose: () => void;
}
export const SearchResultModal = ({ handleClose }: ISearchResultModalProps) => {
  const modalRef = useRef<HTMLDivElement>();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<any[]>();

  useEffect(() => {
    const search = async () => {
      setSearchResults(await callSearch(searchQuery ?? ""));
    };
    search();
  }, [searchQuery]);

  const handleOutsideClick = (e: any) => {
    if (modalRef?.current?.contains(e.target)) {
      return;
    }
    handleClose();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick, {
      passive: true,
    });
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  const groupSearch = (searchResult: any[]): ISearchGroup[] => {
    let searchRes: { [key: string]: any } = {};
    searchResult?.forEach((res) => {
      if (!searchRes[res.type]) {
        searchRes[res.type] = [];
      }
      searchRes[res.type].push(res);
    });
    return Object.entries(searchRes)?.map((searchGroup) => ({
      title: searchGroup[0],
      items: searchGroup[1],
    }));
  };

  return (
    <Modal
      handleClose={() => handleClose()}
      node={modalRef}
      submitLabel="Submit"
      minHeight="70vh"
      topPosition="113px"
      customHeader={
        <style.SearchModalHeader>
          <Searchbar
            value={searchQuery}
            onChange={(ev: any) => setSearchQuery(ev.target.value)}
            onSubmit={() => {}}
            name={"search"}
            placeholder={"sök"}
          />
          <style.CloseButton onClick={handleClose}>
            <Cross stroke="#000" />
          </style.CloseButton>
        </style.SearchModalHeader>
      }
    >
      {searchResults?.length ? (
        groupSearch(searchResults)?.map(({ title, items }) => (
          <>
            <style.SearchGroupTitle key={title}>
              {/* @TODO: maybe tranlate these? */}
              {title}
            </style.SearchGroupTitle>
            {items?.map((result: any) => {
              return (
                <div
                  style={{
                    padding: "1em 0",
                    borderBottom: "solid 1px #cecece",
                    overflow: "auto",
                  }}
                >
                  <Link to={result.url} onClick={() => handleClose()}>
                    <style.SearchItemTitle
                      paddingBottom={title === "page" ? 0 : 18}
                    >
                      {result.title}
                    </style.SearchItemTitle>

                    <div style={{ display: "flex", gap: "10px" }}>
                      <img style={{ maxHeight: "100px" }} src={result.image} />
                      <div
                        dangerouslySetInnerHTML={{ __html: result.excerpt }}
                      />
                    </div>
                  </Link>
                </div>
              );
            })}
          </>
        ))
      ) : (
        <div>Inga resultat</div>
      )}
    </Modal>
  );
};
