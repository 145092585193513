import React, { useContext } from "react";
import { Heading, ButtonLink, Container } from "../../components";
import * as style from "./BannerBlock.style";
import { Block, TextAlign, Image } from "../../types/Page";

import { getProductUrl } from "../../utils";
import { Product } from "../../types/Product";

import { AppContext } from "../../AppProvider";
import { UserContext } from "../../providers/UserProvider";

enum HeadlineSize {
  h1,
  h2,
}
export interface IBannerBlockProps extends Block {
  headline: string;
  headlineSize: HeadlineSize;
  image?: Image;
  product?: number;
  text?: string;
  textAlign?: TextAlign;
  imageUrl?: string;
}
export const BannerBlock: React.FC<IBannerBlockProps> = ({
  image,
  headline,
  text,
  product,
  textAlign,
  headlineSize,
  imageUrl,
}) => {
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const products = appContext?.products; //useSelector(selectProducts);
  const headingSize = headlineSize ? headlineSize.toString().substr(-1) : "2";
  const user = userContext?.user; //useSelector(selectUser);
  const baseProductSlug = appContext?.settings?.product_slug; //useSelector(selectProductSlug);
  const p: Product | undefined = products?.find(
    (prod: any) => prod.id === product
  );
  console.log("product ", p);
  return (
    <style.Wrapper image={image?.url}>
      <Container>
        <style.Flex align={textAlign}>
          <style.Content>
            <Heading title={headline} size={+headingSize} />
            {text && <p dangerouslySetInnerHTML={{ __html: text }} />}
            {p && user?.company && (
              <>
                <p>{p.price} kr</p>
                <style.Button>
                  <ButtonLink
                    rounded
                    size="large"
                    label="Köp nu"
                    path={getProductUrl(p, baseProductSlug || "")}
                  />
                </style.Button>
              </>
            )}
          </style.Content>
        </style.Flex>
      </Container>
    </style.Wrapper>
  );
};
