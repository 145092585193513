import React from "react";
import PropTypes from "prop-types";

import { Checkmark } from "../../assets/icons";

import * as style from "./Radio.style";

export interface IRadioProps {
  size?: string;
  onChange: (ev: any) => void;
  name: string;
  value: string;
  checked: boolean;
  label: string;
}
export const Radio = ({
  size,
  onChange,
  name,
  value,
  checked,
  label,
}: IRadioProps) => (
  <style.Label size={size} checked={checked}>
    {checked && <Checkmark stroke="#BE8567" />}
    {label}

    <input
      type="radio"
      onChange={onChange}
      name={name}
      value={value}
      checked={checked}
    />
  </style.Label>
);

export default Radio;
