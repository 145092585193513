import React from "react";
import { Helmet } from "react-helmet";

import { BrowserRouter } from "react-router-dom";
import { Nav, Footer, ScrollToTop, LoginWrap } from "./modules";
import { Notifications } from "./components";

// @TODO: is this needed?
// import history from "./store/history";

import Globalstyle from "./Globalstyle";
import { AppProvider } from "./AppProvider";
import { ThemeProvider, createTheme } from "@mui/material";
const darkTheme = createTheme({
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          padding: "20px 32px",
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          backgroundColor: "rgb(219, 219, 219)",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "rgb(37, 89, 88)",
    },
  },
  typography: {
    fontFamily: "Montserrat",
    fontSize: 16,
  },
  transitions: {
    // So we have `transition: none;` everywhere
    create: () => "none",
  },
});
export const App = () => {
  return (
    <>
      <Helmet>
        <title>Acordo</title>
        <meta name="description" content="" />
      </Helmet>

      <div id="App">
        <BrowserRouter>
          <ThemeProvider theme={darkTheme}>
            <ScrollToTop />
            <Globalstyle />
            <AppProvider>
              <Nav />
              <LoginWrap />
              <Footer />
            </AppProvider>

            <Notifications />
          </ThemeProvider>
        </BrowserRouter>
      </div>
    </>
  );
};
