import styled from "styled-components";

interface IWrapperProps {
  small:Boolean;
}
export const Wrapper = styled.div<IWrapperProps>`
display: flex;
width: ${props => props.small ? "100%" : "30%"};
flex-direction: column;
margin-top: 20px;

@media (max-width: 767px) {
  width: 100%;
}
`

export const Item = styled.div`
display: flex;
justify-content: space-between;
width: 100%;
margin-bottom: 10px;

:last-child {
  margin-bottom: 0;
}
`
