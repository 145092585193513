import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";

import * as style from "./Breadcrumb.style";
import { makeProductBreadcrumb } from "../../utils";
import { IBreadcrumbs } from "../../types/Page";
import { AppContext } from "../../AppProvider";

export const Breadcrumb = () => {
  const params = useParams<{ product_slug: string }>();
  const context = useContext(AppContext);
  const baseCategorySlug = context?.settings?.category_slug;
  const baseProductSlug = context?.settings?.product_slug;

  let crumbs: IBreadcrumbs = [];
  if (context?.products && baseCategorySlug && baseProductSlug) {
    const product = context?.products.find(
      (product: any) => product.slug === params.product_slug
    );
    if (product && context?.categories) {
      crumbs = makeProductBreadcrumb(
        context?.categories,
        product,
        baseCategorySlug
      );
    }
  }
  return (
    <style.Breadcrumb>
      <ul>
        {crumbs.map((crumb) => (
          <li key={crumb.id}>
            <Link to={crumb.slug}>{crumb.label}</Link>
          </li>
        ))}
      </ul>
    </style.Breadcrumb>
  );
};
