import styled from "styled-components";
import { colors } from "../../assets/style/variables";
import { ProductCategoryHeroColor } from "../../types/Category";
const determineTextColor = (color?: ProductCategoryHeroColor) => {
  switch (color) {
    case ProductCategoryHeroColor.White:
      return '#fff';
    case ProductCategoryHeroColor.Black:
      return '#000';
    default:
      return '#000';
  };
};
interface HeaderProps {
  image?: string,
  textColor?: ProductCategoryHeroColor,
};
export const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${colors.lightgray};
  background-image: ${({ image }: HeaderProps) => image ? `url(${image})` : ''};
  background-size: cover;
  background-repeat: no-repeat;
  color: ${({ textColor }: HeaderProps) => determineTextColor(textColor)};
  height: 500px;
  margin-bottom: 64px;
  z-index: 0;
  &:before {
    content: '';
    display: block;
    position: absolute;
    background-color: rgba(0,0,0,0.2);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`;
export const Container = styled.div`
  margin: auto;
  max-width: 1200px;
  width: 100%;
  padding: 0 32px;
  z-index: 1;
  @media screen and (max-width: 568px) {
    h1 {
      display: flex;
      justify-content: center;
    }
  }
`;
export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  flex-wrap: wrap;
`;
export const ProductItems = styled.div`
  display: flex;
  margin: 0 -15px 32px;
  flex-wrap: wrap;
  @media screen and (max-width: 568px) {
    margin-left: 0;
    margin-right: 0;
  }
`;
export const TextColums = styled.div`
  background-color: #f1f1f1;
  padding: 32px;
  @media screen and (min-width: 768px) {
    padding: 84px 116px;
  }
  >div {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -32px;
    @media screen and (min-width: 768px) {
      margin: 0 -84px;
    }
    >div {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      padding: 0 32px;
      @media screen and (min-width: 768px) {
        padding: 0 84px;
      }
      +div {
        @media screen and (max-width: 767px) {
          padding-top: 32px;
        }
      }
    }
  }
`;
export const FilterWrap = styled.div`
  display: flex;
  white-space: nowrap;
  padding-right: 32px;
`;
export const FilterSelect = styled.div`
  position: relative;
  margin-left: 1em;
  margin-top: -5px;
  select {
    appearance: none;
    display: block;
    border: 0;
    outline: 0;
    background: none;
    font-family: inherit;
    font-size: 1em;
    font-weight: 600;
    margin-top: 8px;
    margin-right: 16px;
  }
  :after,
  :before {
    content: '';
    display: block;
    width: 10px;
    height: 2px;
    background-color: #000;
    position: absolute;
    top: 50%;
    right: -13px;
  }
  :before {
    transform: rotate(-45deg) translate(2px);
    transform-origin: center right;
  }
  :after {
    transform: rotate(45deg) translate(-2px);
    transform-origin: center left;
  }
`;
