import React from "react";
import { Modal } from "../../../components";
import { Link } from "react-router-dom";
import * as style from "./AdditionalSalesModal.style";
interface Props {
  node: any; // TODO: Type me
  onCloseClick: EventListener;
  ctaItems: Array<any>;
}
const hideModal = () => {
  let site = document.getElementsByTagName("body")[0];
  site.classList.remove("modal-open");
};
export const AdditionalSalesModal: React.FC<Props> = ({
  node,
  onCloseClick,
  ctaItems,
}) => {
  return (
    <Modal
      node={node}
      title="Varan tillagd i varukorgen"
      description="Behöver ni något mer än vad ni precis beställde?"
      handleClose={onCloseClick}
    >
      <style.Wrapper>
        <style.InnerWrapper>
          {ctaItems &&
            ctaItems.map((item, i) => {
              return (
                <div key={i}>
                  <Link to={item.cta_link} onClick={() => hideModal()}>
                    {item.icon && (
                      <div>
                        <img src={item.icon} alt="" />
                      </div>
                    )}
                    <p>{item.label}</p>
                  </Link>
                </div>
              );
            })}
        </style.InnerWrapper>
      </style.Wrapper>
    </Modal>
  );
};
