import React from "react";
import { CartItemOptions } from "../../types/Cart";
import {
  isNewNumberSubscriptionOption,
  isKeepNumberSubscriptionOption,
  isPhoneOption,
} from "../../utils";

interface Props {
  options: CartItemOptions;
}

export const CartItemOption: React.FC<Props> = ({ options }) => {
  if (isNewNumberSubscriptionOption(options)) {
    return <p>{options.fullName}, nytt mobilnr.</p>;
  }
  if (isKeepNumberSubscriptionOption(options)) {
    return <p>{options.fullName}, behåller mobilnr.</p>;
  }
  if (isPhoneOption(options)) {
    return (
      <p>
        Användare: <strong>{options.fullName}</strong>
      </p>
    );
  }
  return null;
};
