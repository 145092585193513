import styled from "styled-components";
import { colors } from "../../assets/style/variables";
interface WrapperProps {
  $loading?: boolean,
};
export const Wrapper = styled.div`
  border-bottom: 1px solid;
  border-bottom-color: ${colors.lightgray};
  margin-bottom: 32px;
  padding-bottom: 32px;
  transition: opacity .2s;
  opacity: ${(props: WrapperProps) => props.$loading ? 0.5 : 1};
  display: flex;
  :last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`;
export const ImageWrap = styled.div`
  flex: 0 0 100px;
  width: 100px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 567px) {
    display: none;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;
export const Header = styled.div`
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  button {
    all: unset;
    cursor: pointer;
    text-align: right;
    width: 29px;
    height: 29px;
  }
`
export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const Details = styled.div`
  /* display: flex;
  justify-content: space-between;
  align-items: flex-end; */
  p {
    margin-bottom: 6px;
  }
`;
export const DetailsPrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
