import styled from "styled-components";

export const RadioGroup = styled.div`
display: flex;
justify-content: space-between;
margin: 0 -10px;
margin-bottom: 32px;

label {
  width: 50%;
  margin: 0 10px;
}

@media screen and (max-width: 468px) {
  margin: 0;
  flex-wrap: wrap;

  label {
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
  }
}
`
