import React, { useContext } from "react";
import { Product } from "../../types/Product";
import { Block } from "../../types/Page";
import { RelatedProducts, Container } from "../../modules";
import { AppContext } from "../../AppProvider";

export interface IHighlightedProductsBlock extends Block {
  headline: string;
  productId1?: number;
  productId2?: number;
  productId3?: number;
  productId4?: number;
  productId5?: number;
  products: Product[];
  category?: number;
  showAllCat?: number;
}
export const HighlightedProductsBlock: React.FC<IHighlightedProductsBlock> = ({
  headline,
  productId1,
  productId2,
  productId3,
  productId4,
  productId5,
  category,
  showAllCat,
}) => {
  const context = useContext(AppContext);
  const products: Product[] = context?.products ?? []; //useSelector(selectProducts);
  const categories = context?.categories ?? []; //useSelector(selectCategories);
  let relatedProducts = [
    productId1,
    productId2,
    productId3,
    productId4,
    productId5,
  ]
    .filter((p) => p)
    .map((p) => products.find((product) => product.id == p));

  return (
    <Container>
      <RelatedProducts
        relatedProducts={(relatedProducts as Product[]) ?? []}
        title={headline}
        category={categories.find((c: any) => c.id == showAllCat)}
      />
    </Container>
  );
};
