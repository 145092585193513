import { createContext, useEffect, useState } from "react";
import { callGetCategories, callGetProducts, callGetSettings } from "./api";

import { ISettingsState } from "./types/Settings";

import { Subscription } from "./types/Subscription";
import { Page } from "./types/Page";
import { Product } from "./types/Product";
import { Notification } from "./types/Notifications";
import { ProductCategory } from "./types/Category";
import { UserProvider } from "./providers/UserProvider";
import { Box, CircularProgress, Container } from "@mui/material";

export interface IAppContext {
  products: Product[];
  settings: ISettingsState;
  categories: any[];
  pages: Page[];
  notifications: Notification[];
  isLoading: boolean;
  removeNotification: (id: number) => void;
  setLoggedIn: (loggedIn: boolean) => void;
}

export const AppContext = createContext<Partial<IAppContext>>({});

export const AppProvider: React.FunctionComponent<any> = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [products, setProducts] = useState<any[]>([]);
  const [settings, setSettings] = useState<ISettingsState>();
  const [categories, setCategories] = useState<ProductCategory[]>([]);

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [user, setUser] = useState<any>(null);
  const [pages, setPages] = useState<any[]>([]);
  const init = async () => {
    setSettings(await callGetSettings());
  };
  useEffect(() => {
    init();
  }, []);

  const setLoggedIn = async (loggedIn: boolean) => {
    setLoading(true);
    setProducts(await callGetProducts());
    //setSettings(await callGetSettings());
    setCategories(await callGetCategories());

    setLoading(false);
  };

  let state: Partial<IAppContext> = {
    get isLoading() {
      return loading;
    },
    get products() {
      return products;
    },
    get settings() {
      return settings;
    },
    get categories() {
      return categories;
    },
    get pages() {
      return pages;
    },
    get notifications() {
      return notifications;
    },
    setLoggedIn: setLoggedIn,
  };
  return (
    <AppContext.Provider value={state}>
      <UserProvider>
        {loading ? (
          <div
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box>
              <CircularProgress />
            </Box>
          </div>
        ) : (
          <>{children}</>
        )}
      </UserProvider>
    </AppContext.Provider>
  );
};
