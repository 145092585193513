import styled from "styled-components";

export const Wrapper = styled.div`
font-size: 12px;
background-color: #000;
color: #fff;
height: 32px;
overflow-x: auto;
overflow-y: hidden;
white-space: nowrap;

ul {
  padding-left: 0;
  margin: 8px 0 0 0;

  li {
    display: inline-block;
    margin-right: 24px;

    svg {
      margin-right: 4px;
    }

    :first-of-type {
      padding-left: 0;
    }
  }
}
`

export const Container = styled.div`
  max-width: 1200px;
  margin: auto;
  width: 100%;
  padding: 0 32px;
  position: relative;
`
