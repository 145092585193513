import React from "react";

import { Heading, PriceTable } from "..";

import * as style from "./CartEnd.style";

interface ICartEndProps {
  small?: boolean;
  monthlyCost: number;
  shippingCost: string;
  cartTotal: string;
}
export const CartEnd = ({
  small,
  monthlyCost,
  shippingCost,
  cartTotal,
}: ICartEndProps) => {
  return (
    <style.Wrapper small={small ?? false}>
      {monthlyCost > 0 && (
        <>
          <Heading size={4} spacing={8} title="Abonnemang" />
          <style.Content>
            <p>Månadsavgiften debiteras separat av operatören.</p>
            <PriceTable
              small={small ?? false}
              costs={[{ type: "month", cost: monthlyCost }]}
            />
          </style.Content>

          <style.Break />

          <Heading size={4} spacing={8} title="Hårdvara" />
        </>
      )}

      <style.Content>
        <p></p>
        <PriceTable
          small={!!small}
          costs={[
            { type: "shipping", cost: shippingCost },
            { type: "total", cost: cartTotal },
          ]}
        />
      </style.Content>

      <style.Vat>(Priserna anges exklusive moms)</style.Vat>
    </style.Wrapper>
  );
};
