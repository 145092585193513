import React, { useContext, useRef } from "react";
import { UserModalContent } from "./UserModal.content";
import { UserContext } from "../../providers/UserProvider";
interface Props {
  handleClose: () => void;
}
export const UserModal: React.FC<Props> = ({ handleClose }) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const userContext = useContext(UserContext);
  const user = userContext?.user; //useSelector(selectUser);

  const onLogoutClick = (event: React.MouseEvent<HTMLElement>) => {
    userContext?.logOut?.();
  };

  const onModalCloseClick = (event: React.MouseEvent<HTMLElement>) => {
    handleClose();
  };

  return (
    <UserModalContent
      wrapperRef={wrapperRef}
      user={user}
      onLogoutClick={onLogoutClick}
      onModalCloseClick={onModalCloseClick}
    />
  );
};
