import React, { useRef, useEffect, useState } from "react";

import { ListItem } from "./AddressModal.style";
import { IAddress } from "../../../utils";
import { Modal } from "../..";

interface IAddressModalProps {
  addresses: IAddress[];
  selectedId: string;
  submitLabel?: string;
  onSave?: (id: string) => void;
  onSaveAsync?: (id: string) => Promise<any>;
  close: () => void;
}
const AddressModal = ({
  onSave,
  onSaveAsync,
  selectedId,
  addresses,
  close,
  submitLabel,
}: IAddressModalProps) => {
  const modalRef = useRef<HTMLDivElement>();
  const [selected, setSelected] = useState(selectedId);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // See how we can get rid of any here
    const handleOutsideModalClick = (event: any) => {
      if (
        modalRef?.current &&
        event?.target &&
        modalRef.current.contains(event.currentTarget)
      ) {
        return;
      }
      close();
    };

    document.addEventListener("click", handleOutsideModalClick);
    document.body.classList.add("modal-open");

    return () => {
      // cleanup
      document.removeEventListener("click", handleOutsideModalClick);
      document.body.classList.remove("modal-open");
    };
  }, [close]);

  const handleSubmit = () => {
    if (onSaveAsync) {
      setLoading(true);
      return onSaveAsync(selected)
        .then(() => close())
        .finally(() => setLoading(false));
    }
    close();
    return onSave?.(selected);
  };

  return (
    <Modal
      title="Välj adress"
      description=""
      handleClose={close}
      onSubmit={handleSubmit}
      node={modalRef}
      submitLabel={submitLabel}
      loading={loading}
    >
      {addresses.map((address) => (
        <ListItem
          key={address.id}
          tabIndex={0}
          onClick={() => setSelected(address.id)}
          isSelected={address.id === selected}
        >
          <ul>
            <li>{address.company}</li>
            <li>{address.recipient}</li>
            <li>{address.address}</li>
            <li>
              {address.city}, {address.post_code}
            </li>
          </ul>
        </ListItem>
      ))}
    </Modal>
  );
};

export default AddressModal;
