import React, { useContext, useState } from "react";

import { Link } from "react-router-dom";
import AnimateHeight from "react-animate-height";
import { sanitizePath } from "../../utils";
import { TopBar, UserModal } from "..";
import { WPLink } from "../../components";
import { Avatar, Cart } from "../../assets/icons";
import * as style from "./Nav.style";
import { NavigationItem } from "../../types/Settings";
import { SearchResultModal } from "../SearchResultModal/SearchResultModel";
import { SearchIcon } from "../../components/Icons/SearchIcon";
import { CartContext } from "../../providers/CartProvider";
import { UserContext } from "../../providers/UserProvider";
import { AppContext } from "../../AppProvider";

interface INavProps {
  user?: any;
  toggleModal?: () => void;
  modal?: boolean;
  isLoggedIn?: boolean;
  wrapperRef?: any;
  navigation?: NavigationItem[];
  categorySlug?: string;
  navOpen?: boolean;
  toggleNav?: () => void;
  navTop: number;
  navFixed: boolean;
  navHasBg: boolean;
  navHidden: boolean;
  pageHasPaddedTop: any;
}
export const NavContent = ({
  user,
  toggleModal,
  modal,
  isLoggedIn,
  wrapperRef,
  navigation,
  categorySlug,
  navOpen,
  toggleNav,
  navTop,
  navFixed,
  navHasBg,
  navHidden,
  pageHasPaddedTop,
}: INavProps) => {
  const textLinks = navigation?.filter((item) => !item.image);
  const imageLinks = navigation?.filter((item) => item.image);
  const shouldShowBg = navHasBg || navOpen;

  const [cartQuantity, setCartQuantity] = useState(0);
  const [searchModalOpen, setSearchModalOpen] = useState<boolean>(false);
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const cart = userContext?.cart;

  const quantity = cart?.items?.length ?? 0;

  if (quantity !== cartQuantity) setCartQuantity(quantity);

  const showModal = () => {
    let site = document.getElementsByTagName("body")[0];
    site.classList.add("modal-open");
    setSearchModalOpen(true);
  };
  const hideModal = () => {
    let site = document.getElementsByTagName("body")[0];
    site.classList.remove("modal-open");

    setSearchModalOpen(false);
  };

  return (
    <>
      <style.ScrollContainer
        ref={wrapperRef}
        navFixed={navFixed}
        style={{ top: navTop }}
      >
        <style.ScrollAnimation
          navHasBg={shouldShowBg ?? false}
          pageHasPaddedTop={pageHasPaddedTop}
          isHidden={navHidden}
        >
          {appContext?.settings?.header_bullets && <TopBar />}
          <style.NavbarWrapper
            navHasBg={shouldShowBg ?? false}
            pageHasPaddedTop={pageHasPaddedTop}
          >
            <>
              <style.MobileNavWrap>
                {isLoggedIn && (
                  <style.NavBarButton
                    onClick={toggleNav}
                    open={navOpen ?? false}
                  />
                )}
              </style.MobileNavWrap>
              <style.LogoWrap>
                <Link to="/">
                  <div id="logo-desktop" style={{ display: "flex" }}>
                    <img
                      style={{ height: "100%", width: "auto" }}
                      src="/wp-content/uploads/2021/01/logo.png"
                      alt="logo"
                    />
                  </div>
                  <div id="logo-mobile">
                    <img
                      style={{ height: "50px", width: "auto" }}
                      src="/wp-content/uploads/2021/01/logo.png"
                      alt="logo"
                    />
                  </div>
                </Link>
              </style.LogoWrap>
            </>

            <style.LoggedInWrap>
              <style.Links>
                <li
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    showModal();
                  }}
                >
                  <SearchIcon
                    color={
                      shouldShowBg || pageHasPaddedTop ? "#255958" : "#ffffff"
                    }
                  />
                  <style.SearchLabel>Sök</style.SearchLabel>
                </li>

                {user && user.company && (
                  <style.DesktopLi>
                    <Link to="/foretag/bestallning">Mina sidor</Link>
                  </style.DesktopLi>
                )}
                <li id="toggle-dropdown" role="button" onClick={toggleNav}>
                  Produkter
                </li>

                <li>
                  <button onClick={toggleModal} className="user-icon">
                    <Avatar />
                  </button>
                </li>

                {user && user.company && (
                  <li>
                    <Link to="/kundvagn">
                      <Cart />
                      <style.NavCartAmount>{cartQuantity}</style.NavCartAmount>
                    </Link>
                  </li>
                )}
              </style.Links>
            </style.LoggedInWrap>
            {modal && toggleModal && <UserModal handleClose={toggleModal} />}
          </style.NavbarWrapper>
        </style.ScrollAnimation>
        <AnimateHeight height={navOpen ? "auto" : 0} duration={300}>
          <style.NavExpander navOpen={navOpen ?? false}>
            <style.NavItems>
              <div>
                <style.NavItemsText>
                  {textLinks?.map((item) => {
                    return (
                      <li key={item.id}>
                        <WPLink to={item.link.url} target={item.link.target}>
                          {item.link.title}
                        </WPLink>
                      </li>
                    );
                  })}
                </style.NavItemsText>
                <style.NavItemsImage>
                  {imageLinks?.map((item) => {
                    return (
                      <style.NavItemImage key={item.id} image={item.image}>
                        <WPLink to={item.link.url} target={item.link.target}>
                          <span>{item.link.title}</span>
                        </WPLink>
                      </style.NavItemImage>
                    );
                  })}
                </style.NavItemsImage>
              </div>
              <Link to={sanitizePath(`/${categorySlug}` || "")}>
                Alla produkter
              </Link>
            </style.NavItems>
          </style.NavExpander>
        </AnimateHeight>
      </style.ScrollContainer>
      {searchModalOpen && <SearchResultModal handleClose={() => hideModal()} />}
    </>
  );
};
