import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginContent } from "./Login.content";
import { UserContext } from "../../providers/UserProvider";

export const Login = () => {
  const context = useContext(UserContext);
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const ERRORS = {
    BAD_CREDENTIALS:
      "Kontrollera att du angett rätt användarnamn och lösenord.",
    UNKOWN_ERROR: "Något gick fel, försök igen senare.",
  };

  const onLoginClick = async (
    e: React.MouseEvent<HTMLElement> | React.FormEvent
  ) => {
    e.preventDefault();
    setError(undefined);
    if (user && password) {
      setLoading(true);
      try {
        await context?.logIn?.(user, password, remember);
        navigate("/");
      } catch (err: any) {
        setLoading(false);
        if (err.status === 401) {
          setError(ERRORS.BAD_CREDENTIALS);
        } else {
          setError(ERRORS.UNKOWN_ERROR);
        }
      }
    }
  };
  return (
    <LoginContent
      onLoginClick={onLoginClick}
      user={user}
      setUser={setUser}
      password={password}
      setPassword={setPassword}
      remember={remember}
      setRemember={setRemember}
      errorMessage={error}
      loading={loading}
    />
  );
};
