import styled from "styled-components";
import { colors } from "../../../assets/style/variables";
export const DocumentCard = styled.a`
  display: flex;
  border-radius: 3px 3px 0 0;
  padding: 30px;
  margin-bottom: 25px;
  background-color: #fff;
  transition: background-color 0.3s ease;
  cursor: pointer;
  align-items: center;
  &:hover {
    background: #eeeeee;
  }
`
export const Documents = styled.div`
  margin-top: 35px;
`
export const h3 = styled.h3`
  color: ${colors.accent};
  padding-bottom: 10px;
  font-size: 18px;
`
export const span = styled.span`
`
export const FileSize = styled.span`
  white-space: nowrap;
`;
export const RotatedElement = styled.div`
  @media screen and (max-width: 567px) {
    display: none
  }
  svg {
    transform: rotate(180deg);
  }
`
export const LeftWrap = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`
export const Meta = styled.div`
  margin-left: 25px;
`
